import React, { useRef, useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { HeadingAbout } from './HeadingAbout';
import { useLanguage } from '../../context/LanguageContext';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { headingsAbout, aboutTexts, aboutSmallTexts } from '../../data/aboutData';
import { findElement } from '../../data/data';

const Wrapper = styled.div`
  width: 88.28vw;
  margin: auto;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2rem;
  display: flex;
  height:auto;
   @media (max-width: 801px) {
     padding-bottom: 0px;
  }
`;

const Body = styled.div`
@media (max-width: 801px) {
     padding-bottom:9.375rem;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  @media (max-width: 801px) {
    flex-direction: column;
  }
`;

const FlexLeft = styled.div`
  flex: 50%;
  display: flex;
  align-self: stretch;
  align-items: start;
  margin-top:4.688rem;
  @media (max-width: 801px) {
    flex: 100%;
  }
`;

const FlexRight = styled.div`
  flex: 50%;
  padding-left: 6rem;
  display: flex;
  align-self: stretch;
  align-items: center;
  margin-top:4.688rem;
  @media (max-width: 801px) {
    flex: 100%;
    padding-left: 0px;
     margin-top:2.688rem;
  }
`;

const AnimatedText = styled.p`
  color: black;
  font-size: 3.438rem;
  line-height: 3.438rem;
  opacity: ${(props) => (props.isVisible ? '1' : '0')};
  transform: translateY(${(props) => (props.isVisible ? '0' : '1em')});
  transition: opacity 0.5s, transform 0.5s;
  margin-top:0px;
  @media (max-width: 801px) {
   font-size: 2.1875rem;
  font-style: normal;
  font-weight: 275;
  line-height: 2.5rem;
  margin-top:0px;
  }
`;

const TextContent = styled.div`
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 275;
  line-height: 2.5rem;
  text-align: start;
  color: black;
  width: 52%;
  margin-left: 5.7rem;
  padding-bottom: 2.7rem;
  white-space:pre-line;
  position:relative;
  z-index:20;
  bottom:30rem;
  @media (max-width: 768px) {
    bottom:2rem;
    width:88.28vw;
    margin:auto;
    padding-bottom: 0rem;
  }

  @media (min-width: 801px) and (max-width: 990px) {
    bottom: 2rem;
  }

  @media (min-width: 1200px) and (max-width: 1300px) {
    bottom: 11rem;
  }

  @media (min-width: 1520px) {
    bottom: 11rem;
  }

  
`;




const ImagesSection = () => {
  const headingText = findElement(headingsAbout, 3);
  const writingText = findElement(aboutTexts,3);
  const smallText = findElement(aboutSmallTexts, 5);
 
  const [resize, setResize] = useState(false)
  const textRef = useRef(null);

  const { language } = useLanguage();
  const [isVisible, setIsVisible] = useState(false);

  const screenWidth = window.innerWidth
  const screenHeight = window.innerHeight

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const handleIntersectionText = (entries) => {
      const entry = entries[0];
      setIsVisible(entry.isIntersecting);
    };

    const observerText = new IntersectionObserver(handleIntersectionText, options);

    if (textRef.current) {
      observerText.observe(textRef.current);
    }

    return () => {
      observerText.disconnect();
    };
  }, []);


  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [resize]);

  const handleResize = (id) => {
    setResize(window.innerWidth <= 900);

  }


  return (
    <Body>
      <Wrapper>
        <Container>
          <FlexLeft>
            <HeadingAbout props={headingText.text[language]} />
          </FlexLeft>
          <FlexRight>
            <AnimatedText ref={textRef} isVisible={isVisible}>
              {writingText.text[language]}
            </AnimatedText>
          </FlexRight>
        </Container>
      </Wrapper>
      <ImageWrapper>

         {resize ?
            <ParallaxBanner style={{ height: '200vh', position: 'relative' }}>
            <ParallaxBannerLayer
            image="/img/about/img3.webp"
            style={{
              width: '60vw', 
            height:'40vh',
              objectFit: 'scale-down',
              objectPosition: 'center top',
              position: 'absolute',
              left: 0,
              top: '20%',
              zIndex: 21,
            }}
            speed={-40}
          />
          <ParallaxBannerLayer
            image="/img/about/img4.webp"
            style={{
              width: '30vw', 
              height:'30vh',
              position: 'absolute',
              left: '70%',
              top: '25%',
            }}
            speed={-25}
          />
          <ParallaxBannerLayer
            image="/img/about/img5.webp"
            style={{
              width: '100vw',  
              height:'100vw',
              objectFit: 'scale-down',
              position: 'absolute',
              left: '0%',
              top: '50%',
              zIndex: 1,
            }}
            speed={-40}
          />
          
            </ParallaxBanner>

           : 
            <ParallaxBanner style={{ height: '220vh', position: 'relative' }}>
            <ParallaxBannerLayer
            image="/img/about/img3.webp"
            style={{
              width: `54vw`,    
              height: `${(44.625 * 16 / screenHeight) * 100}vh`,  
              objectFit: 'contain',
              objectPosition: 'center top',
              position: 'absolute',
              left: 0,
              top: '20%',  
              zIndex: 21,
            }}
            speed={-40}
          />
        
          <ParallaxBannerLayer
            image="/img/about/img4.webp"
            style={{
              width: `${(23.3125 * 16 / screenWidth) * 100}vw`,   
              height: `${(24.1875 * 16 / screenHeight) * 100}vh`,  
              position: 'absolute',
              left: '70%',
              top: '15%',  
            }}
            speed={-25}
          />
        
          <ParallaxBannerLayer
            image="/img/about/img5.webp"
            style={{
              width: `${(37.8125 * 16 / screenWidth) * 100}vw`,   
              height: `${(30 * 16 / screenHeight) * 100}vh`, 
              objectFit: 'cover',
              position: 'absolute',
              left: '60%',
              top: '50%', 
              zIndex: 1,
            }}
            speed={-40}
          />
        </ParallaxBanner>
        }
      </ImageWrapper>
      <TextContent>
        <p>{smallText.text[language]}</p>
      </TextContent>
    </Body>
  );
};

export default ImagesSection;