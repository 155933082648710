import React from 'react'
import { styled } from 'styled-components'
import { HeadingAbout } from './HeadingAbout';
import { useLanguage } from '../../context/LanguageContext';
import { headingsAbout } from '../../data/aboutData';
import { aboutTexts } from '../../data/aboutData';
import { findElement } from '../../data/data';

const Wrapper = styled.div`
  width: 88.28vw;
  height:34vh;
  margin:auto;
  align-items: center;
  flex-direction:column;
  padding-bottom:5.625rem;
  display:flex;
  @media (max-width: 801px){
    padding-bottom:0rem;
    height:auto;
  }
`;

const Container = styled.div`
 display:flex;
 align-items: center;
 flex-direction:row;
 margin-top:120px;
 @media (max-width: 801px){
    flex-direction:column;
   
  }
`;

const FlexLeft = styled.div`
  flex:50%;
  display:flex;
  align-self: stretch;
  align-items: start; 
  margin-top:5.625rem;
  @media (max-width: 801px){
    flex:100%;
    margin-top:2.5rem;
  }
`;
const FlexRight = styled.div`
  flex:50%;
  display:flex;
  align-self: stretch;
  align-items: center; 
  font-size:3.438rem;
  line-height:3.438rem;
  @media (max-width: 801px){
    flex:100%;
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 275;
    line-height: 2.1875rem; 
    padding-top:0rem;
  }
`;

const SubtitleText = styled.div`
margin-top:2.8rem;
color:"#000";
@media (max-width: 801px){
  margin-top:0rem;
  padding-top:0rem;
  }
`;


const Header = () => {
  const headingText = findElement(headingsAbout, 1);
  const headingTextSmall = findElement(aboutTexts, 1);
  const { language } = useLanguage()

  return (
    <Wrapper>
      <Container>
        <FlexLeft>
          <HeadingAbout props={headingText.text[language]} />
        </FlexLeft>
        <FlexRight>
          {language === 'es' ?
            <SubtitleText>
              <p style={{marginBottom:'0px', marginTop:'2.5rem' }}>{headingTextSmall.text[language]}</p>
            </SubtitleText>
            :
            <SubtitleText>
              <p style={{marginBottom:'0px', marginTop:'2.5rem' }}>{headingTextSmall.text[language]}</p>
            </SubtitleText>
          }
        </FlexRight>
      </Container>
    </Wrapper>
  )
}

export default Header
