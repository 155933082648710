import { React, useEffect } from 'react';
import Header from './Header'
import ServicesList from './ServicesList';
import Image from './Image';
import PictureSection from './PictureSection';


const Services = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div id="wrapper">
      <Header />
      <ServicesList/>
      <Image/>
      <PictureSection/>
    </div>
  );
};

export default Services;
