


const headingsAbout = [
  {
    id: 1,
    text: {
      en: "WHAT WE DO",
      es: "QUÉ HACEMOS",
      cat: "QUÈ FEM",
      ger: "WAS WIR TUN"
    },
  },
  {
    id: 2,
    text: {
      en: "WHY \nACOUSTIC \nHERITAGE?",
      es: "¿POR QUÉ \nPATRIMONIO \nACÚSTICO?",
      cat: "PER QUÈ \nPATRIMONI ACÚSTIC?",
      ger: "WARUM \nAKUSTISCHES \nERBE?"
    },
  },
  {
    id: 3,
    text: {
      en: "HOW WE DO IT",
      es: "CÓMO LO HACEMOS",
      cat: "COM HO FEM",
      ger: `WIE MACHEN 
            WIR ES?`
    },
  },
  {
    id: 4,
    text: {
      en: `OUR CLIENTS & 
            COLLABORATORS`,
      es: `NUESTROS CLIENTES Y 
            COLABORADORES`,
      cat: `ELS NOSTRES CLIENTS I 
            COL·LABORADORS`,
      ger: `UNSERE KUNDEN UND 
      KOLLABORATOREN`
    },
  },
  {
    id: 5,
    text: {
      en: "WHO WE ARE",
      es: "QUIÉNES SOMOS",
      cat: "QUI SOM",
      ger: "WER WIR SIND"
    },
  },
  {
    id: 6,
    text: {
      en: "RESIDENCES & SCHOLARSHIPS",
      es: "RESIDENCIAS Y BECAS",
      cat: "RESIDÈNCIES I BEQUES",
      ger: "AUFENTHALTE UND STIPENDIEN"
    }
  },
  {
    id: 7,
    text: {
      en: "ACADEMICS",
      es: "ACADEMIA",
      cat: "ACADÈMIA",
      ger: "AKADEMIA"
    }
  }
];

const aboutTexts = [
  {
    id: 1,
    text: {
      en: `We explore new ways 
            of representing and enhancing 
            sound and acoustic heritage.`,
      es: `Exploramos nuevas formas de 
            representar y poner en valor el 
            patrimonio acústico y sonoro.`,
      cat: `Explorem noves maneres de 
            representar i realçar el 
            patrimoni sonor i acústic`,
      ger: `Wir erforschen neue Wege 
            der Darstellung und Aufwertung 
            von akustischem Erbe.`
    },
  },
  {
    id: 2,
    text: {
      en: "Our main goal is to enrich and preserve the acoustic and sound heritage of significant community spaces.",
      es: "Nuestro objetivo principal es enriquecer y preservar el patrimonio acústico y sonoro de espacios comunitarios significativos.",
      cat: "El nostre objectiu principal és enriquir i preservar el patrimoni acústic i sonor d'espais comunitaris significatius.",
      ger: "Unser Ziel ist es, das akustische und klangliche Erbe wichtiger öffentlicher Räume zu bereichern und zu erhalten."
    },
  },
  {
    id: 3,
    text: {
      en: `A creativity-centered approach is our key to community engagement.`,
      es: `Ponemos la creatividad en el centro para involucrar a la comunidad.`,
      cat: `Posem la creativitat al centre per tal d’involucrar a la comunitat.`,
      ger: `Kreativitätszentrierte Ansätze sind der Schlüssel zu sozialem Engagement.`
    }
  },
  {
    id: 4,
    text: {
      en: `An independent non-profit group of professionals from the art, technology, and culture sectors with different specialties.`,
      es: `Un grupo independiente sin ánimo de lucro de profesionales del arte, la tecnología y la cultura con diversas especialidades.`,
      cat: `Un grup independent sense ànim de lucre de professionals de l'art, la tecnologia i la cultura amb diverses especialitats.`,
      ger: `Eine unabhängige, gemeinnützige Gruppe von Fachleuten aus den Bereichen Kunst, Technologie und Kultur.`
    }
  }



]

const aboutSmallTexts = [
  {
    id: 1,
    text: {
      en: `We investigate the relationship between acoustics, perception, and emotional responses. 
            Acoustics, sound, and its social, political, and creative implications are our core. 
            We explore how acoustic spaces can create meaning by altering the way sound is experienced.`,

      es: `Investigamos el vínculo entre acústica, percepción y emoción.
            Ponemos en el centro la  acústica, el sonido y sus implicaciones sociales, políticas y creativas. 
            Estudiamos cómo creamos significado a través de los espacios acústicos y cómo los percibimos.`,

      cat: `Investiguem el vincle entre acústica, percepció i emoció.
            Posem al centre l'acústica, el so i les seves implicacions socials, polítiques i creatives.
            Estudiem com creeuem significat a través dels espais acústics i com els percebem.
            `,

      ger: `Wir erforschen die Beziehung zwischen Akustik, Wahrnehmung und emotionalen Reaktionen.
            Akustik, Klang und seine sozialen, politischen und kreativen Auswirkungen sind unser Kern.
            Wir erforschen die Bedeutung von akustischen Räumen und wie sie beeinflussen, wie wir Klang erleben.`
    },
  },
  {
    id: 2,
    text: {
      en: `You'll find us capturing vanishing glacier sounds in Iceland, measuring acoustics in 
            abandoned industrial factories or in World Heritage sites, empowering communities to 
            build acoustic heritage libraries, designing immersive experiences for museums or 
            researching innovative acoustic representation of a Spanish Civil War bunker using VR.`,

      es: `Nos puedes encontrar registrando sonidos de glaciares islandeses en extinción; 
            midiendo la acústica de fábricas industriales abandonadas o de lugares declarados Patrimonio de 
            la Humanidad; empoderando a comunidades para crear bibliotecas digitales de patrimonio 
            acústico; diseñando experiencias inmersivas para museos o investigando cómo representar la 
            acústica de un refugio de la Guerra Civil Española mediante realidad virtual.`,

      cat: `Ens pots trobar enregistrant sons de glaceres islandeses en extinció; mesurant l'acústica de 
            fàbriques industrials abandonades o de llocs declarats Patrimoni de la Humanitat; empoderant a 
            comunitats per a crear biblioteques digitals de patrimoni acústic; dissenyant experiències 
            immersives per a museus o investigant com representar l’acústica d'un refugi de la Guerra Civil 
            Espanyola mitjançant realitat virtual. `,

      ger: `Wir fangen verschwindende Gletschergeräusche in Island ein, messen die Akustik in 
            stillgelegten Industrieanlagen, unterstützen Gemeinden beim Aufbau von Bibliotheken zum 
            akustischen Erbe, entwerfen immersive Erlebnisse für Museen oder erforschen die innovative 
            akustische Darstellung eines Bunkers aus dem Spanischen Bürgerkrieg mit VR.`,
    }
  },
  {
    id: 2.1,
    text: {
      en: `Our activities span art and science, encompassing safeguarding, digitization, workshops, 
           research, cultural projects, artistic exploration, and community initiatives.`,

      es: `Nuestras actividades abarcan el arte y la ciencia, incluyendo la salvaguarda, digitalización, talleres, 
           investigación, proyectos culturales, exploración artística e iniciativas comunitarias.`,

      cat: `Les nostres activitats abasten l'art i la ciència, incloent-hi la salvaguarda, digitalització, tallers, 
            recerca, projectes culturals, exploració artística i iniciatives comunitàries.
            . `,

      ger: `Unsere Aktivitäten umfassen Kunst und Wissenschaft, Sicherung, Digitalisierung, Workshops, 
            Forschung, Kulturprojekte, künstlerische Erkundung und Gemeinschaftsinitiativen.`
    }
  },

  {
    id: 3,
    text: {
      en: `In a visually dominant world, we shift focus to the sonic and acoustic realm as 
            a tool to evoke emotion, preserve collective memory, reclaim it, and explore its depths. 
            
            Our inclusive heritage research extends beyond historic buildings 
            to spaces crucial for marginalized communities.`,

      es: `En un mundo donde reina la hegemonía visual, desplazamos el enfoque a  lo sonoro y acústico para evocar 
            emociones, preservar la memoria colectiva, reclamarla y explorar sus profundidades.

            Nuestra perspectiva inclusiva del patrimonio va más allá de edificios históricos, considerando 
            también espacios importantes para comunidades marginadas.
           `,

      cat: `En un món on reina l'hegemonia visual, posem èmfasi en el so i l'acústica per evocar 
            emocions, preservar la memòria col·lectiva, reclamar-la i explorar les seves profunditats.

            La nostra perspectiva inclusiva del patrimoni va més enllà d'edificis històrics, també considerant espais importants per a comunitats marginades.
            `,

      ger: `In einer überwiegend visuellen Welt konzentrieren wir uns auf den Klang als 
            Werkzeug, um die Tiefen des kollektiven Gedächtnisses zu erforschen und es wiederzugewinnen.
 
            
            Unsere Forschung zum kulturellen Erbe geht über das Historische hinaus 
            und umfasst auch Räume, die für Gemeinden wichtig sind.`
    }
  },
  {
    id: 4,
    text: {
      en: `We proactively preserve heritage before its disappearance or any harm occurs.`,
      es: `Salvaguardamos proactivamente el patrimonio antes de que desaparezca o se dañe.`,
      cat: `Salvaguardem proactivament el patrimoni abans que desaparegui o es danyi.`,
      ger: `Wir erhalten das Erbe proaktiv, bevor es verschwindet oder Schaden nimmt.`
    }
  },
  {
    id: 5,
    text: {
      en: `We proactively preserve heritage before its disappearance or any harm occurs.
            
            Immersive experiences lie at the core, fostering empathy, connecting people with our heritage, and enhancing accessibility for all.\n\nOur collective tailors each project to its unique needs, client, and audience. 
            We employ advanced art and tech to record and represent heritage, exploring optimal ways for acoustic representation. 

            Sound and acoustics serve as powerful tools for crafting new narratives and evoking emotions.`,
      es: `Salvaguardamos proactivamente el patrimonio antes de que desaparezca o se dañe.
            
            Apostamos por las experiencias inmersivas, fomentando la empatía, conectando a la gente con su patrimonio y mejorando su accesibilidad.\n\nNuestro colectivo adapta cada proyecto a sus necesidades únicas, cliente y público. Usamos arte y tecnología avanzada para grabar y representar el patrimonio, explorando novedosas formas de representación acústica.  
            
            El sonido y la acústica sirven como herramientas poderosas para crear nuevas narrativas y evocar emociones.

            `,
      cat: `Salvaguardem proactivament el patrimoni abans que desaparegui o es danyi.
            
            Salvaguardem proactivament el patrimoni abans que desaparegui o es danyi. Apostem per les experiències immersives, fomentant l'empatia, connectant a la gent amb el seu patrimoni i millorant la seva accessibilitat.\n\nEl nostre col·lectiu adapta cada projecte a les seves necessitats úniques, client i públic.Usem art i tecnologia avançada per a gravar i representar el patrimoni, explorant noves formes de representació acústica. 

            El so i l'acústica serveixen com a eines poderoses per a crear noves narratives i evocar emocions.
           `,
      ger: `Wir erhalten das Erbe proaktiv, bevor es verschwindet oder Schaden nimmt.
            
            Immersive Erlebnisse stehen im Mittelpunkt, fördern das Mitgefühl, verbinden Menschen mit unserem Erbe und verbessern die Zugänglichkeit.

            Wir schneiden jedes Projekt auf die Bedürfnisse des Kunden und des Publikums zu. Wir nutzen die neuesten Technologien, um neue künstlerische Wege zur Präsentation des kulturellen Erbes zu finden.
            
            Klang und Akustik sind mächtige Tools, um neue Geschichten zu erfinden und Emotionen zu wecken.
            `
    }
  },
  {
    id: 6,
    text: {
      en: `In the dynamic field of acoustic heritage, we navigate diverse landscapes - academia, research, popular culture, archives, museums, art, creativity education, technology, emotion, and music.`,
      es: `En el dinámico campo del patrimonio acústico, abordamos diversos terrenos: academia, investigación, cultura popular, archivos, museos, arte, educación creativa, tecnología, emoción y música`,
      cat: `En l’ampli camp del patrimoni acústic, toquem diversos terrenys: acadèmia, recerca, cultura popular, arxius, museus, art, educació creativa, tecnologia, emoció i música.`,
      ger: `Auf dem dynamischen Gebiet des akustischen Erbes bewegen wir uns in verschiedenen Landschaften: Wissenschaft, Forschung, Populärkultur, Kunst, kreative Bildung, Technologie, Emotionen und Musik.`
    }
  },
  {
    id: 7,
    text: {
      en: `We constantly update skills for top-notch work within a flat hierarchy, adapting our involvement to project needs by integrating new collaborators or rotating responsibilities.`,
      es: `Actualizamos constantemente nuestro conocimiento para realizar un trabajo de alta calidad siguiendo una estructura horizontal. Adaptamos nuestra participación a las necesidades del proyecto, integrando nuevos colaboradores o rotando responsabilidades.`,
      cat: `Actualitzem constantment el nostre coneixement per a fer un treball d'alta qualitat seguint una estructura horitzontal. Adaptem la nostra participació a les necessitats del projecte, integrant nous col·laboradors o rotant responsabilitats.`,
      ger: `Wir aktualisieren ständig unsere Fähigkeiten, um erstklassige Arbeit zu leisten, und passen unser Engagement an die Bedürfnisse des Projekts an, indem wir neue Partner einbeziehen.`
    }
  }

];




const producers = [
  {
    id: 1,
    name: 'GINEBRA RAVENTÓS',
    title: {
      en: 'Founder and Original Idea, Executive Producer, Audiovisual Producer, Sound Artist, Psychologist',
      es: 'Fundadora e Idea Original, Productora Ejecutiva, Productora Audiovisual, Artista Sonora, Psicóloga',
      cat: 'Fundadora i Idea Original, Productora Audiovisual, Artista Sonora, Psicòloga',
      ger: 'Founder and Original Idea, Executive Producer, Audiovisual Producer, Sound Artist, Psychologist'
    }
  },
   {
      id: 2,
      name: 'EDGARDO GÓMEZ',
      title: {
          en: 'Germany Partnership, Executive Producer, Acoustic Engineer, Sound Technician, Sound Artist',
          es: 'Socio Productor Ejecutivo en Alemania, Ingeniero Acústico, Técnico de Sonido, Artista Sonoro',
          cat: 'Soci Productor Executiu a Alemanya, Enginyer Acústic, Tècnic de So, Artista Sonor',
          ger: 'Germany Partnership, Executive Producer, Acoustic Engineer, Sound Technician, Sound Artist'
      }
  }, 
] 
  const theGang = [
  { 
    id: 1,
    name: 'EMARX',
    title: {
      en: 'Acoustic Engineer, Sound Technician, Sound Artist',
      es: 'Ingeniero Acústico, Técnico de Sonido, Artista Sonoro',
      cat: 'Enginyer Acústic, Tècnic de So, Artista Sonor',
      ger: 'Acoustic Engineer, Sound Technician, Sound Artist'
    }
  },
 
  {
    id: 2,
    name: 'JOAN LAVANDEIRA A.K.A POSTRUCTURAL-ISM LABEL',
    title: {
      en: 'Engineer, 3D Modeler, Unreal Engine Programmer, Digital Artist',
      es: 'Ingeniero, Modelador 3D, Programador Unreal Engine, Artista Digital',
      cat: 'Enginyer, Modelador 3D, Programador Unreal Engine, Artista Digital',
      ger: 'Engineer, 3D Modeler, Unreal Engine Programmer, Digital Artist'
    }
  },
  {
    id: 3,
    name: 'PAOLO CARRETERO',
    title: {
      en: 'Web Designer and Developer, Sound Artist, Website Implementation, Web Audio API Coding',
      es: 'Diseñador y Desarrollador Web, Artista Sonoro, Implementación de Sitios Web, Codificación de Web Audio API',
      cat: 'Dissenyador i Desenvolupador Web, Artista Sonor, Implementació de Llocs Web, Codificació de Web Audio API',
      ger: 'Web Designer and Developer, Sound Artist, Website Implementation, Web Audio API Coding'
    }
  },
  {
    id: 4,
    name: 'MATHIAS KLENNER',
    title: {
      en: 'Architect and Sound Artist, Three-Dimensional Architectural Modeling',
      es: 'Arquitecto y Artista Sonoro, Modelado Arquitectónico Tridimensional',
      cat: 'Arquitecte i Artista Sonor, Modelatge Arquitectònic Tridimensional',
      ger: 'Architect and Sound Artist, Three-Dimensional Architectural Modeling'
    }
  },
  {
    id: 5,
    name: 'JULIANA RABELLO',
    title: {
      en: 'Designer, Branding',
      es: 'Diseñadora, Branding',
      cat: 'Dissenyadora, Branding',
      ger: 'Designer, Branding'
    }
  },
  {
    id: 6,
    name: 'PEPITO MORAN',
    title: {
      en: 'Technician, Designer, Technical Consultancy',
      es: 'Técnico, Diseñador, Consultoría Técnica',
      cat: 'Tècnic, Dissenyador, Consultoria Tècnica',
      ger: 'Technician, Designer, Technical Consultancy'
    }
  },
];

const projectsData = [
  {
    title: {
      en: "2023 MANIFEST Residency Programme",
      es: "Programa de Residencia MANIFEST 2023",
      cat: "Programa de Residència MANIFEST 2023",
      ger: "MANIFEST Residenzprogramm 2023",
    },
    description: {
      en:
        "Artistic Journey, re-imagining Europe’s collective memory of the transatlantic trade of enslaved people and its existing cultural traces, BUDAPEST, LISBON, NANTES",
      es:
        "Viaje artístico, reimaginando la memoria colectiva de Europa sobre el comercio transatlántico de personas esclavizadas y sus rastros culturales existentes, BUDAPEST, LISBOA, NANTES",
      cat:
        "Viatge artístic, reimaginant la memòria col·lectiva d'Europa sobre el comerç transatlàntic de persones esclavitzades i els seus rastres culturals existents, BUDAPEST, LISBOA, NANTES",
      ger:
        "Künstlerische Reise, die die kollektive Erinnerung Europas an den transatlantischen Handel mit versklavten Menschen und seine bestehenden kulturellen Spuren neu erfindet, BUDAPEST, LISSABON, NANTES",
    },
  },
  {
    title: {
      en: "2023 IRCAM Forum Workshops 2023",
      es: "Talleres del Foro IRCAM 2023",
      cat: "Tallers del Fòrum IRCAM 2023",
      ger: "IRCAM Forum Workshops 2023",
    },
    description: {
      en:
        '"Video game technology as an AV art and culture dissemination strategy: Case study "Journey to the center of sound" in the MONOM 4D sound system” Presentation',
      es:
        '"Tecnología de videojuegos como estrategia de diseminación artística y cultural audiovisual: Estudio de caso "Journey to the center of sound" en el sistema de sonido MONOM 4D" Presentación',
      cat:
        '"Tecnologia de videojocs com a estratègia de difusió artística i cultural audiovisual: Estudi de cas "Journey to the center of sound" en el sistema de so MONOM 4D" Presentació',
      ger:
        '"Videospiele als Strategie zur Verbreitung von Kunst und Kultur: Fallstudie "Journey to the center of sound" im MONOM 4D-Soundsystem" Präsentation',
    },
  },
  {
    title: {
      en: "2022 Winner of the Grant for the Modernization and Innovation of the Cultural and Creative Industries for the Year",
      es: "Ganador del Subsidio para la Modernización e Innovación de las Industrias Culturales y Creativas para el Año 2022",
      cat: "Guanyador de la Beca per a la Modernització i Innovació de les Indústries Culturals i Creatives de l'Any 2022",
      ger: "Gewinner des Zuschusses für Modernisierung und Innovation der Kultur- und Kreativindustrien für das Jahr 2022",
    },
    description: {
      en: "2022, Ministry of Culture and Sports of Spain",
      es: "2022, Ministerio de Cultura y Deporte de España",
      cat: "2022, Ministeri de Cultura i Esport d'Espanya",
      ger: "2022, Ministerium für Kultur und Sport Spaniens",
    },
  },
  {
    title: {
      en: "2022 Conference SC2022",
      es: "Conferencia SC2022",
      cat: "Conferència SC2022",
      ger: "Konferenz SC2022",
    },
    description: {
      en: "University of Kent, Wave Field Synthesis Artist in Residence @A+E Lab, Chatham, UK",
      es: "Universidad de Kent, Artista en Residencia de Síntesis de Campo de Ondas @A+E Lab, Chatham, Reino Unido",
      cat: "Universitat de Kent, Artista en Residència de Síntesi de Camp d'Ondes @A+E Lab, Chatham, Regne Unit",
      ger: "Universität Kent, Künstler in Residence für Wellenfeldsynthese @A+E Lab, Chatham, Vereinigtes Königreich",
    },
  },
  {
    title: {
      en: "2022 Goethe-Institut e.V.",
      es: "2022 Goethe-Institut e.V.",
      cat: "2022 Goethe-Institut e.V.",
      ger: "2022 Goethe-Institut e.V.",
    },
    description: {
      en: "Music Division VIRTUAL PREPARATION FOR RESIDENCIES",
      es: "División de Música PREPARACIÓN VIRTUAL PARA RESIDENCIAS",
      cat: "Divisió de Música PREPARACIÓ VIRTUAL PER A RESIDÈNCIES",
      ger: "Musikabteilung VIRTUELLE VORBEREITUNG AUF RESIDENZEN",
    },
  },
  {
    title: {
      en: "2022 MONOM Residency",
      es: "Residencia MONOM 2022",
      cat: "Residència MONOM 2022",
      ger: "MONOM Residenz 2022",
    },
    description: {
      en: "Journey to the Center of the Earth, Berlin, Germany",
      es: "Viaje al Centro de la Tierra, Berlín, Alemania",
      cat: "Viatge al Centre de la Terra, Berlín, Alemanya",
      ger: "Reise zum Mittelpunkt der Erde, Berlin, Deutschland",
    },
  },
  {
    title: {
      en: "2022 PHONOS LAB UPF",
      es: "2022 PHONOS LAB UPF",
      cat: "2022 PHONOS LAB UPF",
      ger: "2022 PHONOS LAB UPF",
    },
    description: {
      en: "EMARX Residency, PROCÉS: A sonic transit through the industrialization process of Catalonia With the participation of Joan Lavandeira, Barcelona, Spain",
      es: "Residencia EMARX, PROCÉS: Un tránsito sonoro por el proceso de industrialización de Cataluña Con la participación de Joan Lavandeira, Barcelona, España",
      cat: "Residència EMARX, PROCÉS: Un trànsit sonor pel procés d'industrialització de Catalunya Amb la participació de Joan Lavandeira, Barcelona, Espanya",
      ger: "EMARX Residenz, PROCÉS: Ein klanglicher Transit durch den Industrialisierungsprozess Kataloniens Mit der Beteiligung von Joan Lavandeira, Barcelona, Spanien",
    },
  },
  {
    title: {
      en: "2022 ESTRUCH",
      es: "2022 ESTRUCH",
      cat: "2022 ESTRUCH",
      ger: "2022 ESTRUCH",
    },
    description: {
      en: "Medialab Residency Scholarship, “SOROLL” Sound Art Installation, Sabadell, Spain",
      es: "Beca de Residencia de Medialab, Instalación de Arte Sonoro 'SOROLL', Sabadell, España",
      cat: "Beca de Residència de Medialab, Instal·lació d'Art Sonor 'SOROLL', Sabadell, Espanya",
      ger: "Medialab Residenzstipendium, Klangkunstinstallation 'SOROLL', Sabadell, Spanien",
    },
  },
  {
    title: {
      en: "2021 SONOTOMIA RESIDENCY 2.0",
      es: "Residencia SONOTOMIA 2.0",
      cat: "Residència SONOTOMIA 2.0",
      ger: "SONOTOMIA RESIDENCY 2.0",
    },
    description: {
      en: "Preservation of Albarracín sound heritage, Albarracín, Spain",
      es: "Preservación del patrimonio sonoro de Albarracín, Albarracín, España",
      cat: "Preservació del patrimoni sonor d'Albarracín, Albarracín, Espanya",
      ger: "Erhaltung des Klangerbes von Albarracín, Albarracín, Spanien",
    },
  },
  {
    title: {
      en: "2021 I-portunus Mobility in CULTURAL HERITAGE",
      es: "Movilidad I-portunus en PATRIMONIO CULTURAL",
      cat: "Mobilitat I-portunus en PATRIMONI CULTURAL",
      ger: "I-portunus Mobilität im KULTURERBE",
    },
    description: {
      en: '"Safeguarding Acoustic Heritage of Thingvellir National Park", Iceland',
      es: '"Salvaguardia del Patrimonio Acústico del Parque Nacional de Thingvellir", Islandia',
      cat: '"Salvaguarda del Patrimoni Acústic del Parc Nacional de Thingvellir", Islàndia',
      ger: '"Sicherung des akustischen Erbes des Nationalparks Thingvellir", Island',
    },
  },
  {
    title: {
      en: "2021 UNZIP Música i Territori Scholarship",
      es: "Beca UNZIP Música y Territorio",
      cat: "Beca UNZIP Música i Territori",
      ger: "UNZIP Musik und Territorium Stipendium",
    },
    description: {
      en: "Rescuing the acoustic heritage of El Prat through Music, El Prat del Llobregat, Spain",
      es: "Rescatando el patrimonio acústico de El Prat a través de la música, El Prat del Llobregat, España",
      cat: "Rescatant el patrimoni acústic d'El Prat a través de la música, El Prat del Llobregat, Espanya",
      ger: "Rettung des akustischen Erbes von El Prat durch Musik, El Prat del Llobregat. Spanien"
    }
  }
]


const academicsData = [
  {
    title: {
      en: "EuropeanaTech 2023 Conference",
      es: "Conferencia EuropeanaTech 2023",
      cat: "Conferència EuropeanaTech 2023",
      ger: "EuropeanaTech Konferenz 2023",
    },
    text: {
      en: "Explore, Engage, Experience: cultural heritage in the data space and beyond, the Netherlands, The Hague. “Preserving acoustic heritage: a workshop on safeguarding, digitisation, and immersive experiences”",
      es: "Explora, participa, experimenta: patrimonio cultural en el espacio de datos y más allá, Países Bajos, La Haya. \"Preservación del patrimonio acústico: taller sobre salvaguardia, digitalización, y experiencias inmersivas\"",
      cat: "Explora, participa, experimenta: patrimoni cultural en l'espai de dades i més enllà, Països Baixos, La Haia. \"Preservació del patrimoni acústic: taller sobre salvaguarda, digitalització, i experiències immersives\"",
      ger: "Erkunden, einbinden, erleben: Kulturerbe im Datenraum und darüber hinaus, Niederlande, Den Haag. „Erhalt des akustischen Erbes: Workshop zu Schutz, Digitalisierung, und immersiven Erfahrungen“",
    },
  },
  {
    title: {
      en: "Strategies for the inclusion of the acoustic space in the digitization of cultural heritage. Case study: air raid shelters from the Spanish civil war in the city of Barcelona (2023)",
      es: "Estrategias para la inclusión del espacio acústico en la digitalización del patrimonio cultural. Estudio de caso: refugios antiaéreos de la Guerra Civil Española en la ciudad de Barcelona (2023)",
      cat: "Estratègies per a la inclusió de l'espai acústic en la digitalització del patrimoni cultural. Estudi de cas: refugis antiaeris de la Guerra Civil Espanyola a la ciutat de Barcelona (2023)",
      ger: "Strategien zur Einbeziehung des akustischen Raums in die Digitalisierung des kulturellen Erbes. Fallstudie: Luftschutzbunker aus dem Spanischen Bürgerkrieg in der Stadt Barcelona (2023)",
    },
    text: {
      en: "First International Conference on Conflict Heritage. European Observatory of Memories (EUROM) of the University of Barcelona, Figueres (Spain)",
      es: "Primer Congreso Internacional sobre Patrimonio del Conflicto. Observatorio Europeo de Memorias (EUROM) de la Universidad de Barcelona, Figueres (España)",
      cat: "Primer Congrés Internacional sobre Patrimoni del Conflicte. Observatori Europeu de Memòries (EUROM) de la Universitat de Barcelona, Figueres (Espanya)",
      ger: "Erster Internationaler Kongress zum Thema Konflikterbe. Europäisches Observatorium für Erinnerungen (EUROM) der Universität Barcelona, Figueres (Spanien)",
    },
  },
  {
    title: {
      en: "Gothic Acoustics in Contemporary Art: A Set of Audio Tools for La Capella (2023)",
      es: "Acústica gótica en el arte contemporáneo: un conjunto de herramientas de audio para La Capella (2023)",
      cat: "Acústica gòtica en l'art contemporani: un conjunt d'eines d'àudio per a La Capella (2023)",
      ger: "Gotische Akustik in der zeitgenössischen Kunst: Ein Set von Audiowerkzeugen für La Capella (2023)",
    },
    text: {
      en: "Gothic Resonances international symposium, Palais des Papes, Avignon, Mathias Klenner, EMARX",
      es: "Simposio internacional Resonancias góticas, Palais des Papes, Aviñón, Mathias Klenner, EMARX",
      cat: "Simposi internacional Resonàncies gòtiques, Palais des Papes, Avinyó, Mathias Klenner, EMARX",
      ger: "Internationales Symposium Gotische Resonanzen, Palais des Papes, Avignon, Mathias Klenner, EMARX",
    },
  },
  {
    title: {
      en: "Acoustic Heritage of the Abbot’s Cliff sound mirror: Strategies on its safeguard addressing past usage, present conditions, and future application (2023)",
      es: "Patrimonio acústico del espejo de sonido Abbot’s Cliff: Estrategias para su salvaguardia abordando el uso pasado, las condiciones presentes, y la aplicación futura (2023)",
      cat: "Patrimoni acústic del mirall de so Abbot’s Cliff: Estratègies per a la seva salvaguarda abordant l'ús passat, les condicions presents, i l'aplicació futura (2023)",
      ger: "Akustisches Erbe des Schallschirms von Abbot’s Cliff: Strategien zur Sicherung unter Berücksichtigung der vergangenen Nutzung, der gegenwärtigen Bedingungen und zukünftiger Anwendungen (2023)",
    },
    text: {
      en: "Sounding Modernism, An interdisciplinary conference, King’s College London",
      es: "Sounding Modernism, una conferencia interdisciplinaria, King’s College London",
      cat: "Sounding Modernism, una conferència interdisciplinària, King’s College London",
      ger: "Sounding Modernism, Eine interdisziplinäre Konferenz, King’s College London",
    },
  },
  {
    title: {
      en: "Acoustic heritage of Logberg in Thingvellir National Park: impulse response library and an approach to future studies (2022)",
      es: "Patrimonio acústico de Logberg en el Parque Nacional Thingvellir: biblioteca de respuestas impulsivas y un enfoque para estudios futuros (2022)",
      cat: "Patrimoni acústic de Logberg al Parc Nacional Thingvellir: biblioteca de respostes impulsives i una aproximació als estudis futurs (2022)",
      ger: "Akustisches Erbe des Logbergs im Thingvellir-Nationalpark: Impulsantwortbibliothek und ein Ansatz für zukünftige Studien (2022)",
    },
    text: {
      en: "Conference SC2022, University of Kent. https://thingvelliracoustics.tumblr.com/",
      es: "Conferencia SC2022, Universidad de Kent. https://thingvelliracoustics.tumblr.com/",
      cat: "Conferència SC2022, Universitat de Kent. https://thingvelliracoustics.tumblr.com/",
      ger: "Konferenz SC2022, Universität Kent. https://thingvelliracoustics.tumblr.com/",
    },
  },
  {
    title: {
      en: "Workshop: Simulated acoustic spaces into digital artistic practice (2021)",
      es: "Taller: Espacios acústicos simulados en la práctica artística digital (2021)",
      cat: "Taller: Espais acústics simulats a la pràctica artística digital (2021)",
      ger: "Workshop: Simulierte akustische Räume in die digitale künstlerische Praxis (2021)",
    },
    text: {
      en: "ICELAND UNIVERSITY OF THE ARTS / NAIP: European Master in Music, Prof. Berglind María Tómasddóttir",
      es: "UNIVERSIDAD DE LAS ARTES DE ISLANDIA / NAIP: Máster Europeo en Música, Prof. Berglind María Tómasddóttir",
      cat: "UNIVERSITAT D'ARTS D'ISLÀNDIA / NAIP: Màster Europeu en Música, Prof. Berglind María Tómasddóttir",
      ger: "ISLAND UNIVERSITY OF THE ARTS / NAIP: European Master in Music, Prof. Berglind María Tómasddóttir",
    },
  },
  {
    title: {
      en: "Lecture, UNIVERSITY OF ICELAND / CULTURAL HERITAGE COURSE (2021)",
      es: "Conferencia, UNIVERSIDAD DE ISLANDIA / CURSO DE PATRIMONIO CULTURAL (2021)",
      cat: "Conferència, UNIVERSITAT D'ISLÀNDIA / CURS DE PATRIMONI CULTURAL (2021)",
      ger: "Vorlesung, UNIVERSITÄT ISLAND / KULTURERBEKURS (2021)",
    },
    text: {
      en: "Prof. Vilhelmína Jónsdóttir, Department of Folkloristics/Ethnology University of Iceland",
      es: "Prof. Vilhelmína Jónsdóttir, Departamento de Folc...",
      cat: "Prof. Vilhelmína Jónsdóttir, Departament de Folclorística / Etnologia, Universitat d'Islandia",
      ger: "Prof. Vilhelmína Jónsdóttir, Abteilung für Volkskunde / Ethnologie, Universität Island"
    }
  },
  {
    title: {
      en: "Acoustic heritage: challenges of the community in its safeguarding, Vol. 16 No. 31 (2021): City and sound art",
      es: "Patrimonio acústico: desafíos de la comunidad en su salvaguardia, Vol. 16 No. 31 (2021): Ciudad y arte sonoro",
      cat: "Patrimoni acústic: reptes de la comunitat en la seva salvaguarda, Vol. 16 No. 31 (2021): Ciutat i art sonor",
      ger: "Akustisches Erbe: Herausforderungen der Gemeinschaft bei seiner Bewahrung, Bd. 16 Nr. 31 (2021): Stadt und Klangkunst",
    },
    text: {
      en: "The edition covers both the work of artists who explore sound in its pure state while dissolving the barriers between sound, noise and music in the historical or contemporary sense, as well as those who investigate the political and cultural implications of certain sounds to exalt human rights.",
      es: "La edición aborda tanto el trabajo de artistas que exploran el sonido en su estado puro, disolviendo las barreras entre sonido, ruido y música en el sentido histórico o contemporáneo, como aquellos que investigan las implicaciones políticas y culturales de ciertos sonidos para exaltar los derechos humanos.",
      cat: "L'edició cobreix tant la feina d'artistes que exploren el so en el seu estat pur, dissolent les barreres entre so, soroll i música en el sentit històric o contemporani, com aquells que investiguen les implicacions polítiques i culturals de certs sons per exaltar els drets humans.",
      ger: "Die Ausgabe umfasst sowohl die Arbeit von Künstlern, die den Klang in seinem reinen Zustand erforschen, indem sie die Barrieren zwischen Klang, Lärm und Musik im historischen oder zeitgenössischen Sinn auflösen, als auch diejenigen, die die politischen und kulturellen Implikationen bestimmter Klänge erforschen, um die Menschenrechte zu erheben.",
    },
  }

]

const professionalTeam = [
  {
    text: {
      en: "Current Professional Team:",
      es: "Colaboradores Habituales:",
      cat: "Col·laboradors Habituals:",
      ger: "Aktuelles Professionelles Team:",
    },
  },
]

export { theGang, headingsAbout, aboutTexts, aboutSmallTexts, projectsData, academicsData, producers, professionalTeam}
