const headingsContact = [
    {
        id: 1,
        text: {
            en: "CONTACT",
            es: "CONTACTO",
            cat: "CONTACTE",
            ger: "KONTAKT"
        },
    }
];

const contactSmallTexts = [
    {
        id: 1,
        text: {
            en: `Our collective is based in Barcelona and Berlin but we 
            also work all around the world.`,
            es: `Nuestro colectivo tiene su base en Barcelona y Berlín, pero también trabajamos en todo el mundo.`,
            cat: `El nostre col·lectiu té la seva base a Barcelona i Berlín, però també treballem arreu del món.`,
            ger: `Unser Kollektiv hat seinen Sitz in Barcelona und Berlin, arbeitet aber auch weltweit.`
        },
    },
    {
        id: 2,
        text: {
            en: `PLEASE GET IN TOUCH WITH US IF YOU 
            WANNA COLLABORATE, WORK WITH US OR 
            JUST SHARE PROJECTS OR IDEAS.`,
            es: `PONTE EN CONTACTO CON NOSOTROS SI 
            QUIERES COLABORAR, TRABAJAR CON NOSOTROS 
            O SIMPLEMENTE COMPARTIR PROYECTOS O IDEAS.`,
            cat: `POSA'T EN CONTACTE AMB NOSALTRES SI 
            VOLS COL·LABORAR, TREBALLAR AMB NOSALTRES 
            O SIMPLEMENT COMPARTIR PROJECTES O IDEES.`,
            ger: `NIMM KONTAKT MIT UNS AUF, WENN DU MIT 
            UNS ZUSAMMENARBEITEN MÖCHTEST ODER 
            EINFACH PROJEKTE ODER IDEEN TEILEN MÖCHTEST.`
        },
    }
    
];


const contactInfo = [
    {
        id: 1,
        phone: "+34 639 33 71 40",
        city: "Barcelona",
        country:{
            en:"Spain",
            es: "España",
            cat: "Espanya",
            ger: "Spanien"
        },
        image:"/img/contact/barcelona.webp"
    },
    {
        id: 2,
        phone: "+49 176 84241098",
        city: "Berlin",
        country:{
            en:"Germany",
            es: "Alemania",
            cat: "Alemanya",
            ger: "Deutschland"
        },
        image:"/img/contact/berlin.webp"
    },

]


export { headingsContact, contactSmallTexts, contactInfo }
