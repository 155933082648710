import React from 'react'
import { styled } from 'styled-components'
import ProjectListHeading from './ProjectListHeading';
import { useLanguage } from '../../context/LanguageContext';
import { projectsText } from '../../data/projectsData';

const Wrapper = styled.div`
  width: 88.28vw;
  height:44vh;
  margin:auto;
  align-items: center;
  flex-direction:column;
  padding-bottom:5.625rem;
  display:flex;
  @media (max-width: 801px){
    padding-bottom:0rem;
    height:auto;
  }
 
`;

const Container = styled.div`
 display:flex;
 align-items: center;
 flex-direction:row;
 margin-top:120px;
 width:100%;
 @media (max-width: 901px){
    flex-direction:column;
  }
`;

const FlexLeft = styled.div`
  flex:50%;
  display:flex;
  align-self: stretch;
  align-items: start; 
  margin-top:5.625rem;
  @media (max-width: 768px){
    flex:100%;
    margin-top:2.5rem;
  }
`;
const FlexRight = styled.div`
  flex:50%;
  display:flex;
  align-self: stretch;
  align-items: center; 
  font-size:3.438rem;
  line-height:3.438rem;
  @media (max-width: 768px){
    flex:100%;
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 275;
    line-height: 2.1875rem; 
    padding-top:0rem;
  }
`;

const SubtitleText = styled.div`
margin-top:2.8rem;
@media (max-width: 801px){
  margin-top:0rem;
  padding-top:0rem;
  }
`;


const Header = () => {

  const { language } = useLanguage()

  const projectInfo = projectsText.find((text) => text.id === 1);

  return (
    <Wrapper>
      <Container>
        <FlexLeft>
          <ProjectListHeading props={projectInfo.heading[language]} />
        </FlexLeft>
        <FlexRight>
          <SubtitleText>
            <p style={{marginBottom:'0px', marginTop:'2.5rem' }} >{projectInfo.text[language]}</p>
          </SubtitleText>
        </FlexRight>
      </Container>
    </Wrapper>
  )
}

export default Header
