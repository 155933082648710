
const headingText = [
  {
    id: 1,
    title: {
      en: "Services",
      es: "Servicios",
      cat: "Serveis",
      ger: "Leistungen"
    },
    text: {
      en: `Leverage the possibilities of sound preservation and innovation with our 3D safeguarding, immersive experiences, and top-notch equipment rental.`,
      es: `Descubre las posibilidades de la preservación e innovación sonora con nuestra salvaguarda en 3D, experiencias inmersivas y alquiler de equipos de primera.`,
      cat: `Descobreix les possiblitats de la preservació i innovació sonora amb la nostra salvaguarda en 3D, experiències immersives i lloguer d'equips de primera.`,
      ger: `Nutze die Möglichkeiten der Klangbewahrung und Innovation mit unserer 3D-Sicherung, den immersiven Erlebnissen und dem Verleih hochwertiger Technik.`
    },
  },
  {
    id: 2,
    title: {
      en: `HOW WE CAN 
      HELP YOU`,
      es: `CÓMO PODEMOS 
      AYUDARTE`,
      cat: `COM ET PODEM 
      AJUDAR`,
      ger: `WIE HELFEN 
      WIR DIR?`
    },
    text: {
      en: `Step into the realm of sonic preservation and innovation with our wide range of services.`,
      es: `Adéntrate en el ámbito de la preservación y la innovación sonora con nuestra amplia gama de servicios.`,
      cat: `Endinsa't en l'àmbit de la preservació i la innovació sonora amb la nostra àmplia gamma de serveis.`,
      ger: `Mit unserem Leistungspaket betrittst du das Reich der Klangbewahrung und Innovation.`
    }
  },
  {
    id: 3,
    title: {
      en: "",
      es: "",
      cat: "",
      ger: ""
    },
    text1: {
      en: `From the 3D safeguarding of acoustic and sound heritage using impulse response technology to 
      immersive VR/XR/360 experiences that transport you to unique acoustic spaces, we are the 
      pioneers dedicated to preserving and exploring the cultural and historical significance of sound.`,
      es: `Desde la salvaguardia en 3D del patrimonio acústico y sonoro utilizando la tecnología de 
      respuesta de impulso hasta experiencias inmersivas de VR/XR/360 que te transportan a espacios acústicos únicos, somos los 
      pioneros dedicados a preservar y explorar la importancia cultural e histórica del sonido.`,
      cat: `Des de la salvaguarda en 3D del patrimoni acústic i sonor utilitzant la tecnologia de 
      resposta d'impuls fins a experiències immersives de VR/XR/360 que et transporten a espais acústics únics, som els 
      pioners dedicats a preservar i explorar la importància cultural i històrica del so.`,
      ger: `Von der 3D-Sicherung des akustischen Erbes durch Impulsantwort-Methoden bis hin zu 
      immersiven VR/XR/360-Erlebnissen, die dich in einzigartige akustische Räume versetzen. Wir sind Pioniere, 
      die sich der Erhaltung und Erforschung der kulturellen und historischen Bedeutung von Klang widmen.
      `
    },
    text2: {
      en: `Engage with us through workshops, lectures, and research initiatives, or elevate your projects 
      with our state-of-the-art equipment rental and personalized assistance. Unleash the power 
      of sound with us – where heritage meets innovation!`,
      es: `Interactúa con nosotros a través de talleres, conferencias e iniciativas de investigación, o eleva tus proyectos 
      con nuestro alquiler de equipos de última generación y asistencia personalizada. Desata el poder 
      del sonido con nosotros, donde el patrimonio se encuentra con la innovación!`,
      cat: `Interactua amb nosaltres a través de tallers, conferències i iniciatives de recerca, o eleva els teus projectes 
      amb el nostre lloguer d'equips de última generació i assistència personalitzada. Desencadena el poder del so amb nosaltres, on el patrimoni es troba amb la innovació!`,
      ger: `Nimm an unseren Konferenzen und Forschungsinitiativen teil oder verbessere deine Projekte 
      mit unserem hochmodernen Geräteverleih und unserer persönlichen Unterstützung. Entfessle mit uns 
      die Kraft des Klangs: wo Erbe auf Innovation trifft!
      `
    },

  }
];



const texts = [
  {
    id: 0,
    text: {
      en: "Sonic Archive",
      es: "Archivo Sonoro",
      cat: "Arxiu Sonor",
      ger: "Sonic Archive "
    },
    subtext: {
      en: `3D Preservation of 
      acoustic and sound Heritage`,
      es: `Preservación 3D del 
      patrimonio acústico y sonoro `,
      cat: `Preservació 3D del 
      patrimoni acústic i sonor `,
      ger: `3D-Peservation von 
      akustischem und klanglichem Erbe
      `
    },
    content: {
      en: `We craft innovative sound and acoustic archives to perpetually preserve our history and heritage from a listening perspective. We are dedicated to preserving the unique acoustic properties of spaces such as churches, caves, industrial sites, and more. These spaces hold cultural and historical significance, and we are committed to ensuring their sonic legacies endure.

            Revolutionizing with cutting-edge tech and zero-impact methods, we protect what matters without harming our environment. 
            Modern. Creative. Sustainable.

            • Sound banks: In-situ 3d audio recordings of sounding objects (eg, machinery, instruments, tools), human activities and natural environments.

            • Acoustic banks: Measurement of space reverberation through spatial impulse responses (ambisonics).

            • Co-production with 3d visual modeling initiatives.
            `,

      es: `Creamos archivos sonoros y acústicos innovadores para preservar para siempre nuestra historia y patrimonio desde una perspectiva auditiva. Nos dedicamos no solo a preservar las propiedades acústicas únicas de espacios como iglesias, cuevas, sitios industriales, sino que también grabamos sonidos de objetos, actividades humanas y paisajes naturales. Estos espacios tienen importancia cultural, histórica y ecológica, y estamos comprometidos a asegurar que sus legados sonoros perduren.

      Revolucionamos con tecnología de vanguardia y métodos de impacto cero, protegiendo lo que importa sin dañar nuestro entorno. 
      Moderno. Creativo. Sostenible.

      • Bancos de sonido: Grabaciones de audio 3D in situ de objetos sonoros (por ejemplo, maquinaria, instrumentos, herramientas), actividades humanas y entornos naturales.

      • Bancos acústicos: Medición de la reverberación del espacio mediante respuestas al impulso espaciales (ambisonics).

      • Co-producción con iniciativas de modelado visual en 3D.
`,
      cat: `Creem arxius sonors i acústics innovadors per a preservar per sempre la nostra història i patrimoni des d'una perspectiva auditiva. Ens dediquem no sols a preservar les propietats acústiques úniques d'espais com a esglésies, coves, llocs industrials, sinó que també gravem sons d'objectes, activitats humanes i paisatges naturals. Aquests espais tenen importància cultural, històrica i ecològica, i estem compromesos a assegurar que els seus llegats sonors perdurin.

      Revolucionem amb tecnologia d'avantguarda i mètodes d'impacte zero, protegint el que importa sense danyar el nostre entorn. 
      Modern. Creatiu. Sostenible.
      
      • Bancs de so: Gravacions d'àudio 3D in situ d'objectes sonors (per exemple, maquinària, instruments, eines), activitats humanes i entorns naturals.

      • Bancs acústics: Mesurament de la reverberació de l'espai mitjançant respostes a l'impuls espacials (ambisonics).

      • Co-producció amb iniciatives de modelatge visual en 3D.
      `,

      ger: `Wir gestalten innovative Klang- und Akustikarchive, um unsere Geschichte und unser Erbe aus einer akustischen Perspektive fortwährend zu bewahren. Wir widmen uns der Bewahrung der einzigartigen akustischen Eigenschaften von Räumen wie Kirchen, Höhlen, Industriestandorten und mehr. Diese Räume haben kulturelle und historische Bedeutung, und wir setzen uns dafür ein, dass ihre klanglichen Erbschaften Bestand haben.
      
      Mit modernster Technologie und umweltfreundlichen Methoden revolutionieren wir, indem wir das schützen, was wichtig ist, ohne unsere Umwelt zu schädigen.
      Modern. Kreativ. Nachhaltig.
      
      • Soundbanks: In-situ 3D-Audioaufnahmen von klingenden Objekten (z.B. Maschinen, Instrumente, Werkzeuge), menschlichen Aktivitäten und natürlichen Umgebungen.

      • Akustikbanks: Messung der Raumhall durch räumliche Impulsantworten (Ambisonics).

      • Zusammenarbeit mit 3D-Visualisierungsinitiativen.`
    },
    footer: {
      en: `Professional Scope: Local Governments - Heritage administrations 
      Local Community Initiatives - Heritage Associations 
      Architecture and 3d modeling agencies - social science researchers`,
      es: `Público objetivo: Museos - Gobiernos Locales - Administraciones del Patrimonio 
      Iniciativas comunitarias locales - Asociaciones de Patrimonio
      Agencias de Arquitectura y Modelado 3D - Investigadores en Ciencias Sociales`,
      cat: `Públic objectiu: Museus - Governs Locals - Administracions del Patrimoni 
      Iniciatives comunitàries locals - Associacions de Patrimoni - Agències d'Arquitectura i Modelatge 3D - Investigadors en Ciències Socials`,
      ger: `Zielbereich: Lokale Regierungen - Denkmalpflegeverwaltungen 
      Initiativen der lokalen Gemeinschaft - Denkmalvereine - Architektur- und 3D-Modellierungsagenturen
      Sozialwissenschaftliche Forscher`
    }
  },

  {
    id: 1,
    text: {
      en: "Immersive experiences",
      es: "Experiencias inmersivas",
      cat: "Experiències immersives",
      ger: "Immersive Erfahrung"
    },
    subtext: {
      en: `VR/XR/360 production 
      design & consultancy`,
      es: `Diseño y consultoría 
      de producción VR/XR/360`,
      cat: `Disseny i consultoria 
      de producció VR/XR/360`,
      ger: `VR/XR/360 Produktionsdesign 
      & Beratung      `
    },
    content: {
      en: `We specialize in integrating spatial acoustics into VR/XR/360 productions, employing cutting-edge technological equipment. 
      
      From advanced recording devices such as Ambisonics and Binaural Microphones, 360 cameras, and 3D lidar scans to digital software processing using Unreal Engine, Reaper, Davinci, Touchdesigner, MaxMsp, Web Audio API, JavaScript, Python, and more.

      Our expertise extends to multichannel monitoring, incorporating Ambisonics Encoders, Dolby Atmos, and 5.1 systems. Output formats include multichannel audio files, 360 video with spatial audio, VR spatial simulation applications, interactive 360 website material and immersive physical design and implementation on real spaces.

      • Design and (Re)creation of Acoustic and sound spaces for museum immersive and/or interactive public experiences (in-situ, VR, web).
      •  Sound design and implementation for performing arts and AV creators (in-situ, VR, web).
      • Acoustic space simulation via auralization, allowing the placement of any audio as if it were in that space (real time or pre-designed).
      • Sound and acoustic recreation based on historical reviews of references (literature, press, interviews).
      • Sound narratives: Compelling storytelling using all these tools.
      • Interactive acoustic and sound websites and in-situ tools.
      • Spatial music mix services.
      • Acoustic Space simulation for architectural projects.`,

      es: `
      Nos especializamos en integrar acústica espacial en producciones de realidad virtual (VR), realidad extendida (XR) y vídeos 360, utilizando equipos tecnológicos de vanguardia. 
      
      Desde dispositivos de grabación avanzados como micrófonos Ambisonics y binaurales, cámaras 360 y escaneos lidar en 3D hasta procesamiento de software digital utilizando Unreal Engine, Reaper, Davinci, Touchdesigner, MaxMsp, Web Audio API, JavaScript, Python, y más. 
      
      Nuestra experiencia se extiende al monitoreo multicanal, incorporando Codificadores Ambisonics, Dolby Atmos y sistemas 5.1. Los formatos de salida incluyen archivos de audio multicanal, vídeos 360 con audio espacial, aplicaciones de simulación espacial de realidad virtual, material interactivo para sitios web en 360, y diseño e implementación inmersivos en espacios reales.
      
      • Diseño y (re)creación de espacios acústicos y sonoros para experiencias públicas inmersivas y/o interactivas en museos (in-situ, VR, web).
      • Diseño e implementación de sonido espacial para artes escénicas y creadores de AV (in-situ, VR, web).
      • Simulación acústica de espacios a través de auralización, permitiendo la ubicación de cualquier audio como si estuviera en ese espacio (en tiempo real o pre-diseñado).
      • Recreación de sonido y acústica basada en revisiones históricas de referencias (literatura, prensa, entrevistas).
      • Narrativas sonoras: Contar historias cautivadoras utilizando todas estas herramientas.
      • Sitios web interactivos de acústica y sonido, y herramientas in-situ.
      • Servicios de mezcla musical espacial.
      • Simulación de espacios acústicos para proyectos arquitectónicos.`,

      cat: `Ens especialitzem a integrar acústica espacial en produccions de realitat virtual (VR), realitat estesa (XR) i vídeos 360, utilitzant equips tecnològics d'avantguarda. 
      
      Des de dispositius de gravació avançats com a micròfons Ambisonics i binaurals, cambres 360 i escanejos lidar en 3D fins a processament de programari digital utilitzant Unreal Engine, Reaper, Davinci, Touchdesigner, MaxMsp, Web Àudio API, JavaScript, Python, i més. 
      
      • La nostra experiència s'estén al monitoratge multicanal, incorporant Codificadors Ambisonics, Dolby Atmos i sistemes 5.1. Els formats de sortida inclouen arxius d'àudio multicanal, vídeos 360 amb àudio espacial, aplicacions de simulació espacial de realitat virtual, material interactiu per a llocs web en 360, i disseny i implementació immersius en espais reals.
      • Disseny i (re)creació d'espais acústics i sonors per a experiències públiques immersives i/o interactives en museus (in-*situ, VR, web).
      • Disseny i implementació de so espacial per a arts escèniques i creadors de AV (in-situ, VR, web).
      • Simulació acústica d'espais a través de auralización, permetent la ubicació de qualsevol àudio com si estigués en aquest espai (en temps real o pre-dissenyat).
      • Recreació de so i acústica basada en revisions històriques de referències (literatura, premsa, entrevistes).
      • Narratives sonores: Contar històries captivadores utilitzant totes aquestes eines.
      • Llocs web interactius d'acústica i so, i eines in-*situ.
      • Serveis de mescla musical espacial.
      • Simulació d'espais acústics per a projectes arquitectònics.`,


      ger: `Wir haben uns darauf spezialisiert, räumliche Akustik in VR/XR/360-Produktionen zu integrieren und dabei modernste technologische Ausrüstung einzusetzen.

      Von fortschrittlichen Aufnahmegeräten wie Ambisonics- und Binaural-Mikrofonen, 360-Kameras und 3D-Lidar-Scans bis hin zur digitalen Softwareverarbeitung unter Verwendung von Unreal Engine, Reaper, Davinci, Touchdesigner, MaxMsp, Web Audio API, JavaScript, Python und mehr.
      
      Unsere Expertise erstreckt sich auf Mehrkanalüberwachung unter Verwendung von Ambisonics-Encodern, Dolby Atmos und 5.1-Systemen.
      
      • Die Ausgabeformate umfassen Mehrkanal-Audiodateien, 360-Videos mit räumlichem Audio, VR-Raumsimulationsanwendungen, interaktives 360-Webmaterial sowie immersive physische Gestaltung und Umsetzung in realen Räumen.
      • Design und (Re)kreation von akustischen und klanglichen Räumen für immersive und/oder interaktive Museumserlebnisse (in-situ, VR, Web).
      • Räumliches Sounddesign und Umsetzung für darstellende Künste und AV-Schöpfer (in-situ, VR, Web).
      • Akustische Raumsimulation durch Auralisation, die die Platzierung von Audio ermöglicht, als wäre es in diesem Raum (Echtzeit oder vorab entworfen).
      • Klang- und akustische Rekonstruktion basierend auf historischen Überprüfungen von Referenzen (Literatur, Presse, Interviews).
      • Klangnarrative: Fesselndes Geschichtenerzählen unter Verwendung all dieser Werkzeuge.
      • Interaktive akustische und klangliche Websites und In-situ-Werkzeuge.
      • Raumakustikmischdienste für räumliche Musik.
      • Akustische Raumsimulation für architektonische Projekte.`

    },
    footer: {
      en: `Professional Scope: Museums - Curators - Events
      Venues - Immersive Labs - Audiovisual creators 
      AV Artists - Performing arts - Musicians and/or Architects`,
      es: `Público objetivo: Museos - Curadores -  Eventos 
      Espacios - Laboratorios Inmersivos- Creadores Audiovisuales
      Artistas de AV - Artes Escénicas - Músicos y/o Arquitectos.
      `,
      cat: `Públic objectiu: Museus - Curadors - Esdeveniments
      Espais - Laboratoris Immersius - Creadors Audiovisuals
      Artistes de AV - Arts Escèniques, Músics i/o Arquitectes.`,
      ger: `Beruflicher Anwendungsbereich: Museen - Kuratoren - Veranstaltungen
      Veranstaltungsorte - Immersive Labs - Audiovisuelle Schöpfer
      AV-Künstler - Darstellende Künste - Musiker und/oder Architekten`
    }
  },

  {
    id: 2,
    text: {
      en: `Sound design and 
      digital applications`,
      es: `Diseño de sonido y
      aplicaciones digitales`,
      cat: `Disseny de so i aplicacions digitals`,
      ger: `Sounddesign und 
      digitale Anwendungen`
    },
    subtext: {
      en: "",
      es: "",
      cat: "",
      ger: ""
    },
    content: {
      en: `Innovative creativity, artistic originality or sounding exploration often requires designing customized tools for specific purposes. 
      
      We offer a wide range of possibilities for translating your ideas to digital devices (m4L, arduino, raspberry pi, web audio api) or dedicated audio production.

      •  Elaboration of customized real time audio plugins for Sound artists, Musicians, Composers sound productions and performances.

      •  Original 3d reverberation banks creations and acoustic spaces simulations for Advertising, Video game production and storytelling initiatives.

      •  Elaboration of customized audio tools for Art Therapy initiatives.

      •  Accessibility material production for content creators. (eg: visually impaired individuals, neurodiversity)`,

      es: `La creatividad innovadora, la originalidad artística o la exploración sonora a menudo requieren diseñar herramientas personalizadas para propósitos específicos. 
      
      Ofrecemos una amplia gama de posibilidades para traducir tus ideas a dispositivos digitales (m4L, Arduino, Raspberry Pi, Web Audio API) o producción de audio especializada.

      • Elaboración de plugins de audio en tiempo real personalizados para artistas sonoros, músicos, compositores y producciones y actuaciones sonoras.

      • Creación de bancos originales de reverberación en 3D y simulaciones de espacios acústicos para publicidad, producción de videojuegos e iniciativas de storytelling.
      
      • Elaboración de herramientas de audio personalizadas para iniciativas de arteterapia.

      • Producción de material de accesibilidad para creadores de contenido (por ejemplo, personas con discapacidad visual, neurodiversidad)`,

      cat: `La creativitat innovadora, l'originalitat artística o l'exploració sonora sovint requereixen dissenyar eines personalitzades per a propòsits específics. 
      
      Oferim una àmplia gamma de possibilitats per a traduir les teves idees a dispositius digitals (m4L, Arduino, Raspberry Pi, Web Àudio API) o producció d'àudio especialitzada.

      • Elaboració de plugins d'àudio en temps real personalitzats per a artistes sonors, músics, compositors i produccions i actuacions sonores.

      • Creació de bancs originals de reverberació en 3D i simulacions d'espais acústics per a publicitat, producció de videojocs i iniciatives de storytelling.

      • Elaboració d'eines d'àudio personalitzades per a iniciatives d'artteràpia.

      • Producció de material d'accessibilitat per a creadors de contingut (per exemple, persones amb discapacitat visual, neurodiversitad).
      .
      `,

      ger: `Innovative Kreativität, künstlerische Originalität oder klangliche Exploration erfordern oft die Gestaltung von maßgeschneiderten Werkzeugen für bestimmte Zwecke. 

            Wir bieten eine Vielzahl von Möglichkeiten, um Ihre Ideen auf digitale Geräte (m4L, Arduino, Raspberry Pi, Web Audio API) oder dedizierte Audioproduktion zu übertragen.
    
            • Ausarbeitung von maßgeschneiderten Echtzeit-Audio-Plugins für Klangkünstler, Musiker, Komponisten, Tonproduktionen und Aufführungen.

            • Original 3D-Hallbank-Kreationen und Simulationen von akustischen Räumen für Werbung, Videospieleproduktion und Erzählinitiativen.

            • Ausarbeitung von maßgeschneiderten Audio-Tools für Initiativen der Kunsttherapie.

            • Produktion von Material zur Barrierefreiheit für Content-Ersteller. (z.B. für sehbehinderte Menschen, Neurodiversität)`
    },
    footer: {
      en: `Professional Scope: Audiovisual creators - artists - Musicians
      Composers - Content creators - Advertising 
      Video game production - storytelling initiatives - Art Therapy initiatives - Education.`,
      es: `Público objetivo: Creadores audiovisuales - artistas - músicos - compositores
      creadores de contenido - publicidad - producción de videojuegos
      iniciativas de narración - iniciativas de arte terapéutico - educación.`,
      cat: `Públic objetctiu: Creadors audiovisuals - artistes - músics  
      compositors - creadors de contingut - publicitat - producció de videojocs
      iniciatives de narració - iniciatives d'art terapèutic - educació.`,
      ger: `Beruflicher Bereich: Audiovisuelle Kreative - Künstler - Musiker
      Komponisten - Content-Ersteller - Werbung
      Videospielproduktion - Erzählinitiativen - Kunsttherapie-Initiativen - Bildung.`
    }
  },

  {
    id: 3,
    text: {
      en: `Research and 
      Dissemination Hub`,
      es: `Centro de Investigación 
      y Difusión `,
      cat: `Centre de Recerca 
      i Difusió `,
      ger: `Hub für Forschung 
      und Dissemination`
    },
    subtext: {
      en: "",
      es: "",
      cat: "",
      ger: ""
    },
    content: {
      en: `Our collective conducts constant research on the interplay between acoustics, perception, and emotional responses. 

       We explore how acoustic spaces shape meaning and how they are experienced, offering fresh insights into the significance of sound and acoustics in our environment. Given the evolving landscape of cultural studies and the advancements in digital technologies, we strive to stay at the forefront of emerging perspectives on heritage, community and cultural significance. 
       
       • Critical and innovative acoustic and sound space research conducting.

       • Elaboration of dissemination material (academic articles, essays, tutorials…)

       • Conferences, congresses and seminars presentations.

       • Promote research cooperations between investigators from different study areas (archeology, sociology, psychology, history, musicology, anthropology, biology).`
      ,
      es: `Nuestro colectivo investiga constantemente la interacción entre la acústica, la percepción y las respuestas emocionales. 
      
      Exploramos cómo los espacios acústicos dan forma al significado y cómo se experimentan, ofreciendo nuevas perspectivas sobre la importancia del sonido y la acústica en nuestro entorno.  Dado el panorama en constante evolución de los estudios culturales y los avances en tecnologías digitales, nos esforzamos por mantenernos en la vanguardia de las perspectivas emergentes sobre el patrimonio, la comunidad y la importancia cultural.
      
      • Investigación crítica e innovadora en acústica y sonido espacial.
      
      • Elaboración de material de difusión (artículos académicos, ensayos, tutoriales, etc.).
      
      • Presentaciones en conferencias, congresos y seminarios.
      
      • Promoción de cooperaciones de investigación entre investigadores de diferentes áreas de estudio (arqueología, sociología, psicología, historia, musicología, antropología, biología).
`,

      cat: `El nostre col·lectiu investiga constantment la interacció entre l'acústica, la percepció i les respostes emocionals. 
      
      Explorem com els espais acústics donen forma al significat i com s'experimenten, oferint noves perspectives sobre la importància del so i l'acústica en el nostre entorn. Donat el panorama en constant evolució dels estudis culturals i els avanços en tecnologies digitals, ens esforcem per mantenir-nos a l'avantguarda de les perspectives emergents sobre el patrimoni, la comunitat i la importància cultural.

      • Recerca crítica i innovadora en acústica i so espacial.

      • Elaboració de material de difusió (articles acadèmics, assajos, tutorials, etc.).

      • Presentacions en conferències, congressos i seminaris.

      • Promoció de cooperacions de recerca entre investigadors de diferents àrees d'estudi (arqueologia, sociologia, psicologia, història, musicologia, antropologia, biologia).
       biologia).`,

      ger: `Unsere Gemeinschaft betreibt ständige Forschung zur Wechselwirkung zwischen Akustik, Wahrnehmung und emotionalen Reaktionen.

      Wir untersuchen, wie akustische Räume Bedeutung schaffen und wie sie erlebt werden, und bieten frische Einblicke in die Bedeutung von Klang und Akustik in unserer Umgebung. Angesichts der sich entwickelnden Landschaft der Kulturwissenschaften und der Fortschritte in digitalen Technologien streben wir danach, an vorderster Front aufkommender Perspektiven zu Erbe, Gemeinschaft und kultureller Bedeutung zu bleiben.
      
      • Kritische und innovative Forschung im Bereich Akustik und Klangraum.
      
      • Erstellung von Veröffentlichungsmaterial (akademische Artikel, Essays, Tutorials...)

      • Präsentationen auf Konferenzen, Kongressen und Seminaren.

      • Förderung von Forschungskooperationen zwischen Ermittlern aus verschiedenen Studienbereichen (Archäologie, Soziologie, Psychologie, Geschichte, Musikwissenschaft, Anthropologie, Biologie).`
    },
    footer: {
      en: `Professional Scope: Academic Conferences - Publications - Research Centers
      Study groups - Individual researchers - Art Research residencies - Local administrations.`,
      es: `Público objetivo: Conferencias Académicas - Publicaciones - Centros de Investigación
      Grupos de Estudio - Investigadores Individuales - Residencias de Investigación Artística- Administraciones Locales.`,
      cat: `Públic objectiu: Conferències Acadèmiques - Publicacions - Centres de Recerca - Grups d'Estudi - Investigadors Individuals - Residències de Recerca Artística - Administracions Locals.`,
      ger: `Beruflicher Bereich: Wissenschaftliche Konferenzen - Publikationen - Forschungszentren
      Studiengruppen - Einzelne Forscher - Kunstforschungsresidenzen - Lokale Verwaltungen.`
    }
  },

  {
    id: 4,
    text: {
      en: `Educational workshops 
      and lectures `,
      es: `Talleres y conferencias 
      educativas`,
      cat: `Tallers i xerrades 
      educatives `,
      ger: `Bildungsworkshops 
      und Vorträge`
    },
    subtext: {
      en: "",
      es: "",
      cat: "",
      ger: ""
    },
    content: {
      en: `We provide workshops and lectures specially designed for diverse audiences, encompassing various methodologies, theoretical frameworks, and experiential formats, suitable for different age groups and educational backgrounds. 
      
      A primary objective of our collective is to disseminate our knowledge and methodologies across diverse domains, aiming to attract new individuals, gather valuable feedback, and foster meaningful discussions. 

      • Auditory perception awareness activities such as soundwalks, listening sessions, sound exploration or field recording experiences.

      • Customized lectures for Art and Music classes interested in the potential of acoustic and sound heritage as a creative tool.

      • Customized lectures for Cultural Courses interested in the potential of acoustic and sound heritage as a creative tool for dissemination and community engagement.
      
      `,
      es: `Ofrecemos talleres y conferencias diseñados especialmente para una amplia gama de audiencias, abarcando diversas metodologías, marcos teóricos y formatos experienciales, adecuados para diferentes grupos de edad y antecedentes educativos. 
      
      Un objetivo principal de nuestro colectivo es difundir nuestro conocimiento y metodologías en diversos ámbitos, con la intención de atraer a nuevas personas, recopilar retroalimentación valiosa y fomentar discusiones significativas.

      • Actividades de conciencia de la percepción auditiva, como paseos sonoros, sesiones de escucha, exploración sonora o experiencias de grabación de campo.

      • Clases personalizadas para estudiantes de Arte y Música interesadas en el potencial del patrimonio acústico y sonoro como herramienta creativa.

      • Clases personalizadas para cursos culturales interesados en el potencial del patrimonio acústico y sonoro como herramienta creativa para la difusión y la participación comunitaria.
      `,

      cat: `Oferim tallers i conferències dissenyats especialment per a una àmplia gamma d'audiències, abastant diverses metodologies, marcs teòrics i formats experiencials, adequats per a diferents grups d'edat i antecedents educatius. 
      
      Un objectiu principal del nostre col·lectiu és difondre el nostre coneixement i metodologies en diversos àmbits, amb la intenció d'atreure a noves persones, recopilar retroalimentació valuosa i fomentar discussions significatives.

      • Activitats de consciència de la percepció auditiva, com a passejos sonors, sessions d'escolta, exploració sonora o experiències de gravació de camp.

      • Classes personalitzades per a estudiants d'Art i Música interessades en el potencial del patrimoni acústic i sonor com a eina creativa.

      • Classes personalitzades per a cursos culturals interessats en el potencial del patrimoni acústic i sonor com a eina creativa per a la difusió i la participació comunitària.
      `,
      ger: `Wir bieten Workshops und Vorträge, die speziell für diverse Zielgruppen konzipiert sind und verschiedene Methoden, theoretische Rahmenbedingungen, 
          und erlebnisorientierte Formate umfassen, die für verschiedene Altersgruppen und Bildungshintergründe geeignet sind. 
          
          Ein Hauptziel unseres Kollektivs ist es, unser Wissen und unsere Methodologien in verschiedenen Bereichen zu verbreiten, um neue Personen anzuziehen, wertvolles Feedback zu sammeln und bedeutende Diskussionen zu fördern.

          • Aktivitäten zur Bewusstseinsbildung für auditive Wahrnehmung wie Soundwalks, Hörsitzungen, Klangexplorationen oder Feldaufnahmeerlebnisse.

          • Maßgeschneiderte Vorträge für Kunst- und Musikunterrichte, die am Potenzial des akustischen und klanglichen Erbes als kreatives Werkzeug interessiert sind.

          • Maßgeschneiderte Vorträge für kulturelle Kurse, die am Potenzial des akustischen und klanglichen Erbes als kreatives Werkzeug für Verbreitung und Bürgerbeteiligung interessiert sind.
      `
    },
    footer: {
      en: `Professional Scope: Universities - Schools - Educational Centers
      Cultural Centers - Consortia - individual Initiatives
      Arts, music and Heritage Courses and lectures`,
      es: `Público objetivo: Universidades - Escuelas - Centros educativos
      Centros culturales - Consorcios - Iniciativas individuales
      Conferencias sobre cursos de arte, música y patrimonio`,
      cat: `Públic objectiu: Universitats - Escoles - Centres educatius
      Centres culturals - Consorcis - Iniciatives individuals
      Xerrades sobre cursos d'art, música i patrimoni`,
      ger: `Beruflicher Rahmen: Universitäten - Schulen - Bildungseinrichtungen
      Kulturzentren - Konsortien - individuelle Initiativen - Vorträge zu Kunst, Musik und Erbe Kursen`
    }
  },
  {
    id: 5,
    text: {
      en: "Rent with us ",
      es: "Alquila con nosotros ",
      cat: "Lloga amb nosaltres ",
      ger: "Miet bei uns"
    },
    subtext: {
      en: `Professional equipment 
      and personalized support`,
      es: `Equipo profesional 
      y soporte personalizado`,
      cat: `Equip professional 
      i suport personalitzat`,
      ger: `Professionelles Equipment 
      und persönliche Betreuung`
    },
    content: {
      en: `We have a wide range of professional and specialized equipment that you can hire to develop your own projects. 
      
      All of these items include personalized assistance to help you achieve your goals. Contact us to tell us your idea and what you need.`,

      es: `Disponemos de una amplia gama de equipos profesionales y especializados que puedes alquilar para desarrollar tus propios proyectos. 

      Todos estos elementos incluyen asistencia personalizada para ayudarte a alcanzar tus objetivos. Contáctanos para contarnos tu idea y lo que necesitas.`,

      cat: `Disposem d'una àmplia gamma d'equips professionals i especialitzats que pots llogar per a desenvolupar els teus propis projectes. 
      
      Tots aquests elements inclouen assistència personalitzada per a ajudar-te a aconseguir els teus objectius. Contacta'ns per a explicar-nos la teva idea i el que necessites.`,

      ger: `Wir haben eine breite Palette von professioneller und spezialisierter Ausrüstung, die Sie mieten können, um Ihre eigenen Projekte zu entwickeln. 
      
      Alle diese Artikel beinhalten eine persönliche Unterstützung, um Ihnen bei der Erreichung Ihrer Ziele zu helfen. Kontaktieren Sie uns, um uns Ihre Idee und Ihre Bedürfnisse mitzuteilen.`
    },
    gear: [
      {
        id: 1,
        title: {
          en: "Microphones",
          es: "Micrófonos",
          cat: "Micròfons",
          ger: "Mikrofone"
        },
        text: {
          en: `Sennheiser Ambeo VR (first-order ambisonic) microphone
             Neumann TL49
             Aquarium Contact and hydrophone microphone 
             Roland Binaural Microphone
             Electromagnetic Microphones`,
          es: `Micrófono Sennheiser Ambeo VR (ambisónico de primer orden)
             Neumann TL49
             Micrófono de contacto y hidrófono Aquarium 
             Micrófono binaural Roland
             Micrófonos electromagnéticos`,
          cat: `Micròfon Sennheiser Ambeo VR (àmbison de primer ordre)
             Neumann TL49
             Micròfon de contacte i hidròfon Aquarium 
             Micròfon binaural Roland
             Micròfons electromagnètics`,
          ger: `Sennheiser Ambeo VR (erstbestellendes ambisonisches) Mikrofon
             Neumann TL49
             Aquarium Kontakt- und Hydrofonmikrofon 
             Roland Binaural Mikrofon
             Elektromagnetische Mikrofone`
        }
      },
      {
        id: 2,
        title: {
          en: "Hard drive Audio Processing",
          es: "Hard drive Audio Processing",
          cat: "Hard drive Audio Processing",
          ger: "Hard drive Audio Processing"
        },
        text: {
          en: `Klark-Teknik DN 780 Reverb
              Universal Audio 2-1176 Compressor
              Universal Audio 2-610 Preamp`,
          es: `Reverb Klark-Teknik DN 780
              Compresor Universal Audio 2-1176
              Preamplificador Universal Audio 2-610`,
          cat: `Reverb Klark-Teknik DN 780
              Compressor Universal Audio 2-1176
              Preamplificador Universal Audio 2-610`,
          ger: `Reverb Klark-Teknik DN 780
              Kompressor Universal Audio 2-1176
              Vorverstärker Universal Audio 2-610`
        }
      },
      {
        id: 3,
        title: {
          en: "Recorders and Portable audio",
          es: "Grabadoras y audio portátil",
          cat: "Gravadores i àudio portàtil",
          ger: "Recorders und tragbares Audio"
        },
        text: {
          en: `Zoom F6 Multi-Channel Field Recorder
               Zoom H2n Multi-Channel Field Recorder
               Zoom`,
          es: `Grabadora de campo Zoom F6 de varios canales
               Grabadora de campo Zoom H2n de varios canales
               Zoom`,
          cat: `Gravadora d'àudio Zoom F6 de diversos canals
                Gravadora d'àudio Zoom H2n de diversos canals
                Zoom`,
          ger: `Zoom F6 Mehrkanal-Feldrekorder
                Zoom H2n Mehrkanal-Feldrekorder
                Zoom`
        }
      },
      {
        id: 4,
        title: {
          en: "Video, Photo and Scan",
          es: "Video, Foto y Escaneo",
          cat: "Vídeo, Foto i Escàner",
          ger: "Video, Foto und Scan"
        },
        text: {
          en: `insta 360 camera PRO 2
              insta 360 camera RS one twin
              Sony alpha 7 camera
              2 x 47” HD Video Monitor
              I-Pad Pro with LIDAR Sensor
              Lens Auto Focus
              3-Axis Gimbal Stabilizer
              VR/XR
              Oculus Quest 2 VR headset
              desktop PC - Nvidia3048`,
          es: `Cámara insta 360 PRO 2
              Cámara insta 360 RS one twin
              Cámara Sony alpha 7
              2 monitores de video HD de 47"
              I-Pad Pro con sensor LIDAR
              Enfoque automático de lente
              Estabilizador gimbal de 3 ejes
              VR/XR
              Auriculares VR Oculus Quest 2
              PC de escritorio - Nvidia3048`,
          cat: `Càmera insta 360 PRO 2
              Càmera insta 360 RS one twin
              Càmera Sony alpha 7
              2 monitors de vídeo HD de 47"
              I-Pad Pro amb sensor LIDAR
              Autofocus de lents
              Estabilitzador gimbal de 3 eixos
              VR/XR
              Auriculars VR Oculus Quest 2
              PC d'escriptori - Nvidia3048`,
          ger: `insta 360 Kamera PRO 2
              insta 360 Kamera RS one twin
              Sony alpha 7 Kamera
              2 x 47” HD Video Monitor
              I-Pad Pro mit LIDAR-Sensor
              Objektivautomatik
              3-Achsen-Gimbal-Stabilisator
              VR/XR
              Oculus Quest 2 VR-Headset
              Desktop-PC - Nvidia3048`
        }
      },
      {
        id: 5,
        title: {
          en: "Accessories",
          es: "Accesorios",
          cat: "Accessoris",
          ger: "Zubehör"
        },
        text: {
          en: `Microphone Stands
              Studio Speakers Stands
              Camera Stands
              Batteries
              windscreens
              MOTU traveler audio interface
              M-Audio 8 pre audio interface
              quadraphonic speakers
              Lens Auto Focus
              Portable HD Projector
              cables, connectors and adapters`,
          es: `Soportes para micrófono
              Soportes para altavoces de estudio
              Trípode para cámara
              Baterías
              Parabrisas
              Interfaz de audio MOTU traveler
              Interfaz de audio M-Audio 8 pre
              Altavoces cuadrafónicos
              Enfoque automático de lente
              Proyector HD portátil
              Cables, conectores y adaptadores`,
          cat: `Peus per a micròfon
              Peus per a altaveus d'estudi
              Trípode per a càmeres
              Bateries
              Paravents
              Interfície d'àudio MOTU traveler
              Interfície d'àudio M-Audio 8 pre
              Altaveus quadrafònics
              Autofocus de lents
              Projector HD portàtil
              Cables, connectors i adaptadors`,
          ger: `Mikrofonständer
              Studio-Lautsprecherständer
              Kameraständer
              Batterien
              Windschutzscheiben
              MOTU Traveler-Audio-Schnittstelle
              M-Audio 8 Pre-Audio-Schnittstelle
              Quadrophonische Lautsprecher
              Autofokus-Objektiv
              Tragbarer HD-Projektor
              Kabel, Stecker und Adapter`
        }
      }
    ],
    footer: {
      en: `Professional Scope: Audiovisual creators - artists - Musicians
      Composers - Content creators - Advertising
      Video game production - storytelling initiatives.`,
      es: `Público objetivo: Creadores audiovisuales - artistas - músicos - compositores
      creadores de contenido - publicidad- producción de videojuegos - iniciativas de narración.`,
      cat: `Públic objectiu: Creadors audiovisuals - artistes - músics
      compositors - creadors de contingut - publicitat
      producció de videojocs- iniciatives de narració.`,
      ger: `Beruflicher Rahmen: Audiovisuelle Kreative - Künstler - Musiker
      Komponisten - Content-Ersteller - Werbung
      Videospiele-Produktion- Storytelling-Initiativen.`
    }
  }
]

export { texts, headingText }