import React from 'react'
import { styled } from 'styled-components'

const Wrapper = styled.div`
  width:100vw;
  /*height:160vh;*/
  margin:auto;
  flex-direction:column;
  display:flex;
  margin-top:5.625rem;
  @media (max-width: 801px) {
    margin-top:3.75rem;
  
  }
  
`;

const Picture = styled.img`
width:100vw;
height:100vh;
object-fit:cover;
@media (max-width: 801px) {
    width:100%;
    height:auto;

  }
`

const Image = () => {
    return (
        <Wrapper>
            <Picture loading="eager"  src="/img/services/servicios2.webp" />
        </Wrapper>

    )
}

export default Image
