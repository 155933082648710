import React from 'react'
import { styled } from 'styled-components'
import { useLanguage } from '../../context/LanguageContext';
import { footerText } from '../../data/data';
import { Link } from 'react-router-dom';


const Wrapper = styled.footer`
  display: flex;
  width: 100vw;
  height:40vh;
  flex-direction: row;
  font-weight:200;
  font-size: 1.6rem;
  border-top:1px solid lightgray;
  @media (max-width: 768px) {
    flex-direction:column;
  }
`;

const FooterLeft = styled.div`
display:flex;
flex:1;
align-items:start;
margin-bottom:5rem;
padding-top:1rem;
@media (max-width: 768px) {
  margin-bottom:0rem;
  }
`
const FooterRight = styled.div`
display:flex;
flex:1;
margin-bottom:5rem;
padding-top:1rem;
@media (max-width: 768px) {
    flex-direction:column;
    align-items:center;
  }
`
const ContainerContact = styled.div`
display:flex;
flex:1;
color:gray;
`
const TextLeft = styled.p`
margin-left:5.5rem;
@media (max-width: 768px) {
    margin-left:2.5rem;
  
  }
`

const ContainerLinks = styled.div`
display:flex;
flex:2;
flex-direction:column;
column-gap:1rem;
@media (max-width: 768px) {
    flex:1;
  
  }
`

const Footer = () => {
  const { language } = useLanguage();
 
  const contactText = footerText.find((item) => item.id === 1)

  return (
    <Wrapper>
      <FooterLeft>
        <TextLeft className="light-font">ACOUSTIC HERITAGE COLLECTIVE 2024</TextLeft>
      </FooterLeft>
      <FooterRight>
        <ContainerContact>
        <Link style={{'color':'gray','textDecoration':'none' }}to={'/contact'}>
          <p className="light-font">{contactText.text[language]}</p>
        </Link>
        </ContainerContact>
        <ContainerLinks>
            <p className="light-font">acousticheritagecollective@gmail.com</p>
          <p style={{marginTop:'0px'}} className="light-font">patrimoniacustic@gmail.com</p>
        </ContainerLinks>
      </FooterRight>
    </Wrapper>
  )
}

export default Footer
