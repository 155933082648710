import React, { useState } from 'react';
import { styled } from 'styled-components';
import { useLanguage } from '../../context/LanguageContext';
import { headingsAbout, projectsData, academicsData } from '../../data/aboutData';

const Wrapper = styled.div`
  width: 88.28vw;
  margin: auto;
  padding-bottom:9.375rem;
  border-bottom: 1px solid #D9D9D9;
  display: flex;
  flex-direction: column;
  
`;

const Title = styled.p`
  font-size: 1.375rem;
  line-height: 1.375rem;
  margin-top: 4.688rem;
  letter-spacing:1px;
  font-weight: 275;
  cursor: pointer;
  color: ${(props) => (props.isCollapsed ? 'black' : '#818181')};
`;

const Container = styled.div`
  height: ${(props) => (props.isCollapsed ? '5vh' : 'auto')};
  font-size: 1.375rem;
  line-height: 1.375rem;
  border-top: 1px solid #D9D9D9;
  transition: height 0.3s ease-in-out;
`;

const ItemTitle = styled.p`
  text-transform: uppercase;
  letter-spacing:1.4px;
  margin-bottom:5px;
`;

const ItemText = styled.p`
  color: #818181;
  font-size: 2.188rem;
  line-height: 2.5rem;
  margin-top:0px;
  margin-bottom:0px;
`;

const TextContainer = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #D9D9D9;
  display: ${(props) => (props.isCollapsed ? 'none' : 'block')};
  transition: display 0.3s ease-in-out;
`;

export const SectionThree = () => {
  const { language } = useLanguage();

  const [residencesCollapsed, setResidencesCollapsed] = useState(true);
  const [academicsCollapsed, setAcademicsCollapsed] = useState(true);

  const toggleResidences = () => {
    setResidencesCollapsed(!residencesCollapsed);
  };

  const toggleAcademics = () => {
    setAcademicsCollapsed(!academicsCollapsed);
  };

  const residencesHeading = headingsAbout.find((item) => item.id === 6);
  const academicsHeading = headingsAbout.find((item) => item.id === 7);

  return (
    <Wrapper>
      <Title onClick={toggleResidences} isCollapsed={residencesCollapsed} className="light-font">
        {residencesHeading.text[language]}
      </Title>
      <Container isCollapsed={residencesCollapsed}>
        {projectsData.map((item, key) => (
          <TextContainer key={key} isCollapsed={residencesCollapsed}>
            <ItemTitle className="light-font">{item.title[language]}</ItemTitle>
            <ItemText className="thin-font">{item.description[language]}</ItemText>
          </TextContainer>
        ))}
      </Container>
      <Title onClick={toggleAcademics} isCollapsed={academicsCollapsed} className="light-font">
        {academicsHeading.text[language]}
      </Title>
      <Container isCollapsed={academicsCollapsed}>
        {academicsData.map((item, key) => (
          <TextContainer key={key} isCollapsed={academicsCollapsed}>
            <ItemTitle className="light-font">{item.title[language]}</ItemTitle>
            <ItemText className="thin-font">{item.text[language]}</ItemText>
          </TextContainer>
        ))}
      </Container>
    </Wrapper>
  );
};
