import { React, useEffect } from 'react';
import Header from './Header';
import Image from './Image';
import Parallax from './Parallax';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import { SectionThree } from './SectionThree';
import ImagesSection from './ImagesSection'
import Collaborators from './Collaborators';

const About = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  }, []);

  return (
    <div id="wrapper">
      <Header />
      <Image />
      <Parallax />
      <SectionOne />
      <ImagesSection />
      <Collaborators />
      <SectionTwo />
      <SectionThree/>
    </div>
  );
};

export default About;
