import React from 'react';
import styled from 'styled-components';
import { useLanguage } from '../../context/LanguageContext';

const Container = styled.div`
  width:100%;
`;

const Header = styled.h2`
margin-bottom:0px;
margin-top:0px;
margin-bottom:0px;
padding:0px;
font-size: 7.5rem;
font-weight: 300;
line-height: 6.875rem; /* 91.667% */
letter-spacing: -0.075rem;
text-transform: uppercase;
white-space:pre-line;
 @media (max-width: 801px) {
    font-size: 3.4375rem;
    font-style: normal;
    line-height: 3.4375rem; 
    letter-spacing: -0.10313rem;
    white-space:normal;
  }
`;


const HeadingAbout = ({ props, style = {} }) =>{

  const { language } = useLanguage()

    return (
        <Container style={style}>
            <Header setLineHeight={language} className="light-font">{props}</Header>
        </Container>

    )
}

export { HeadingAbout };