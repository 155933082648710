import React from 'react';
import { styled } from 'styled-components';
import { findElement } from '../../data/data';
import { useLanguage } from '../../context/LanguageContext';
import { HeadingAbout } from '../about/HeadingAbout';
import { headingText } from '../../data/servicesData';

const MainContainer = styled.div`
  width:100vw;
  height:100vh;
  position:relative;
  
`;

const Wrapper = styled.div`
  width: 88.28vw;
  height:auto;
  margin:auto;
  align-items: center;
  flex-direction:column;
  padding-bottom:5.625rem;
  display:flex;
  @media (max-width: 801px){
    padding-bottom:0rem;
  }
 
`;

const BackgroundPicture = styled.img`
  width:100vw;
  max-height:100vh;
  object-fit:cover;
  z-index:-1;
  position:absolute;
  @media (max-width: 801px) {
    height:auto;
  }
`;
/*
const FlexContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction:row;
  @media (max-width: 801px){
    flex-direction:column;
    height:45vh;
    margin-top:4.288rem;
  }
  
`;

const FlexLeft = styled.div`
  flex: 50%;
  z-index: 30;
  text-align: left;
  padding-left: 5.6rem; 
  width:50%;
  margin-top:5.625rem;
  padding-top:120px;
  @media (max-width: 801px){
    flex:auto;
    width:88.28vw;
    margin-left:auto;
    margin-right:auto;
    margin-top:2.5rem;
    padding-left:0px;
  }
`;

const FlexRight = styled.div`
  flex: 50%;
  z-index: 30;
  padding-right: 5.7rem; 
  color: white; 
  margin-top:5.625rem;
  padding-top:120px;
  @media (max-width: 801px){
   
    padding-top:0rem;
    padding-right: 0rem;
    width:88.28vw;
    margin-left:auto;
    margin-right:auto;
    margin-top:2.5rem;
    height:auto;
  
  }
`;
*/

const FlexContainer = styled.div`
 display:flex;
 align-items: center;
 flex-direction:row;
 width:100%;
 color:white;
 margin-top:120px;
 @media (max-width: 901px){
    flex-direction:column;
  }
`;

const FlexLeft = styled.div`
  flex:50%;
  display:flex;
  align-self: stretch;
  align-items: start; 
  margin-top:5.625rem;
  @media (max-width: 600px){
    flex:100%;
    margin-top:2.5rem;
  }
`;
const FlexRight = styled.div`
  flex:50%;
  display:flex;
  align-self: stretch;
  align-items: center; 
  font-size:3.438rem;
  line-height:3.438rem;
  @media (max-width: 768px){
    flex:100%;
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 275;
    line-height: 2.1875rem; 
    padding-top:0rem;
  }
`;

const TextContent = styled.p`
  font-size:3.4375rem;
  line-height:3.437rem;
  color: black;
  z-index: 15;
  margin-top:5.7rem;
  @media (min-width: 580px){
    color:white;
  }

  @media (min-width: 580px) and (max-width:900px){
    margin-top:2rem;
  }

  @media (min-width: 380px) and (max-width:580px){
    margin-top:11rem;
  }

  @media (max-width: 800px){
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 275;
    line-height: 2.1875rem; 
    @media (orientation: landscape) {
     display:none;
    }
  }
  
`;

const SubtitleText = styled.div`
margin-top:0rem;
`;

const Header = () => {
  const { language } = useLanguage();
  const headingInfo = findElement(headingText, 1)


  return (
    <div>
      <BackgroundPicture src="/img/services/servicios1.webp" alt="Background" />
      <MainContainer>
        <Wrapper>
          <FlexContainer>
            <FlexLeft>
              <HeadingAbout style={{ color: "white" }} props={headingInfo.title[language]} />
            </FlexLeft>
            <FlexRight>
              <SubtitleText>
                <TextContent>
                  {headingInfo.text[language]}
                </TextContent>
              </SubtitleText>
            </FlexRight>
          </FlexContainer>
        </Wrapper>
      </MainContainer>
    </div>
  );
};

export default Header;
