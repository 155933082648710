import React, { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { HeadingAbout } from './HeadingAbout';
import { theGang } from '../../data/aboutData';
import { useLanguage } from '../../context/LanguageContext';
import { headingsAbout, aboutTexts, aboutSmallTexts, producers, professionalTeam} from '../../data/aboutData';

const Wrapper = styled.div`
  width: 88.28vw;
  margin: auto;
  align-items: center;
  flex-direction: column;
  padding-bottom:9.375rem;
  border-bottom: 1px solid #D9D9D9;
  display: flex;
  @media (max-width: 801px) {
    padding-bottom:0px;
    border-bottom: none;
}
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  @media (max-width: 801px) {
    flex-direction:column;
    width:100%;
    height:60vh;
  }
`;

const FlexLeft = styled.div`
  flex: 50%;
  display: flex;
  align-self: stretch;
  align-items: start;
  margin-top:4.688rem;
  @media (max-width: 801px) {
    flex: 100%;
    width:100%;
  }
`;

const FlexRight = styled.div`
  flex: 50%;
  padding-left: 7rem;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  width: 62vw; 
  height: 60vh;
  overflow: hidden;
  margin-top:4.688rem;
  @media (max-width: 801px) {
    flex: 100%;
    width:100%;
    padding-left:0px;
    margin-top:1rem;
    overflow: auto;
  }
`;

const TypingText = styled.p`
  color: black;
  font-size:3.438rem;
  line-height:3.438rem;
  white-space: wrap; 
  margin-top:0px;
  @media (max-width: 801px) {
    font-size:2.188rem;
    line-height:2.188rem;
    
  }
`;

const TextContent = styled.div`
  font-size:2.188rem;
  line-height:2.5rem;
  margin-bottom:5.7rem;
`;

const Text = styled.div`
  width: 70%;
  @media (max-width: 801px) {
    width: 100%;
    
  }
`;

const FlexContainer = styled.div`
display:flex;
flex-direction:row;
font-size:1.375rem;
width:100%;
align-items:start;
@media (max-width: 801px) {
    width:auto;
  }
`;


const FlexContainerLeft = styled.div`
flex:50%;
justify-content:start;
height:7rem;
width:50%;
font-size: 1.375rem;
font-style: normal;
font-weight: 300;
line-height: 1.375rem; /* 100% */
letter-spacing: 0.055rem;
@media (min-width: 480px) {
   
  }
`;


const FlexContainerRight = styled.div`
flex:50%;
justify-content:start;
height:7rem;
width:50%;
color: #818181;
font-size: 2.1875rem;
font-style: normal;
font-weight: 275;
line-height: 2.5rem; 
`;

const FlexContainerSingle = styled.div`
display:flex;
justify-content:start;
height:7rem;
width:100%;
   flex:100%;
font-size: 1.375rem;
font-style: normal;
font-weight: 300;
line-height: 1.375rem; /* 100% */
letter-spacing: 0.055rem;
@media (max-width: 801px) {
   margin-top:4rem;
  }
`;

const TextBottom = styled.p`
  @media (max-width: 801px) {
    margin-top:1rem;
  }
`;

const MobileTextContainer = styled.div`
display:flex;
flex-direction:column;
width:100%;
border-bottom:1px solid #D9D9D9;
`;

const MobileTitle = styled.p`
font-size: 1.25rem;
font-style: normal;
font-weight: 300;
line-height: 1.375rem; /* 110% */
letter-spacing: 0.05rem;
text-transform: uppercase;

`;

const MobileSubtitle = styled.p`
color: #818181;
font-size: 2.1875rem;
font-style: normal;
font-weight: 275;
line-height: 2.5rem; 
margin-top:0px;
`;

const TeamText = styled.p`
font-size:1.8rem;
font-style: normal;
font-weight: 300;
line-height: 1.375rem; /* 110% */
letter-spacing: 0.05rem;
margin-bottom:6rem;
`;


const SectionTwo = () => {
  const smallText = aboutSmallTexts.find((text) => text.id === 7)
  const writingText = aboutTexts.find((item) => item.id == 4)
  const flexRightRef = useRef(null);
  const [resize, setResize] = useState(false);
  const [displayText, setDisplayText] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  const headingText = headingsAbout.find((item) => item.id == 5)
  const { language } = useLanguage()

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [resize]);

  const handleResize = (id) => {
    setResize(window.innerWidth <= 801);

  }

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const handleIntersection = (entries) => {
      const entry = entries[0];
      if (entry.isIntersecting && !hasAnimated) {
        setIsTyping(true);
        setHasAnimated(true);
      }
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (flexRightRef.current) {
      observer.observe(flexRightRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [hasAnimated]);

  useEffect(() => {
    if (isTyping) {
      const fullText = writingText.text[language]
      let index = 0;

      const intervalId = setInterval(() => {
        setDisplayText(fullText.substring(0, index + 1));
        index++;

        if (index === fullText.length) {
          clearInterval(intervalId);
        }
      }, 20);

      return () => clearInterval(intervalId);
    }
  }, [isTyping, language]);

  return (
    <Wrapper>
      <Container>
        <FlexLeft>
          <HeadingAbout props={headingText.text[language]} />
        </FlexLeft>
        <FlexRight ref={flexRightRef}>
          <TypingText>{displayText}</TypingText>
        </FlexRight>
      </Container>
      <TextContent>
        <Text>
          <TextBottom>
            {smallText.text[language]}
          </TextBottom>
        </Text>
      </TextContent>


      {producers.map((item) => {
        return (

          resize ? (
            <MobileTextContainer >
              <MobileTitle className="light-font">{item.name}</MobileTitle>
              <MobileSubtitle className="thin-font">{item.title[language]}</MobileSubtitle>
            </MobileTextContainer>
          ) : (
            <FlexContainer>
              <FlexContainerLeft className="light-font">{item.name}</FlexContainerLeft>
              <FlexContainerRight className="thin-font">{item.title[language]}</FlexContainerRight>
            </FlexContainer>
          )
        )
      })}

        <FlexContainerSingle> 
         <TeamText className="thin-font">{professionalTeam[0].text[language]}</TeamText>
        </FlexContainerSingle>     

      {theGang.map((item) => {
        return (

          resize ? (
            <MobileTextContainer >
              <MobileTitle className="light-font">{item.name}</MobileTitle>
              <MobileSubtitle className="thin-font">{item.title[language]}</MobileSubtitle>
            </MobileTextContainer>
          ) : (
            <FlexContainer>
              <FlexContainerLeft className="light-font">{item.name}</FlexContainerLeft>
              <FlexContainerRight className="thin-font">{item.title[language]}</FlexContainerRight>
            </FlexContainer>
          )
        )
      })}

    </Wrapper>
  );
};

export default SectionTwo;
