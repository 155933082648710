const introText = [
    {
        id: 1,
        text: {
            en: `SOUND IS OUR TOOL TO 
            REIMAGINE MEMORY`,
            es: `REIMAGINAMOS LA MEMORIA 
            A TRAVÉS DEL SONIDO`,
            cat: `REIMAGINEM LA MEMÒRIA 
            A TRAVÉS DEL SO`,
            ger: `SOUND ALS WERKZEUG, UM DIE 
            ERINNERUNG NEU ZU GESTALTEN`
        }    
    },

    {
        id: 2,
        text: {
            en: `We're a collective of art, culture and tech experts 
            dedicated to safeguarding and exploring innovative
            expressions of acoustic heritage.`,
            es: `Somos un colectivo de profesionales del arte, la tecnología 
            y la cultura comprometidos con la salvaguarda del patrimonio 
            acústico y la búsqueda de nuevas maneras de representarlo.`,
            cat: `Som un col·lectiu de professionals de l'art, la tecnologia 
            i la cultura compromesos amb la salvaguarda de patrimoni 
            acústic i la cerca de noves maneres de representar-ho.`,
            ger: `Wir sind eine Gruppe von Kunst-, Kultur- und Technikexperten, 
            die sich der Erforschung innovativer Ausdrucksformen 
            des akustischen Erbes widmen.`
        }
    },

    {
        id: 3,
        text: {
            en: `Want more insight? Here’s an inside look 
            into how we work with acoustic heritage`,
            es: `¿Quieres saber más? Conoce cómo 
            abordamos el patrimonio acústico.`,
            cat: `Vols saber més? Coneix com 
            abordem el patrimoni acústic.`,
            ger: `Willst du mehr wissen? Hier ein Einblick, 
            wie wir mit akustischem Erbe arbeiten.`
        },
    },
        {
            id: 4,
            text: {
                en: `Our services. 
                We deliver outstanding results by leveraging 
                cutting-edge tools and techniques.`,
                es: `Nuestros servicios. 
                Entregamos resultados de primera utilizando 
                herramientas y técnicas de vanguardia.`,
                cat: `Els nostres serveis. 
                Lliurem resultats de primera usant 
                eines i tècniques d'avantguarda.`,
                ger: `Unsere Leistungen. 
                Wir liefern außergewöhnliche Ergebnisse mit 
                den modernsten Tools und Techniken.`
            }
        }
];

const headings = [
    {
        id: 1,
        text: {
            en: `FUSING SPACE AND SOUND PERCEPTION 
            TO CRAFT IMMERSIVE NARRATIVES`,
            es: `ENTRELAZAMOS ESPACIO Y PERCEPCIÓN 
            SONORA PARA CREAR NARRATIVAS INMERSIVAS`,
            cat: `ENTRELLACEM ESPAI I PERCEPCIÓ SONORA 
            PER A CREAR NARRATIVES IMMERSIVES`,
            ger: `RAUM - UND KLANGWAHRNEHMUNG ZU 
            IMMERSIVEN ERZÄHLUNGEN VERSCHMELZEN`
        }
    },

    {
        id: 2,
        text: {
            en: `TAILORED ACOUSTIC SOLUTIONS 
            FOR MUSEUMS, ARTISTRY AND EDUCATION`,
            es: `SOLUCIONES ACÚSTICAS 
            PARA MUSEOS, ARTE Y EDUCACIÓN`,
            cat: `SOLUCIONS ACÚSTIQUES PER 
            A MUSEUS, ART I EDUCACIÓ`,
            ger: `INDIVIDUELL ANGEPASSTE AKUSTIKLÖSUNGEN 
            FÜR MUSEEN, KUNST UND BILDUNG`
        }
    },
    {
        id: 3,
        text: {
            en: `SOME OF OUR TOP \nCOLLABORATIONS`,
            es: `ALGUNAS DE NUESTRAS \nCOLABORACIONES`,
            cat: `ALGUNES DE LES NOSTRES \nCOL·LABORACIONS`,
            ger: `EINIGE UNSERER TOP 
            KOOPERATIONEN`
        }
    },

    {
        id: 4,
        text: {
            en: `GOT AN IDEA? 
            WE’D LOVE TO HEAR IT`,
            es: `¿TIENES ALGUNA IDEA?
            QUEREMOS ESCUCHARLA`,
            cat: `TENS UNA IDEA?
            VOLEM ESCOLTAR-LA`,
            ger: `EINE IDEE? 
            WIR HÖREN DICH GERNE`
        }
    },
];


const services = [
    {
        id: 0,
        name: {
            en: `SONIC ARCHIVE: 3D 
            PRESERVATION OF ACOUSTIC 
            AND SOUND HERITAGE`,
            es: `SONIC ARCHIVE: PRESERVACIÓN 3D 
            DEL PATRIMONIO 
            ACÚSTICO Y SONORO`,
            cat: `SONIC ARCHIVE: PRESERVACIÓ 3D 
            DEL PATRIMONI 
            ACÚSTIC I SONOR`,
            ger: `SONIC ARCHIVE: 3D-PRESERVATION 
            VON AKUSTISCHEN UND KLANGLICHEN ERBE`
        },

        nameMobile: {
            title:{
            en: `SONIC ARCHIVE`,
            es:`ARCHIVO SONORO`,
            cat:`ARXIU SONOR`,
            ger:`SONIC ARCHIVE`
            },
            subtitle:{
                en: `3D PRESERVATION OF ACOUSTIC AND SOUND HERITAGE`,
                es:`PRESERVACIÓN 3D DEL PATRIMONIO ACÚSTICO Y SONORO`,
                cat:`PRESERVACIÓ 3D DEL PATRIMONI ACÚSTIC I SONOR`,
                ger:`3D-PRESERVATION VON AKUSTISCHEN UND KLANGLICHEN ERBE`
                
            }
        },

        image: `img/home/sonicarchive.jpg`,
        video: `img/home/sonicarchive.mp4`,
    },
    {
        id: 1,
        name: {
            en: `IMMERSIVE EXPERIENCES. 
            VR/XR/360 PRODUCTION 
            DESIGN & CONSULTANCY`,
            es: `EXPERIENCIAS INMERSIVAS. 
            DISEÑO Y CONSULTORÍA DE 
            PRODUCCIÓN VR/XR/360`,
            cat: `EXPERIÈNCIES IMMERSIVES. 
            DISSENY I CONSULTORIA DE 
            PRODUCCIÓ VR/XR/360`,
            ger: `Immersive Erfahrung. VR/XR/360 Produktionsdesign & Beratung`
        },
        nameMobile: {
            title:{
            en: `IMMERSIVE EXPERIENCES.`,
            es: `EXPERIENCIAS INMERSIVAS.`,
            cat:`EXPERIÈNCIES IMMERSIVES.`,
            ger:`Immersive Erfahrung.`
            },
            subtitle:{
                en:`VR/XR/360 PRODUCTION DESIGN & CONSULTANCY`,
                es:`DISEÑO Y CONSULTORÍA DE PRODUCCIÓN VR/XR/360`,
                cat:`DISSENY I CONSULTORIA DE PRODUCCIÓ VR/XR/360`,
                ger:`VR/XR/360 Produktionsdesign & Beratung`
            }
        },
        image: `img/home/360.jpg`,
        video: `img/home/360.mp4`,
    },
    {
        id: 2,
        name: {
            en: `SOUND DESIGN AND 
            DIGITAL APPLICATIONS`,
            es: `DISEÑO DE SONIDO Y 
            APLICACIONES DIGITALES`,
            cat: `DISSENY DE SO I 
            APLICACIONS DIGITALS`,
            ger: `Sounddesign und 
            digitale Anwendungen`
        },
        nameMobile: {
            title:{
            en: `SOUND DESIGN AND DIGITAL APPLICATIONS`,
            es:`DISEÑO DE SONIDO Y APLICACIONES DIGITALES`,
            cat:`DISSENY DE SO I APLICACIONS DIGITALS`,
            ger:`KLANGDESIGN UND DIGITALE ANWENDUNGEN`
            },
            subtitle:{
                en: ``,
                es:``,
                cat:``,
                ger:``
            }
        },
        image: `img/home/sounddesign.jpg`,
        video: `img/home/sounddesign.mp4`,
    },
    {
        id: 3,
        name: {
            en: `RESEARCH AND 
            DISSEMINATION HUB`,
            es: `INVESTIGACIÓN Y 
            DIFUSIÓN`,
            cat: `RECERCA I 
            DIFUSIÓ`,
            ger: `Hub für Forschung und 
            Dissemination`
        },
        nameMobile: {
            title:{
            en: `RESEARCH AND DISSEMINATION HUB`,
            es:`INVESTIGACIÓN Y DIFUSIÓN`,
            cat:`RECERCA I DIFUSIÓ`,
            ger:`Hub für Forschung und Dissemination`
            },
            subtitle:{
                en: ``,
                es:``,
                cat:``,
                ger:``
            }
        },
        image: `img/home/research.jpg`,
        video: `img/home/research.mp4`,
    },
       
    {
        id: 4,
        name: {
            en: `EDUCATIONAL WORKSHOPS 
            AND LECTURES`,
            es: `TALLERES EDUCATIVOS 
            Y PONENCIAS PARTICIPATIVAS`,
            cat: `TALLERS EDUCATIUS 
            I PONÈNCIES PARTICIPATIVES`,
            ger: `Bildungsworkshops 
            und Vorträge`
        },
        nameMobile: {
            title:{
            en: `EDUCATIONAL WORKSHOPS 
            AND LECTURES`,
            es:`TALLERES EDUCATIVOS 
            Y PONENCIAS PARTICIPATIVAS`,
            cat:`TALLERS EDUCATIUS 
            I PONÈNCIES PARTICIPATIVES`,
            ger:`Bildungsworkshops und 
            Vorträge`
            },

            subtitle:{
                en: ``,
                es:``,
                cat:``,
                ger:``
            }
        },
        image: `img/home/lectures.jpg`,
        video: `img/home/lectures.mp4`,
    },
  
    {
        id: 5,
        name: {
            en: `RENT WITH US: 
            PROFESSIONAL EQUIPMENT 
            AND PERSONALIZED SUPPORT`,
            es: `ALQUILA CON NOSOTROS: 
            EQUIPO PROFESIONAL 
            Y ASISTENCIA PERSONALIZADA`,
            cat: `LLOGA AMB NOSALTRES: 
            EQUIPAMENT PROFESSIONAL 
            I ASSISTÈNCIA PERSONALITZADA`,
            ger: `Miet bei uns:
            Professionelles Equipment 
            und persönliche Betreuung`
        },
        nameMobile: {
            title:{
            en: `RENT WITH US`,
            es:`ALQUILA CON NOSOTROS`,
            cat:`LLOGA AMB NOSALTRES`,
            ger:`Miet bei uns`
            },
            subtitle:{
                en:`PROFESSIONAL EQUIPMENT AND PERSONALIZED SUPPORT`,
                es:`EQUIPO PROFESIONAL Y ASISTENCIA PERSONALIZADA`,
                cat:`EQUIPAMENT PROFESSIONAL I ASSISTÈNCIA PERSONALITZADA`,
                ger:`Professionelles Equipment und persönliche Betreuung`
            }
        },
        image: `img/home/rent.jpg`,
        video: `img/home/rent.mp4`,
    },
];

const contactText = [
    {
        id: 1,
        name: {
            en: `NAME`,
            es: `NOMBRE`,
            cat: `NOM`,
            ger: `NAME`
        },
        email: {
            en: `EMAIL`,
            es: `CORREO`,
            cat: `CORREU`,
            ger: `E-MAIL`
        },
        subject: {
            en: `SUBJECT`,
            es: `ASUNTO`,
            cat: `ASSUMPTE`,
            ger: `BETREFF`
        },
        message: {
            en: `MESSAGE`,
            es: `MENSAJE`,
            cat: `MISSATGE`,
            ger: `NACHRICHT`
        },
        send: {
            en: `SEND`,
            es: `ENVIAR`,
            cat: `ENVIAR`,
            ger: `SENDEN`
        }
    },
   
];




const buttonTexts = [
    {
        id: 1,
        text: {
            en: `GET TO KNOW US +`,
            es: `CONÓCENOS +`,
            cat: `CONEIX-NOS +`,
            ger: `MEHR ÜBER UNS +`
        }
    },

    {
        id: 2,
        text: {
            en: `SEE ALL PROJECTS +`,
            es: `VER PROYECTOS +`,
            cat: `VEURE PROJECTES +`,
            ger: `UNSERE PROJEKTEN +`
        }
    },

    {
        id: 3,
        text: {
            en: `LEARN MORE +`,
            es: `DESCUBRE MÁS +`,
            cat: `APREN MÉS +`,
            ger: `MEHR LERNEN +`
        }
    }
];


export { services, headings, introText, buttonTexts, contactText }