import React from 'react'
import Header from './Header';
import InfoSection from './InfoSection';
import { contactInfo, contactSmallTexts, headingsContact } from '../../data/contactData';

export const Contact = () => {
  return (
    <div>
    <Header/>
    <InfoSection/>
    </div>
  )
}


export default Contact;