import React, { useState, useEffect, useRef } from 'react';
import { styled } from 'styled-components';
import { texts } from '../../data/servicesData';
import { useParams } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';


const MainContainer = styled.div`
  width: 100vw;
  margin-bottom: 5.7rem;
`;

const ServiceElement = styled.div`
  min-height: ${(props) => (props.isExpanded ? (props.number === 5 ? '350vh' : '145vh') : 'auto')};
  background-color: ${(props) => (props.isExpanded ? '#FFF4F2' : 'F5F5F5')};
  width: 100vw;
  flex: 100%;
  border-bottom: 1px solid #818181;
  overflow: hidden;
  cursor: pointer;
  transition: height 0.3s ease-in-out, background 0.3s ease-in-out;
  @media (max-width: 801px) {
    width:100%;
    border-top: ${(props) => (props.number === 0 ? '1px solid #818181;':'none')};

  }
`;


const ServiceElementNoSubtitle = styled.div`
  height: ${(props) => (props.isExpanded ? 'auto' : 'auto')};
  background: ${(props) => (props.isExpanded ? '#FFF4F2' : 'F5F5F5')};
  width: 100vw;
  flex: 100%;
  border-bottom: 1px solid #818181;
  overflow: hidden;
  cursor: pointer;
  transition: height 0.3s ease-in-out, background 0.3s ease-in-out;
`;


const Wrapper = styled.div`
  width: 88.28vw;
  margin: auto;
 
`;

const TitleWrapper = styled.div`
  width: ${(props) => (props.isExpanded ? '55vw' : '70vw')};
  position:relative;
  background-color: ${(props) => (props.isExpanded ? '#FFF4F2' : 'F5F5F5')};
  @media (max-width: 801px) {
    width:100%;

  }
`;

const Picture = styled.img`
 display: ${(props) => (props.isExpanded ? 'block' : 'none')};
 position:absolute;
 top:${(props) => (props.id === 5 ? '-24rem' : '-15rem')};
 left:10vw;
 z-index:1;
 @media (max-width: 801px){
 display:none;
  }

  @media (min-width: 801px){
    left:-10vw;
  }

  @media (min-width: 1200px){
    left:10vw;
  }
`;


const Title = styled.p`
  color: black;
  opacity: ${(props) => (props.isExpanded ? '50%' : '100%')};
  font-size: 5.625rem;
  line-height: 5.625rem; /* 100% */
  letter-spacing: -0.05625rem;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 0px;
  margin-top:2.75rem;
  transition: color 0.3s ease-in-out;
  white-space:pre-line ;
  position:relative;
  z-index:2;
  @media (max-width: 801px) {
    font-size: 2.1875rem;
    font-weight: 275;
    line-height: 2.5rem; /* 114.286% */
    letter-spacing: 0.0875rem;

  }
`;

const SubTitle = styled.p`
  color: #818181;
  opacity: ${(props) => (props.isExpanded ? '50%' : '100%')};
  font-size: 5.625rem;
  line-height: 5.625rem; /* 100% */
  letter-spacing: -0.05625rem;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 2.75rem;
  transition: color 0.3s ease-in-out;
  white-space:pre-line;
  z-index:2;
  position:relative;
  @media (max-width: 801px) {
    font-size: 2.1875rem;
    font-weight: 275;
    line-height: 2.5rem; /* 114.286% */
    letter-spacing: 0.0875rem;

  }
`;

const Text = styled.p`
  font-size: 1.563rem;
  line-height:1.875rem;
  margin-top: 2rem;
  transition: 0.3s ease-in-out;
  white-space:pre-line;
  display: ${(props) => (props.isExpanded ? 'block' : 'none')};
  margin-bottom: 2.75rem;
  z-index:2;
  position:relative;
`;

const OrangeText = styled.p`
  font-size: 1.563rem;
  line-height:1.875rem;
  letter-spacing:1.25px;
  color: #ff3a00;
  text-transform:uppercase;
  transition: 0.3s ease-in-out;
  white-space:pre-line;
  display: ${(props) => (props.isExpanded ? 'block' : 'none')};
  margin-bottom: 2.75rem;
  z-index:2;
  position:relative;
`;

const FlexRentalContainer = styled.div`
  display:flex;
  flex-direction:column;
  width:88.28vw;
`;

const FlexRentalContainerItem = styled.div`
  display:flex;
  flex-direction:row;
  border-top:  ${(props) => (props.isExpanded ? '1px solid #818181' : 'none')};
  z-index:2;
`;

const FlexRentalLeft = styled.div`
  display:flex;
  flex:50%;
  color:gray;
  text-transform:uppercase;
`;

const FlexRentalRight = styled.div`
  display:flex;
  flex:50%;
  text-transform:uppercase;
`;

const TextGear = styled.p`
  font-size:1.563rem;
  line-height:2.5rem;
  margin-top: 2rem;
  transition: 0.3s ease-in-out;
  white-space:pre-line;
  display: ${(props) => (props.isExpanded ? 'block' : 'none')};
  z-index:2;
   @media (max-width: 801px) {
    font-size: 1rem;
    line-height:1.5rem;

  }
`;


const ServicesList = () => {
  const { id } = useParams();
  const { language } = useLanguage()
  const gearItems = texts.find((element) => element.id == 5)
  const gearItem = gearItems.gear

  const [expandedStates, setExpandedStates] = useState(texts.map(() => false));
 
  const elementScroll = (index) =>{
    const element = document.getElementById(`service-${index}`);
    if (element) {
      element.scrollIntoView({
        behavior: 'auto',
        block: 'start',
      });
    }
  }  

  const handleClick = (index) => {

    setExpandedStates((prevStates) => {
      const newExpandedStates = prevStates.map(() => false);
      if (prevStates[index] === true) {
        setTimeout(()=> elementScroll(index), 0);

      } else {

        newExpandedStates[index] = true;
        setTimeout(()=> elementScroll(index), 0);
      }

      return newExpandedStates;
    });
  };


  useEffect(() => {
    if (id) {
      setExpandedStates(() => {
        const newExpandedStates = texts.map(() => false);
        newExpandedStates[id] = true;
        return newExpandedStates;
      });

      setTimeout(()=> elementScroll(id), 100);
    }
  }, [id,texts]);



  return (
    <MainContainer>
      {texts.map((text, index) => (
        text.subtext[language] === '' ? (
          <ServiceElementNoSubtitle
            key={text.id}
            onClick={() => handleClick(index)}
            isExpanded={expandedStates[index]}
            id={`service-${text.id}`}
            number={text.id}
          >
            <Wrapper>
              <TitleWrapper isExpanded={expandedStates[index]}>
                <Title isExpanded={expandedStates[index]}>{text.text[language]}</Title>
                <SubTitle isExpanded={expandedStates[index]}>{text.subtext[language]}</SubTitle>
                <OrangeText isExpanded={expandedStates[index]}>{text.footer[language]}</OrangeText>
                <Text className="read-font" isExpanded={expandedStates[index]}>{text.content[language]}</Text>
                <Picture src={`/img/services/back${text.id}.webp`} isExpanded={expandedStates[index]} alt={text.text[language]} />
              </TitleWrapper>
            </Wrapper>
          </ServiceElementNoSubtitle>
        ) : (
          <ServiceElement
            key={text.id}
            onClick={() => handleClick(index)}
            isExpanded={expandedStates[index]}
            id={`service-${text.id}`}
            number={text.id}
          >
            <Wrapper>
              <TitleWrapper isExpanded={expandedStates[index]}>
                <Title isExpanded={expandedStates[index]}>{text.text[language]}</Title>
                <SubTitle isExpanded={expandedStates[index]}>{text.subtext[language]}</SubTitle>

                {index === 5 ?
                  (
                    <rentalBackground isExpanded={expandedStates[index]}>
                      <Text className="read-font" isExpanded={expandedStates[index]}>{text.content[language]}</Text>
                      <FlexRentalContainer>
                        {gearItem.map((element, i) => (
                          <FlexRentalContainerItem isExpanded={expandedStates[index]} key={i}>
                            <FlexRentalLeft>
                              <TextGear className="read-font" isExpanded={expandedStates[index]}>{element.title[language]}</TextGear>
                            </FlexRentalLeft>
                            <FlexRentalRight>
                              <TextGear className="read-font" isExpanded={expandedStates[index]}>{element.text[language]}</TextGear>
                            </FlexRentalRight>
                          </FlexRentalContainerItem>
                        ))}
                      </FlexRentalContainer>
                      <Picture id={text.id} src={`/img/services/back${text.id}.webp`} isExpanded={expandedStates[index]} alt={text.text[language]} />
                    </rentalBackground>
                  ) : (
                    <React.Fragment>
                      <OrangeText isExpanded={expandedStates[index]}>{text.footer[language]}</OrangeText>
                      <Text className="read-font" isExpanded={expandedStates[index]}>{text.content[language]}</Text>
                      <Picture id={text.id} src={`/img/services/back${text.id}.webp`} isExpanded={expandedStates[index]} alt={text.text[language]} />
                    </React.Fragment>
                  )
                }
              </TitleWrapper>
            </Wrapper>
          </ServiceElement>
        )
      ))}
    </MainContainer>
  );
};

export default ServicesList;
