import { React, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { artProjectsList } from '../../data/projectsData.js';
import { styled } from 'styled-components';
import Header from './Header'
import { useLanguage } from '../../context/LanguageContext.js';

const Wrapper = styled.div`
  display: flex;
  width: 88.20vw;
  margin: auto;
  flex-direction: column;
`;

const ListWrapper = styled.div`
display: flex;
flex-direction: row;
font-size:1.3rem;
line-height:1.3rem;
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: column; 
  overflow: hidden;
  flex: 28%; 
  height:65vh; 

  @media (max-width: 850px) {
    flex-direction: column;
    align-items: center;
    width: 100%; 
    flex: 100%;
    height: auto; 
    margin-bottom:2.5rem;

  }
`;


const List = styled.ul`
  display: flex;
  flex-wrap: wrap; 
  width: 100%; 
  padding: 0; 
  column-gap:1.25rem;
  align-items: stretch;
`;

const Picture = styled.img`
  width:100%;
  height:100%;
 object-fit:cover;
  
`;

const Description = styled.section`
  display: flex;
  margin: 0;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 801px) {
    padding-left: 0;
    width: 100%;

   
    @media (orientation: landscape) {
      align-items: center;
    }
  }
`;

const TextContainer = styled.div`
  width: 100%;

`;
const TitleContainer = styled.div`
  display:flex;
  flex-direction:column;
  letter-spacing:0.7px;
`;

const ProjectTitle = styled.p`
text-transform:uppercase;
margin-bottom:2px;
margin-top:10px;
white-space:pre-line;
`;

const ProjectSubTitle = styled.p`
align-self:start;
justify-self:end;
color:#818181; 
margin-top:0rem;
text-transform:uppercase;
`;

const ProjectDescription = styled.p`
align-self:start;
justify-self:end;
color:#818181; 
margin-top:10px;
font-size:1.4rem;
line-height:1.4rem;
`;



const ProjectList = () => {

  const { language } = useLanguage()
  const [hoveredItemId, setHoveredItemId] = useState(null);
 

  const linkStyle = {
    textDecoration: 'none',
    color: 'inherit',
  };

   useEffect(() => {
    const element = document.getElementById(`wrapper`);
    if (element) {
      element.scrollIntoView({
        behavior: 'auto',
        block: 'start',
      });
    }

  }, [])

  return (
    <Wrapper id="wrapper">
      <Header/>
      <ListWrapper>
        <List>
          {artProjectsList.map((project) => {
            return (
              <ListItem
                key={project.id}
                onMouseEnter={() => setHoveredItemId(project.id)}
                onMouseLeave={() => setHoveredItemId(null)}
              >
                <div>
                  <Link to={`/project/${project.id}`} style={linkStyle}>
                    <Picture loading="lazy" src={project.image} alt={project.title[language]} />
                  </Link>
                </div>
                <Description>
                  <Link to={`/project/${project.id}`} style={linkStyle}>
                    <TextContainer>
                      {hoveredItemId === project.id ? (
                        <ProjectDescription>{project.description[language]}</ProjectDescription>
                      ) : (
                        <TitleContainer>
                        <ProjectTitle>{project.title[language]}</ProjectTitle>
                        <ProjectSubTitle>{project.place[language]}</ProjectSubTitle>
                        </TitleContainer>
                      )}
                    </TextContainer>
                  </Link>
                </Description>  
              </ListItem>
            );
          })}
        </List>
      </ListWrapper>
    </Wrapper>
  );
};

export default ProjectList;
