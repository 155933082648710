

const logos = [
  {
    id: 1,
    text: "eurom",
    src: "img/logos/eurom.webp",
  },
  {
    id: 2,
    text: "europe",
    src: "img/logos/europe.webp",
  },

  {
    id: 3,
    text: "goethe",
    src: "img/logos/goethe.webp",
  },

  {
    id: 4,
    text: "eu",
    src: "img/logos/eu.webp",
  },

  {
    id: 5,
    text: "cultura",
    src: "img/logos/cultura.webp",
  },

  {
    id: 6,
    text: "españa",
    src: "img/logos/esp.webp",
  },
]

const logosAbout = [
  {
    id: 1,
    text: "eurom",
    src: "img/logos/eurom.webp",
  },
  {
    id: 2,
    text: "khora",
    src: "img/logos/khora.webp",
  },

  {
    id: 3,
    text: "studies",
    src: "img/logos/studies.webp",
  },

  {
    id: 4,
    text: "lestruch",
    src: "img/logos/lestruch.webp",
  },

  {
    id: 5,
    text: "iceland",
    src: "img/logos/iceland.webp",
  },

  {
    id: 6,
    text: "mnactec",
    src: "img/logos/mnactec.webp",
  },
  {
    id: 7,
    text: "kings",
    src: "img/logos/kings.webp",
  },
  {
    id: 8,
    text: "monom",
    src: "img/logos/monom.webp",
  },
  {
    id: 9,
    text: "phonos",
    src: "img/logos/phonos.webp",
  },
  {
    id: 10,
    text: "portunus",
    src: "img/logos/portunus.webp",
  },
  {
    id: 11,
    text: "cultura",
    src: "img/logos/cultura.webp",
  },
  {
    id: 12,
    text: "unzip",
    src: "img/logos/unzip.webp",
  },
  {
    id: 13,
    text: "goethe",
    src: "img/logos/goethe.webp",
  },
  {
    id: 14,
    text: "icelandarts",
    src: "img/logos/icelandarts.webp",
  },
  {
    id: 15,
    text: "espana",
    src: "img/logos/esp.webp",
  },
  {
    id: 16,
    text: "iasa",
    src: "img/logos/iasa.webp",
  },
  {
    id: 17,
    text: "kent",
    src: "img/logos/kent.webp",
  },
  {
    id: 18,
    text: "sorbonne",
    src: "img/logos/sorbonne.webp",
  },
  {
    id: 19,
    text: "prat",
    src: "img/logos/prat.webp",
  },
  {
    id: 20,
    text: "d4sound",
    src: "img/logos/4dsound.webp",
  },
  {
    id: 21,
    text: "europe",
    src: "img/logos/europe.webp",
  },
  {
    id: 21,
    text: "greenwich",
    src: "img/logos/greenwich.webp",
  },
  {
    id: 21,
    text: "europeana",
    src: "img/logos/eu.webp",
  },
  {
    id: 22,
    text: "europeana",
    src: "img/logos/gerador.webp",
  },
  {
    id: 23,
    text: "treble",
    src: "img/logos/treble.webp",
  },
  {
    id: 24,
    text: "treble",
    src: "img/logos/capsa.webp",
  },
  {
    id: 25,
    text: "stansa",
    src: "img/logos/stansa.webp",
  },
  {
    id: 26,
    text: "ircam",
    src: "img/logos/ircam.png",
  },

]




const navBarText = [
  {
    id: 1,
    about: {
      en: "ABOUT",
      es: "CONÓCENOS",
      cat: "CONEIX-NOS",
      ger: "ÜBER"
    }
  },
  {
    id: 2,
    projects: {
      en: "PROJECTS",
      es: "PROYECTOS",
      cat: "PROJECTES",
      ger: "PROJEKTE"
    }
  },
  {
    id: 3,
    services: {
      en: "SERVICES",
      es: "SERVICIOS",
      cat: "SERVEIS",
      ger: "LEISTUNGEN"
    }
  },
  {
    id: 4,
    contact: {
      en: "CONTACT",
      es: "CONTACTO",
      cat: "CONTACTE",
      ger: "KONTAKT"
    }
  },
  {
    id: 5,
    lang: {
      en: "LANGUAGE",
      es: "IDIOMA",
      cat: "IDIOMA",
      ger: "SPRACHE"
    }
  },
];

const footerText = [
  {
    id: 1,
    text: {
      en: "CONTACT",
      es: "CONTACTO",
      cat: "CONTACTE",
      ger: "KONTAKT"
    }
  },

]

const findElement = (item, id) =>{
  return item.find((element) => element.id === id);
}


export { logos, navBarText, logosAbout, footerText, findElement}
