import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLanguage } from '../../context/LanguageContext';


const Container = styled.div`
 
  font-size: 5.625rem;
  line-height: 5.625rem;
  letter-spacing: -0.01em;
  font-weight: normal;
  @media (max-width: 801px) {
    font-size: 2.1875rem;
    font-weight: 275;
    line-height: 2.5rem; /* 114.286% */
    letter-spacing: -0.02188rem;
  }
`;

const Header = styled.p`
  margin-top: 5.625rem;
  margin-bottom: 5.625rem;
  text-transform:uppercase;
  white-space:pre-line;
  @media (max-width: 801px) {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
    white-space:${(props) => (props.spacing ? 'pre-line' : 'normal')};
  }
`;

const Color = styled.span`
  color: #ff3a00;
`;

const Heading = ({ props, spacing, style = {} }) => {
  const { language } = useLanguage()
  const words = props.split(" ");
  const [newText, setNewText] = useState([]);
  const wordArray = ["SOUND", "SOLUTIONS", "SONORA", "SOLUCIONES",
    'KLANGWAHRNEHMUNG', 'ACÚSTIQUES', 'AKUSTIKLÖSUNGEN', 'GET', 'IN', 'TOUCH',
    'PROJECTS', 'IDEAS', 'CONTACTO', 'PROYECTOS', 'IDEAS.', 'CONTACTE', 'PROJECTES',
    'IDEES.', 'KONTAKT', 'PROJEKTE', 'IDEEN']

  useEffect(() => {
    const updatedText = words.map((word, index) => (
      <React.Fragment key={index}>
        {index > 0 && ' '}
        {wordArray.includes(word) ? <Color>{word}</Color> : word}
      </React.Fragment>
    ));
    setNewText(updatedText);
  }, [language]);



  return (
    <Container style={style}>
      <Header spacing={spacing}>{newText}</Header>
    </Container>
  );
};

export default Heading;
