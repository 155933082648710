

const artProjectsList = [
  {
    id: 1,
    title: {
      en: `Open Platform of The Spanish 
      Acoustic Heritage`,
      es: `Plataforma Abierta del Patrimonio 
      Acústico Español`,
      cat: `Plataforma Oberta del Patrimoni 
      Acústic Espanyol`,
      ger: `Offene Plattform des spanischen 
      akustischen Erbes`,
    },

    description: {
      en: `An open library dedicated to compiling and sharing a vast collection of impulse responses.`,
      es: `Una biblioteca abierta dedicada a compilar y compartir una vasta colección de respuestas al impulso.`,
      cat: `Una biblioteca oberta dedicada a compilar i compartir una àmplia col·lecció de respostes a impulsos.`,
      ger: `Eine offene Bibliothek, die sich der Zusammenstellung und Weitergabe einer umfangreichen Sammlung von Impulsantworten.`,
    },
    longDescription1: {
      en: `A open library dedicated to compiling and sharing an extensive collection of responses to the impulse from various captivating acoustic environments across the country.`,
      es: `Una biblioteca abierta dedicada a recopilar y compartir una extensa colección de respuestas al impulso de varios entornos acústicos cautivadores en todo el país.`,
      cat: `Una biblioteca oberta dedicada a recopilar i compartir una extensa col·lecció de respostes a l\`impuls de diversos entorns acústics captivadors arreu del país.`,
      ger: `Eine offene Bibliothek, die sich der Zusammenstellung und gemeinsamen Nutzung einer umfangreichen Sammlung von Reaktionen auf den Impuls aus verschiedenen faszinierenden akustischen Umgebungen im ganzen Land widmet.`,
    },

    longDescription2: {
      en: `This website provides an invaluable resource to explore the distinctive characteristics of concert halls, cathedrals, theaters, and other unique spaces. It grants access to authentic reverberations from various locations in Spain, offering high-quality impulse responses that can enhance recordings for composers, artists, sound engineers, gamers, and more.`,
      es: `Este sitio web proporciona un recurso invaluable para explorar las características distintivas de salas de conciertos, catedrales, teatros y otros espacios únicos. Ofrece acceso a reverberaciones auténticas de varios lugares en España, brindando respuestas de impulso de alta calidad que pueden mejorar las grabaciones para compositores, artistas, ingenieros de sonido, jugadores y más.`,
      cat: `Aquest lloc web proporciona un recurs valuós per explorar les característiques distintives de sales de concerts, catedrals, teatres i altres espais únics. Ofereix accés a reverberacions autèntiques de diversos llocs d\'Espanya, oferint respostes d\'impuls de alta qualitat que poden millorar les gravacions per a compositors, artistes, enginyers de so, jugadors i més.`,
      ger: `Diese Website bietet eine unschätzbare Ressource, um die charakteristischen Merkmale von Konzertsälen, Kathedralen, Theatern und anderen einzigartigen Räumen zu erkunden. Sie gewährt Zugang zu authentischen Nachhallungen aus verschiedenen Orten in Spanien und bietet hochwertige Impulsantworten, die Aufnahmen für Komponisten, Künstler, Toningenieure, Spieler und mehr verbessern können.`,
    },
    image: `/img/projects/01/01.webp`,
    image1: `/img/projects/01/portada.webp`,
    image2: `img/projects/01/img1.webp`,
    image3: `img/projects/01/img2.webp`,
    place: {
      en: `Spain`,
      es: `España`,
      cat: `Espanya`,
      ger: `Spanien`,
    },
    link: `https://acousticheritagecollective.org/plataformaespana/index.html`
  },
  {
    id: 2,
    title: {
      en: `Sounds of the industry`,
      es: `Sonidos de la industria`,
      cat: `Sons de la indústria`,
      ger: `Klänge der Industrie`,
    },
    description: {
      en: `An archive of the sound and acoustic heritage of the process of industrialisation in Catalonia`,
      es: `Un archivo del patrimonio sonoro y acústico del proceso de industrialización de Cataluña.`,
      cat: `Salvaguarda i arxiu del patrimoni sonor i acústic del procés d\'industrialització de Catalunya.`,
      ger: `Sicherung und Archivierung des Klang - und Akustikerbes des Industrialisierungsprozesses in Katalonien.`,
    },
    longDescription1: {
      en: `"The Sounds of Industry" is a project aimed at preserving the sound heritage of industrial spaces in Catalonia. Sound is a crucial element in our daily lives and, consequently, plays a significant role in our history. 
      
      This archive rescues the soundscapes from the industrialization processes in Catalonia, as well as the current sounds from museums and centers that exhibit this heritage.`,
      es: `"Los Sonidos de La Industria" es un proyecto destinado a preservar el patrimonio sonoro de los espacios industriales en Cataluña. El sonido es un elemento crucial en nuestra vida diaria y, en consecuencia, desempeña un papel significativo en nuestra historia. 
      
      Este archivo rescata los paisajes sonoros de los procesos de industrialización en Cataluña, así como los sonidos actuales de museos y centros que exhiben este patrimonio.`,
      cat: `"Sons de la Indústria" és un projecte destinat a preservar el patrimoni sonor dels espais industrials a Catalunya. El so és un element crucial en la nostra vida diària i, per tant, juga un paper significatiu en la nostra història. 
      
      Aquest arxiu rescata els paisatges sonors dels processos d'industrialització a Catalunya, així com els sons actuals dels museus i centres que exhibeixen aquest patrimoni.`,
      ger: `"Klänge der Industrie" ist ein Projekt zur Erhaltung des Klangerbes industrieller Räume in Katalonien. Der Klang ist ein entscheidendes Element in unserem täglichen Leben und spielt folglich eine bedeutende Rolle in unserer Geschichte. 
      
      Dieses Archiv rettet die Klanglandschaften aus den Industrialisierungsprozessen in Katalonien sowie die aktuellen Klänge aus Museen und Zentren, die dieses Erbe ausstellen.`,
    },

    longDescription2: {
      en: `Here, you can explore the sounds of different machines and spaces from the museums and centers within the Territorial System of the National Museum of Science and Technology of Catalonia (MNACTEC). We invite you to listen to them.`,
      es: `Aquí, puedes explorar los sonidos de diferentes máquinas y espacios de los museos y centros dentro del Sistema Territorial del Museo Nacional de Ciencia y Tecnología de Cataluña (MNACTEC). Te invitamos a escucharlos.`,
      cat: `Aquí, pots explorar els sons de diferents màquines i espais dels museus i centres dins del Sistema Territorial del Museu Nacional de Ciència i Tecnologia de Catalunya (MNACTEC). Et convidem a escoltar-los.`,
      ger: `Hier kannst du die Klänge verschiedener Maschinen und Räume aus den Museen und Zentren im Territorial System des Nationalen Museums für Wissenschaft und Technologie von Katalonien (MNACTEC) erkunden. Wir laden dich ein, ihnen zuzuhören.`,
    },
    image: `/img/projects/02/02.webp`,
    image1: `img/projects/02/portada.webp`,
    image2: `3gwakM1mNIg`,
    image3: `img/projects/02/img2.webp`,
    place: {
      en: `Catalonia, Spain`,
      es: `Cataluña, España`,
      cat: `Catalunya, Espanya`,
      ger: `Katalonien, Spanien`,
    },
    link: `https://acousticheritagecollective.org/archivomnactec/index.html`
  },
  {
    id: 3,
    title: {
      en: `Safeguard the acoustic 
      heritage of El Prat!`,
      es: `¡Rescatemos el patrimonio 
      acústico del Prat!`,
      cat: `Rescatem el patrimoni 
      acústic del Prat!`,
      ger: `Akustisches Erbe von 
      El Prat schützen!`,
    },
    description: {
      en: `Community Art Project: Interweaving territory into music.`,
      es: `Proyecto de Arte Comunitario: Entrelazando el territorio con la música.`,
      cat: `Projecte d\`Art Comunitari: Entrellaçant el territori amb la música.`,
      ger: `Gemeinschaftskunstprojekt: Verweben des Territoriums mit die Musik.`,
    },
    longDescription1: {
      en: `This proposal seeks to value what is local. No one knows spaces better than the community that inhabits them. We aim to provide a methodology that allows the incorporation of the territory into musical production in an aesthetic and discursive manner. 
      
      Working musically with our own spaces enables us to build identity and understand that music is not only sound but also space.`,
      es: `Esta propuesta busca valorar lo local. Nadie conoce los espacios mejor que la comunidad que los habita. Nuestro objetivo es proporcionar una metodología que permita la incorporación del territorio en la producción musical de manera estética y discursiva. 
      
      Trabajar musicalmente con nuestros propios espacios nos permite construir identidad y entender que la música no es solo sonido, sino también espacio.`,
      cat: `Aquesta proposta busca valorar allò local. Ningú coneix millor els espais que la comunitat que els habita. El nostre objectiu és proporcionar una metodologia que permeti la incorporació del territori en la producció musical de manera estètica i discursiva. 
      
      Treballar musicalment amb els nostres propis espais ens permet construir identitat i entendre que la música no és només so sinó també espai.`,
      ger: `Dieser Vorschlag zielt darauf ab, den lokalen Wert zu schätzen. Niemand kennt Räume besser als die Gemeinschaft, die in ihnen lebt. 
      
      Unser Ziel ist es, eine Methodik bereitzustellen, die die Integration des Territoriums in die musikalische Produktion auf ästhetische und diskursive Weise ermöglicht. Die musikalische Arbeit mit unseren eigenen Räumen ermöglicht es uns, Identität aufzubauen und zu verstehen, dass Musik nicht nur Klang, sondern auch Raum ist.`,
    },

    longDescription2: {
      en: `This is the result of a community process involving the residents of El Prat as part of the winning project of the call /UNZIP 2021 in the Music and Territory category. The goal is to raise awareness of the threats facing heritage and promote the community's active role in generating political, critical, aesthetic, and artistic discourses through music and listening.`,
      es: `Este es el resultado de un proceso comunitario que involucra a los residentes de El Prat como parte del proyecto ganador de la convocatoria /UNZIP 2021 en la categoría de Música y Territorio. El objetivo es concienciar sobre las amenazas que enfrenta el patrimonio y promover el papel activo de la comunidad en la generación de discursos políticos, críticos, estéticos y artísticos a través de la música y la escucha.`,
      cat: `Aquest és el resultat d'un procés comunitari que implica els residents d'El Prat com a part del projecte guanyador de la convocatòria /UNZIP 2021 a la categoria de Música i Territori. L'objectiu és conscienciar sobre les amenaces que enfronta el patrimoni i promoure el paper actiu de la comunitat en la generació de discursos polítics, crítics, estètics i artístics a través de la música i l'escolta.`,
      ger: `Dies ist das Ergebnis eines Gemeinschaftsprozesses, an dem die Bewohner von El Prat als Teil des siegreichen Projekts des Aufrufs /UNZIP 2021 in der Kategorie Musik und Territorium beteiligt sind. Das Ziel ist es, auf die Bedrohungen für das Erbe aufmerksam zu machen und die aktive Rolle der Gemeinschaft bei der Erzeugung politischer, kritischer, ästhetischer und künstlerischer Diskurse durch Musik und Hören zu fördern.`,
    },

    image: `/img/projects/03/03.webp`,
    image1: `img/projects/03/portada.webp`,
    image2: `img/projects/03/img1.webp`,
    image3: `img/projects/03/img2.webp`,
    place: {
      en: `El Prat del Llobregat, Spain`,
      es: `El Prat del Llobregat, España`,
      cat: `El Prat del Llobregat, Espanya`,
      ger: `El Prat del Llobregat, Spanien`,
    },
    link: `https://lacapsa.org/rescatem-el-patrimoni-acustic-i-sonor-del-prat/`
  },
  {
    id: 4,
    title: {
      en: `Sound Walks`,
      es: `Caminatas Sonoras`,
      cat: `Caminades Sonores`,
      ger: `Klangwanderungen`,
    },
    description: {
      en: `Podcast: Catalan soundscape depicted in binaural & 360º AV formats by local creatives.`,
      es: `Pódcast: paisaje sonoro catalán en binaural y AV 360° presentado por las personalidades creativas que lo habitan.`,
      cat: `Pòdcast: paisatge sonor català en binaural i AV 360º presentat per les personalitats creatives que l'habiten.`,
      ger: `Podcast: Katalanische Klanglandschaft dargestellt in binauralen und 360º AV-Formaten von lokalen Kreativen.`,
    },
    longDescription1: {
      en: `Caminatas Sonoras is a binaural podcast highlighting how the Catalan soundscape influences creative personalities and cultural agents inhabiting it. 
      
      The podcast features artists, poets, musicians, and actors who generate art while interacting consciously or unconsciously with their own sonic environment.`,
      es: `Caminatas Sonoras es un pódcast binaural que pone en valor cómo el paisaje sonoro catalán influye en personalidades creativas y agentes culturales que lo habitan. 
      
      El pódcast presenta artistas, poetas, músicos y actores que generan arte y a su vez interactúan consciente o inconscientemente con su propio entorno sonoro.`,
      cat: `Caminades Sonores és un pòdcast binaural que posa en relleu com el paisatge sonor català influeix en personalitats creatives i agents culturals que l'habiten. 
      
      El pòdcast presenta artistes, poetes, músics i actors que generen art alhora que interactuen conscient o inconscientment amb el seu propi entorn sonor.`,
      ger: `Caminnatas Sonoras ist ein binauraler Podcast, der zeigt, wie die katalanische Klanglandschaft kreative Persönlichkeiten und kulturelle Akteure beeinflusst, die in ihr leben. 
      
      Der Podcast präsentiert Künstler, Dichter, Musiker und Schauspieler, die Kunst schaffen, während sie bewusst oder unbewusst mit ihrer eigenen Klangumgebung interagieren.`,
    },

    longDescription2: {
      en: `The "soundwalk" practice aims to bring awareness to our existence within the surrounding soundscape. Through listening, we establish new relationships with our environment and ourselves, opening up to various perceptions. 
      
      Soundwalking not only unveils existing connections with the acoustic environment that went unnoticed but also makes these connections conscious in our experience within the social and political acoustic space.`,
      es: `La práctica de la "caminata sonora" tiene como objetivo crear conciencia sobre nuestra existencia dentro del paisaje sonoro circundante. A través de la escucha, establecemos nuevas relaciones con nuestro entorno y nosotros mismos, abriéndonos a diversas percepciones. 
      
      La caminata sonora no solo nos revela relaciones existentes con el entorno acústico que nos pasaban desapercibidas, sino que hace estas relaciones conscientes entre nuestra experiencia y nuestro espacio acústico social y político. `,
      cat: `La pràctica de la "caminada sonora" té com a objectiu crear consciència sobre la nostra existència dins del paisatge sonor circumdant. Mitjançant l'escolta, establim noves relacions amb el nostre entorn i nosaltres mateixos, obrint-nos a diverses percepcions. 
      
      La caminada sonora no només ens revela relacions existents amb l'entorn acústic que ens passaven desapercebudes, sinó que fa aquestes relacions conscients entre la nostra experiència i el nostre espai acústic social i polític.`,
      ger: `Die Praxis des "Soundwalk" zielt darauf ab, das Bewusstsein für unsere Existenz innerhalb der umgebenden Klanglandschaft zu schärfen. Durch das Zuhören etablieren wir neue Beziehungen zu unserer Umgebung und uns selbst, öffnen uns für verschiedene Wahrnehmungen. 
      
      Soundwalking enthüllt nicht nur vorhandene Verbindungen zur akustischen Umgebung, die unbemerkt geblieben sind, sondern macht diese Verbindungen auch in unserer Erfahrung im sozialen und politischen akustischen Raum bewusst.`,
    },
    image: `/img/projects/04/04.webp`,
    image1: `img/projects/04/portada.webp`,
    image2: `img/projects/04/img1.webp`,
    image3: `img/projects/04/img2.webp`,
    place: {
      en: `Barcelona, Spain`,
      es: `Barcelona, España`,
      cat: `Barcelona, Espanya`,
      ger: `Barcelona, Spanien`,
    },
    link: `https://caminadessonores.tumblr.com/`
  },
  {
    id: 5,
    title: {
      en: `Gothic Acoustics in Contemporary Art`,
      es: `Acústica gótica en el arte contemporáneo`,
      cat: `Acústica gòtica en l\'art contemporani`,
      ger: `Gotische Akustik in der zeitgenössischen Kunst`,
    },
    description: {
      en: `A Set of Audio Tools for La Capella. Archeo-acoustics study and plugin set for artists and musicians to simulate their pieces in the space.`,
      es: `Un conjunto de herramientas de audio para La Capella. Estudio arqueoacústico y conjunto de plugins para artistas y músicos para simular sus piezas en el espacio.`,
      cat: `Un conjunt d'eines d'àudio per a La Capella. Estudi arqueoacústic i conjunt de plugins per a artistes i músics per simular les seves peces a l'espai.`,
      ger: `Ein Set von Audio-Tools für La Capella. Archäoakustische Studie und Plugin-Set für Künstler und Musiker zur Simulation ihrer Stücke im Raum.`,
    },
    longDescription1: {
      en: `The project aims to safeguard the acoustic heritage of La Capella de l'Antic Hospital in Barcelona by identifying its main acoustic characteristics. La Capella, a 15th-century Gothic chapel in Barcelona, now serves as a contemporary art venue. 
      
      This project focuses on its unique acoustics, offering tailored audio tools for artists working in this historical space.`,
      es: `El proyecto tiene como objetivo preservar el patrimonio acústico de La Capella de l'Antic Hospital de Barcelona identificando sus principales características acústicas. La Capella, una capilla gótica del siglo XV en Barcelona, ahora funciona como un espacio de arte contemporáneo. 
      
      Este proyecto se centra en sus acústicas únicas, ofreciendo herramientas de audio personalizadas para artistas que trabajan en este espacio histórico.`,
      cat: `El projecte té com a objectiu preservar el patrimoni acústic de La Capella de l'Antic Hospital de Barcelona identificant les seves principals característiques acústiques. La Capella, una capella gòtica del segle XV a Barcelona, ara funciona com a espai d'art contemporani. 
      
      Aquest projecte es centra en les seves acústiques úniques, oferint eines d'àudio personalitzades per als artistes que treballen en aquest espai històric.`,
      ger: `Das Projekt zielt darauf ab, das akustische Erbe von La Capella de l'Antic Hospital in Barcelona zu schützen, indem seine wichtigsten akustischen Merkmale identifiziert werden. La Capella, eine gotische Kapelle aus dem 15. Jahrhundert in Barcelona, fungiert heute als zeitgenössischer Kunstort. 
      
      Dieses Projekt konzentriert sich auf seine einzigartige Akustik und bietet maßgeschneiderte Audio-Tools für Künstler, die in diesem historischen Raum arbeiten.`
    },
    longDescription2: {
      en: `To safeguard La Capella's acoustic legacy, the study includes in-situ measurements, software simulations, an impulse response library, and a real-time tool. These tools not only enhance our understanding of archeo-acoustics but also empower artists to simulate their work within the space using specialized plugins.`,
      es: `Para salvaguardar el legado acústico de La Capella, el estudio incluye medidas in situ, simulaciones de software, una biblioteca de respuestas al impulso y una herramienta en tiempo real. Estas herramientas no solo mejoran nuestra comprensión de la arqueoacústica, sino que también permiten a los artistas simular su trabajo dentro del espacio utilizando plugins especializados.`,
      cat: `Per salvaguardar el llegat acústic de La Capella, l'estudi inclou mesures in situ, simulacions de programari, una biblioteca de respostes a l'impuls i una eina en temps real. Aquestes eines no només milloren la nostra comprensió de l'arqueoacústica, sinó que també permeten als artistes simular el seu treball dins de l'espai utilitzant plugins especialitzats.`,
      ger: `Um das akustische Erbe von La Capella zu schützen, umfasst die Studie In-situ-Messungen, Software-Simulationen, eine Impulsantwort-Bibliothek und ein Echtzeit-Tool. Diese Werkzeuge verbessern nicht nur unser Verständnis der Archäoakustik, sondern ermöglichen es Künstlern auch, ihre Arbeit innerhalb des Raums mithilfe spezialisierter Plugins zu simulieren.`
    },
    image: `/img/projects/05/05.webp`,
    image1: `img/projects/05/portada.webp`,
    image2: `img/projects/05/img1.webp`,
    image3: `img/projects/05/img2.webp`,
    place: {
      en: `Spain`,
      es: `España`,
      cat: `Espanya`,
      ger: `Spanien`,
    },
    link: `https://acousticheritagecollective.org/lacapella/index.html`
  },
  {
    id: 6,
    title: {
      en: `The Abbot\'s cliff sound mirror`,
      es: `El sound mirror de Abbot Cliff`,
      cat: `El sound mirror d\'Abbot Cliff`,
      ger: `Der Schallspiegel von Abbot\'s Cliff`,
    },
    description: {
      en: `Academic article: Strategies on its safeguard addressing past usage, present conditions and future applications.`,
      es: `Artículo académico: Estrategias para su salvaguarda abordando su uso pasado, condiciones presentes y aplicaciones futuras.`,
      cat: `Article acadèmic: Estratègies per a la seva salvaguarda abordant l'ús passat, les condicions actuals i les aplicacions futures.`,
      ger: `Akademischer Artikel: Strategien zur Sicherung unter Berücksichtigung der vergangenen Nutzung, gegenwärtigen Bedingungen und zukünftigen Anwendungen.`,
    },

    longDescription1: {
      en: `This article presents a methodology for safeguarding the Acoustic Heritage of the Abbot's Cliff Sound Mirror in Kent, UK. This concrete structure, built in 1928 as part of an early warning air defense system, is a well-preserved example of pre-Radar technology. In addition to its historical value, the acoustic space of the mirror is also a cultural asset with deep connections to the local community. 
      
      The mirror's monumental shape, its location, the weather conditions, along with its wartime-related associations, have given rise to a range of artistic and cultural expressions related to silence, solitude, fear, darkness, and deep listening experiences.`,
      es: `Este artículo presenta una metodología para la salvaguarda del Patrimonio Acústico del "Sound Mirror" de Abbot's Cliff en Kent, Reino Unido. Esta estructura de hormigón, construida el 1928 como parte de un sistema de defensa aérea de alerta temprana, es un ejemplo muy conservado de tecnología previa al radar. Además de su valor histórico, el espacio acústico del espejo es también un activo cultural con conexiones profundas con la comunidad local. 
      
      La forma monumental del "Sound Mirror", su ubicación, las condiciones meteorológicas, junto con sus asociaciones relacionadas con la guerra, han dado lugar a una gama de expresiones artísticas y culturales relacionadas con el silencio, la soledad, el miedo, la oscuridad y experiencias de escucha profunda.`,
      cat: `Aquest article presenta una metodologia per a la salvaguarda del Patrimoni Acústic del "Sound Mirror" d'Abbot's Cliff a Kent, Regne Unit. Aquesta estructura de formigó, construïda el 1928 com a part d'un sistema de defensa aèria d'alerta primerenca, és un exemple ben conservat de tecnologia prèvia al radar. A més del seu valor històric, l'espai acústic del mirall és també un actiu cultural amb connexions profundes amb la comunitat local. 
      
      La forma monumental del "Sound Mirror", la seva ubicació, les condicions meteorològiques, juntament amb les seves associacions relacionades amb la guerra, han donat lloc a una gamma d'expressions artístiques i culturals relacionades amb el silenci, la solitud, la por, la foscor i experiències d'escolta profunda.`,
      ger: `Dieser Artikel stellt eine Methodik zur Sicherung des akustischen Erbes des Abbot's Cliff Sound Mirror in Kent, Großbritannien, vor. Diese Betonstruktur, die 1928 im Rahmen eines Frühwarn-Luftverteidigungssystems erbaut wurde, ist ein gut erhaltenes Beispiel für die Technologie vor dem Radar. Neben ihrem historischen Wert ist der akustische Raum des Spiegels auch ein kultureller Schatz mit tiefen Verbindungen zur lokalen Gemeinschaft. 
      
      Die monumentale Form des Spiegels, seine Lage, die Wetterbedingungen sowie seine kriegsbezogenen Verbindungen haben eine Vielzahl von künstlerischen und kulturellen Ausdrucksformen hervorgebracht, die mit Stille, Einsamkeit, Angst, Dunkelheit und tiefen Hörerlebnissen verbunden sind.`,
    },
    longDescription2: {
      en: `These manifestations are extremely relevant for this research as they exemplify the community's relationship with the mirror. To accurately safeguard this acoustic space, the article's authors argue that more than just impulse responses must be considered, with particular attention to simulating the original listening system and source characteristics. 
      
      In October 2022, The Acoustic Heritage Collective visited the Abbot's Cliff Mirror to conduct a pre-assessment and develop strategies for further acoustic heritage safeguarding through 3D data collection and a design for a virtual application for its public dissemination.`,
      es: `Estas manifestaciones son extremadamente relevantes para la investigación, puesto que ejemplifican la relación de la comunidad con el "Sound Mirror". Para salvaguardar con precisión este espacio acústico, los autores del artículo argumentan que hay que tener en cuenta más que simplemente respuestas impulsivas, prestando especial atención a la simulación del sistema de escucha original y las características de la fuente. 
      
      En octubre de 2022, el Acoustic Heritage Collective visitó el "Sound Mirror" de Abbot's Cliff para realizar una preevaluación y desarrollar estrategias para la salvaguardia adicional del patrimonio acústico mediante la recopilación de datos en 3D y un diseño de una aplicación virtual para su difusión pública.`,
      cat: `Aquestes manifestacions són extremadament rellevants per a la investigació, ja que exemplifiquen la relació de la comunitat amb el "Sound Mirror". Per a salvaguardar amb precisió aquest espai acústic, els autors de l'article argumenten que cal tenir en compte més que simplement respostes impulsives, prestant especial atenció a la simulació del sistema d'escolta original i les característiques de la font. 
      
      L'octubre de 2022, l'Acoustic Heritage Collective va visitar el "Sound Mirror" d'Abbot's Cliff per realitzar una preavaluació i desenvolupar estratègies per a la salvaguarda addicional del patrimoni acústic mitjançant la recopilació de dades en 3D i un disseny d'una aplicació virtual per a la seva difusió pública.`,
      ger: `Diese Manifestationen sind für diese Forschung äußerst relevant, da sie die Beziehung der Gemeinschaft zum Spiegel veranschaulichen. Um diesen akustischen Raum genau zu schützen, argumentieren die Autoren des Artikels, dass mehr als nur Impulsantworten berücksichtigt werden müssen, mit besonderem Augenmerk auf die Simulation des originalen Hörsystems und der Merkmale der Quelle. 
      
      Im Oktober 2022 besuchte das Acoustic Heritage Collective den Abbot's Cliff Mirror, um eine Vorabprüfung durchzuführen und Strategien für die weitere Sicherung des akustischen Erbes durch 3D-Datensammlung und ein Design für eine virtuelle Anwendung zur öffentlichen Verbreitung zu entwickeln.`,
    },
    image: `/img/projects/06/06.webp`,
    image1: `img/projects/06/portada.webp`,
    image2: `img/projects/06/img1.webp`,
    image3: `img/projects/06/img2.webp`,

    place: {
      en: `Kent, UK`,
      es: `Kent, Reino Unido`,
      cat: `Kent, Regne Unit`,
      ger: `Kent, Vereinigtes Königreich`,
    },
    link: `https://acousticheritagecollective.org/soundmirror/index.html`
  },
  {
    id: 7,
    title: {
      en: `Safeguarding Acoustic Heritage of 
      Thingvellir National Park`,
      es: `Salvaguardia del patrimonio acústico del 
      Parque Nacional de Thingvellir`,
      cat: `Salvaguarda del patrimoni acústic del 
      Parc Nacional de Thingvellir`,
      ger: `Sicherung des akustischen Erbes des 
      Thingvellir-Nationalparks`,
    },
    description: {
      en: `Academic article: Research of Acoustic heritage of Lögberg in Thingvellir National Park: impulse response library and an approach to future studies`,
      es: `Investigación del patrimonio acústico de Lögberg en el Parque Nacional de Thingvellir: biblioteca de respuestas al impulso y enfoque para futuros estudios.`,
      cat: `Investigació del patrimoni acústic de Lögberg al Parc Nacional de Thingvellir: biblioteca de respostes a impulsos i enfocament per a estudis futurs.`,
      ger: `Forschung zum akustischen Erbe von Lögberg im Thingvellir-Nationalpark: Impulsantwortbibliothek und Ansatz für zukünftige Studien.`,
    },
    longDescription1: {
      en: `Thingvellir is an Icelandic national park located about 50 kilometers from Reykjavik, and its surface area is 237 km2. Due to its cultural, historical, geological characteristics, and its distinctive biodiversity, it represents one of the most significant places for Icelandic society. These characteristics led it to be proposed and declared a World Heritage Site in 2004. 
      
      One of the most relevant events that occurred in Thingvellir is the establishment in 930 of the first Icelandic national parliament called “Althing” (Alþingi), becoming one of humanity’s first documented parliamentary institutions. 
      
      According to most studies, a significant area of this assembly was located in a sector of the park called Lögberg (Law Rock), probably due to the layout of the natural amphitheater that this area has. In this place, the so-called Law-Speaker recited the laws, speeches were given on important topics, and legal disputes were resolved.`,
      es: `Thingvellir es un parque nacional islandés ubicado a unos 50 kilómetros de Reykjavik, y su superficie es de 237 km2. Debido a sus características culturales, históricas, geológicas y su distintiva biodiversidad, representa uno de los lugares más significativos para la sociedad islandesa. Estas características llevaron a que se declarara Patrimonio de la Humanidad en 2004. 
      
      Uno de los eventos más relevantes que ocurrió en Thingvellir es el establecimiento en 930 del primer parlamento nacional islandés llamado “Althing” (Alþingi), convirtiéndose en una de las primeras instituciones parlamentarias documentadas de la humanidad. Según la mayoría de los estudios, una parte significativa de esta asamblea estaba ubicada en un sector del parque llamado Lögberg (Roca de la Ley), probablemente debido al diseño del anfiteatro natural que tiene esta área. En este lugar, el llamado Portavoz de la Ley recitaba las leyes, se pronunciaban discursos sobre temas importantes y se resolvían disputas legales.`,
      cat: `Thingvellir és un parc nacional islandès situat a uns 50 quilòmetres de Reykjavik, i la seva superfície és de 237 km2. Degut a les seves característiques culturals, històriques, geològiques i la seva distintiva biodiversitat, representa un dels llocs més significatius per a la societat islandesa. Aquestes característiques van fer que es declarés Patrimoni de la Humanitat el 2004. 
      
      Un dels esdeveniments més rellevants que va ocórrer a Thingvellir és l'establiment el 930 del primer parlament nacional islandès anomenat “Althing” (Alþingi), convertint-se en una de les primeres institucions parlamentàries documentades de la humanitat. Segons la majoria d'estudis, una part significativa d'aquesta assemblea es trobava en un sector del parc anomenat Lögberg (Roca de la Llei), probablement a causa del disseny de l'amfiteatre natural que té aquesta àrea. En aquest lloc, l'anomenat Portaveu de la Llei recitava les lleis, es feien discursos sobre temes importants i es resolvien disputes legals.`,
      ger: `Thingvellir ist ein isländischer Nationalpark, etwa 50 Kilometer von Reykjavik entfernt, mit einer Fläche von 237 km2. Aufgrund seiner kulturellen, historischen, geologischen Merkmale und seiner einzigartigen Biodiversität ist er einer der bedeutendsten Orte für die isländische Gesellschaft. Diese Merkmale führten dazu, dass er 2004 als Weltkulturerbe vorgeschlagen und erklärt wurde. Eine der relevantesten Veranstaltungen, die in Thingvellir stattfand, war die Gründung des ersten isländischen Nationalparlaments namens „Althing“ (Alþingi) im Jahr 930, das zu einer der ersten dokumentierten parlamentarischen Institutionen der Menschheit wurde. 
      
      Laut den meisten Studien befand sich ein bedeutender Teil dieser Versammlung in einem Bereich des Parks namens Lögberg (Law Rock), wahrscheinlich aufgrund des Layouts des natürlichen Amphitheaters in diesem Bereich. An diesem Ort rezitierte der sogenannte Gesetzessprecher die Gesetze, es wurden Reden zu wichtigen Themen gehalten und Rechtsstreitigkeiten wurden beigelegt.`,
    },
    longDescription2: {
      en: `Some studies on Lögberg suggest the influence that the acoustics of this space could have on these meetings, however, they do not delve much into this aspect. This article initially raises the question of whether through an in-situ impulse response measurement, it is possible to determine and quantify this influence. 
      
      Subsequently, this investigation details the planning and field execution of these measurements. The results obtained, contained in an impulse response library, not only propose new research lines for future studies in the field of archaeoacoustics but also constitute an important safeguard of Iceland’s acoustic heritage.`,
      es: `Algunos estudios sobre Lögberg sugieren la influencia que la acústica de este espacio podría tener en estas reuniones, sin embargo, no profundizan mucho en este aspecto. Este artículo plantea inicialmente la pregunta de si a través de una medición de respuesta al impulso in situ es posible determinar y cuantificar esta influencia. 
      
      Posteriormente, esta investigación detalla la planificación y ejecución de estas mediciones. Los resultados obtenidos, contenidos en una biblioteca de respuestas al impulso, no solo proponen nuevas líneas de investigación para futuros estudios en el campo de la arqueoacústica, sino que también constituyen una salvaguarda importante del patrimonio acústico de Islandia.`,
      cat: `Alguns estudis sobre Lögberg suggereixen la influència que l'acústica d'aquest espai podria tenir en aquestes reunions, no obstant això, no aprofundeixen gaire en aquest aspecte. Aquest article planteja inicialment la pregunta de si mitjançant una mesura de resposta a l'impuls in situ és possible determinar i quantificar aquesta influència. 
      
      Posteriorment, aquesta investigació detalla la planificació i execució d'aquestes mesures. Els resultats obtinguts, continguts en una biblioteca de respostes a l'impuls, no només proposen noves línies de recerca per a futurs estudis en el camp de l'arqueoacústica, sinó que també constitueixen una salvaguarda important del patrimoni acústic.`,
      ger: `Einige Studien zu Lögberg deuten darauf hin, welche Auswirkungen die Akustik dieses Raumes auf diese Treffen haben könnte, gehen jedoch nicht sehr tief auf dieses Thema ein. Dieser Artikel wirft zunächst die Frage auf, ob es durch eine in-situ-Impulsantwortmessung möglich ist, diese Einflüsse zu bestimmen und zu quantifizieren. 
      
      Anschließend wird diese Untersuchung die Planung und Durchführung dieser Messungen im Feld detaillieren. Die erhaltenen Ergebnisse, die in einer Impulsantwort-Bibliothek enthalten sind, schlagen nicht nur neue Forschungslinien für zukünftige Studien im Bereich der Archäoakustik vor, sondern stellen auch eine wichtige Sicherung des akustischen Erbes Islands dar.`,
    },
    image: `/img/projects/07/07.webp`,
    image1: `img/projects/07/portada.webp`,
    image2: `img/projects/07/img1.webp`,
    image3: `img/projects/07/img2.webp`,
    place: {
      en: `Bláskógabyggð, Iceland`,
      es: `Bláskógabyggð, Islandia`,
      cat: `Bláskógabyggð, Islàndia`,
      ger: `Bláskógabyggð, Island`,
    },
    link: `https://thingvelliracoustics.tumblr.com`
  },
  {
    id: 8,
    title: {
      en: `Estampes del silenci, del vertigen, del 
      membre fantasma, de l\`èxtasi, de tu`,
      es: `Estampas del silencio, del vértigo, del 
      miembro fantasma, del éxtasis, de ti`,
      cat: `Estampes del silenci, del vertigen, del 
      membre fantasma, de l\`èxtasi, de tu`,
      ger: `Estampes del silenci, del vertigen, del 
      membre fantasma, de l\`èxtasi, de tu`
    },
    description: {
      en: `VR immersive poetry piece located in a duomo. Investigating the potential of virtual acoustics in poetry.`,
      es: `Pieza de poesía inmersiva en realidad virtual situada en un duomo. Investigando el potencial de la acústica virtual en la poesía.`,
      cat: `Peça de poesia immersiva en realitat virtual situada en un duomo. Investigant el potencial de l\`acústica virtual en la poesia.`,
      ger: `VR-immersives Gedichtsstück in einem Duomo. Untersuchung des Potenzials virtueller Akustik in der Poesie.`,
    },
    longDescription1: {
      en: `This immersive poetry piece is composed of a series of tableaus where Ginebra Raventós unfolds her poetic universe. The elements that make up the work intermingle in an indissociable whole where abysses intersect, and the words, silence, and voice are explored as that ethereal extremity that allows us to touch ourselves. 
      
      The two pillars that support the journey are concepts from neuroscience and psychoacoustics that establish the foundations for generating metaphors that lead us to question how we create reality, how we connect with the world, with others, and with ourselves.`,
      es: `Esta pieza de poesía inmersiva se compone de una serie de estampas donde Ginebra Raventós despliega su universo poético. Los elementos que conforman la obra se entremezclan en un todo indispensable donde se cruzan abismos y se explora la palabra, el silencio y la voz como esa extremidad etérea que nos permite tocarnos. Los dos pilares que sostienen el viaje son conceptos de la neurociencia y la psicoacústica que establecen las bases para generar metáforas que nos llevan a cuestionarnos cómo creamos la realidad, cómo nos conectamos con el mundo, con los demás y con nosotros mismos.`,
      cat: `Aquesta peça de poesia immersiva es compon d'una sèrie d'estampes on Ginebra Raventós ens desplega el seu univers poètic. Els elements que conformen l'obra s'entremesclen en un tot indissociable on es travessen abismes i s'explora la paraula, el silenci i la veu com aquella extremitat etèria que ens permet tocar-nos. 
      
      Els dos pilars que sostenen el viatge són conceptes de la neurociència i la psicoacústica que estableixen les bases per generar metàfores que ens porten a preguntar-nos sobre com creem la realitat, com ens vinculem amb el món, amb els altres i amb nosaltres mateixes.`,
      ger: `Diese immersive Poesie besteht aus einer Reihe von Tableaus, in denen Ginebra Raventós ihr poetisches Universum entfaltet. Die Elemente, die das Werk ausmachen, vermischen sich zu einem untrennbaren Ganzen, in dem Abgründe aufeinandertreffen und Worte, Stille und Stimme als jene ätherische Extremität erforscht werden, die es uns ermöglicht, uns zu berühren. 
      
      Die beiden Säulen, die die Reise unterstützen, sind Konzepte aus der Neurowissenschaft und Psychoakustik, die die Grundlagen für die Erzeugung von Metaphern schaffen, die uns dazu bringen, uns zu fragen, wie wir die Realität schaffen, wie wir uns mit der Welt, mit anderen und mit uns selbst verbinden.`
    },
    longDescription2: {
      en: `The poet combines transmedia language using spoken or projected words, sound, acoustic and interactive visuals rescuing poetry from printed text and offering the viewer an immersive experience where each tableau is a moving universe to immerse themselves in.`,
      es: `La poeta combina lenguaje transmedia haciendo uso de la palabra dicha o proyectada, registros sonoros, acústicos y visuales interactivos rescatando la poesía del texto impreso y brindando al espectador una experiencia inmersiva donde cada estampa es un universo en movimiento en el que sumergirse.`,
      cat: `La poeta combina llenguatge transmèdia fent ús de la paraula dita o projectada, registres sonors, acústics i visuals interactives rescatant la poesia del text imprès i brindant a l'espectador una experiència immersiva on cada estampa és un univers en moviment on submergir-se.`,
      ger: `Die Dichterin kombiniert transmediale Sprache, indem sie gesprochene oder projizierte Worte, Klang, akustische und interaktive visuelle Elemente verwendet, um Poesie aus gedrucktem Text zu retten und dem Betrachter ein immersives Erlebnis zu bieten, bei dem jedes Tableau ein sich bewegendes Universum ist, in das man eintauchen kann.`
    },
    image: `/img/projects/08/08.webp`,
    image1: `img/projects/08/portada.webp`,
    image2: `img/projects/08/img1.webp`,
    image3: `img/projects/08/img2.webp`,
    place: {
      en: `Barcelona, Spain`,
      es: `Barcelona, España`,
      cat: `Barcelona, Espanya`,
      ger: `Barcelona, Spanien`,
    },
    link: `https://patrimoniacustic.cat/ginebraraventos/ginebraraventos.html`
  },
  {
    id: 9,
    title: {
      en: `Manifest, (Work in Progress)`,
      es: `Manifest, (Work in Progress)`,
      cat: `Manifest, (Work in Progress)`,
      ger: `Manifest, (Work in Progress)`,
    },
    description: {
      en: `Residency programme – Artistic Journey, re-imagining Europe’s collective memory of the transatlantic trade of enslaved people and its existing cultural traces.`,
      es: `Programa de residencia - Viaje artístico, reimaginando la memoria colectiva de Europa sobre el comercio transatlántico de personas esclavizadas y sus rastros culturales existentes.`,
      cat: `Programa de residència - Viatge artístic, reimaginant la memòria col·lectiva d\`Europa sobre el comerç transatlàntic de persones esclavitzades i els seus rastres culturals existents.`,
      ger: `Residenzprogramm - Künstlerische Reise, Neugestaltung der kollektiven Erinnerung Europas an den transatlantischen Sklavenhandel und seine vorhandenen kulturellen Spuren.`,
    },
    longDescription1: {
      en: `This artistic and educational project seeks to reshape Europe's collective memory of the transatlantic trade of enslaved people. Through an Artistic Journey in Budapest/Zsennye, Lisbon, and Copenhagen, selected artists will explore the trade's European connections, creating art that reimagines collective memory and leverages modern technologies.`,
      es: `Este proyecto artístico y educativo busca repensar la memoria colectiva de Europa sobre el comercio transatlántico de personas esclavizadas. A través de un Viaje Artístico en Budapest/Zsennye, Lisboa y Copenhague, los artistas seleccionados explorarán las conexiones europeas del comercio, creando arte que reimagina la memoria colectiva y aprovecha las tecnologías modernas.`,
      cat: `Aquest projecte artístic i educatiu busca repensar la memòria col·lectiva d'Europa sobre el comerç transatlàntic de persones esclavitzades. A través d'un viatge artístic a Budapest/Zsennye, Lisboa i Copenhaguen, els artistes seleccionats exploraran les connexions europees del comerç, creant art que reimagina la memòria col·lectiva i aprofita les tecnologies modernes.`,
      ger: `Dieses künstlerische und pädagogische Projekt zielt darauf ab, das kollektive Gedächtnis Europas an den transatlantischen Sklavenhandel neu zu gestalten. Durch eine künstlerische Reise in Budapest/Zsennye, Lissabon und Kopenhagen werden ausgewählte Künstler die europäischen Verbindungen des Handels erforschen, Kunst schaffen, die das kollektive Gedächtnis neu erfindet, und moderne Technologien nutzen.`,
    },
    longDescription2: {
      en: `Parallel research and community engagement will document material and cultural heritage linked to the trade, culminating in a Resources Publication. The project's finale in Nantes will include an Award Ceremony, showcasing artists' work and stimulating vital discussions within the societal discourse about this significant historical event.`,
      es: `La investigación paralela y la participación comunitaria documentarán el patrimonio material y cultural vinculado al comercio, culminando en una Publicación de Recursos. El final del proyecto en Nantes incluirá una Ceremonia de Premiación, exhibiendo el trabajo de los artistas y estimulando discusiones vitales dentro del discurso social sobre este evento histórico significativo.`,
      cat: `La recerca paral·lela i la participació comunitària documentaran el patrimoni material i cultural vinculat al comerç, culminant en una Publicació de Recursos. La finalització del projecte a Nantes inclourà una Cerimònia d'Entrega de Premis, mostrant el treball dels artistes i estimulant discussions vitals dins del discurs societal sobre aquest esdeveniment històric significatiu.`,
      ger: `Parallele Forschung und Beteiligung der Gemeinschaft werden das mit dem Handel verbundene materielle und kulturelle Erbe dokumentieren und in einer Ressourcenveröffentlichung gipfeln. Das Finale des Projekts in Nantes wird eine Preisverleihung beinhalten, bei der die Arbeit der Künstler präsentiert und wichtige Diskussionen innerhalb des gesellschaftlichen Diskurses über dieses bedeutsame historische Ereignis angeregt werden.`,
    },
    image: "/img/projects/09/09.webp",
    image1: "img/projects/09/portada.webp",
    image2: "img/projects/09/img1.webp",
    image3: "img/projects/09/img2.webp",
    place: {
      en: `Lisbon, Budapest, Nantes`,
      es: `Lisboa, Budapest, Nantes`,
      cat: `Lisboa, Budapest, Nantes`,
      ger: `Lissabon, Budapest, Nantes`,
    },
    link: ""
  },
  {
    id: 10,
    title: {
      en: `"Journey to the center of the sound" 
      in the MONOM 4D sound system`,
      es: `"Journey to the center of the sound" 
      en el sistema de sonido MONOM 4D`,
      cat: `"Journey to the center of the sound" 
      en el sistema de so MONOM 4D`,
      ger: `"Journey to the center of the sound" 
      im MONOM 4D Sound System`,
    },
    description: {
      en: `Presentation at IRCAM Forum Workshops 2023. Video game technology as an AV art and culture dissemination strategy.`,
      es: `Presentación en IRCAM Forum Workshops 2023. La tecnología de videojuegos como arte AV y estrategia de difusión artística y cultural.`,
      cat: `Presentació en l'IRCAM Forum Workshops 2023. La tecnologia dels videojocs com a art AV i estratègia de difusió artística i cultural.`,
      ger: `Präsentation bei den IRCAM Forum Workshops 2023. Videospieltechnologie als AV-Kunst - und Kulturdistributionsstrategie.`,
    },
    longDescription1: {
      en: `During the last two years, our collective has been working on an immersive AV poetic narrative called "Journey to the Center of the Sound." One of our main goals for this project was to create a piece that could be adapted to different formats, from physical to digital spaces. The result has been a creative process that has involved diverse spatial research fields, strategies and outputs.`,
      es: `Durante los últimos dos años, nuestro colectivo ha estado trabajando en una narrativa poética AV inmersiva llamada "Journey tono the Center of the Sound."  Uno de nuestros principales objetivos para este proyecto fue crear una pieza que se pudiera adaptar a diferentes formatos, desde espacios físicos hasta espacios digitales. El resultado ha sido un proceso creativo que ha implicado varios campos de investigación espacial, estrategias y resultados.`,
      cat: `Durant els últims dos anys, el nostre col·lectiu ha estat treballant en una narrativa poètica AV immersiva anomenada "Journey to the Center of the Sound." Un dels nostres principals objectius per a aquest projecte va ser crear una peça que es pogués adaptar a diferents formats, des d'espais físics fins a espais digitals. El resultat ha estat un procés creatiu que ha implicat diversos camps de recerca espacial, estratègies i resultats.`,
      ger: `In den letzten zwei Jahren hat unser Kollektiv an einer immersiven AV-poetischen Erzählung mit dem Titel "Reise zum Zentrum des Klangs" gearbeitet. Eines unserer Hauptziele für dieses Projekt war es, ein Werk zu schaffen, das sich an verschiedene Formate anpassen lässt, von physischen bis zu digitalen Räumen. Das Ergebnis war ein kreativer Prozess, der verschiedene räumliche Forschungsfelder, Strategien und Ergebnisse einbezogen hat.`,
    },
    longDescription2: {
      en: `Our last approach was to design a methodology to translate this artwork to a simulated venue using video game engine technology and to be experienced in VR headset. As a case of study we proposed to revisit our presentation in MONOM studios in 2022 (48 speakers set, 4DSound Engine) by means of applied room acoustics theory, virtualization and auralization strategies.`,
      es: `Nuestra última aproximación fue diseñar una metodología para traducir esta obra de arte en un lugar simulado utilizando la tecnología de los motores de videojuegos y experimentarla con auriculares de realidad virtual. Como caso de estudio, propusimos revisitar nuestra presentación en los estudios MONOM el 2022 (conjunto de 48 altavoces, motor 4DSound) mediante la aplicación de “room acoustics”, estrategias de virtualización y auralització.`,
      cat: `La nostra darrera aproximació va ser dissenyar una metodologia per traduir aquesta obra d'art a un lloc simulat utilitzant la tecnologia dels motors de videojocs i experimentar-la amb auriculars de realitat virtual. Com a cas d'estudi, vam proposar revisitar la nostra presentació als estudis MONOM el 2022 (conjunt de 48 altaveus, motor 4DSound) mitjançant l'aplicació de “room acoustics”, estratègies de virtualització i auralització.`,
      ger: `Unser letzter Ansatz bestand darin, eine Methodik zu entwerfen, um dieses Kunstwerk in einen simulierten Veranstaltungsort mit Hilfe von Videospiel-Engine-Technologie zu übertragen und mit VR-Headset zu erleben. Als Fallstudie schlugen wir vor, unsere Präsentation in den MONOM-Studios im Jahr 2022 (48 Lautsprecher-Setup, 4DSound Engine) mithilfe angewandter Raumakustiktheorie, Virtualisierungs- und Auralisierungsstrategien zu überarbeiten.`,
    },
    image: `/img/projects/10/10.webp`,
    image1: `img/projects/10/portada.webp`,
    image2: `_bc_OahG9bE`,
    image3: `img/projects/10/img2.webp`,
    place: {
      en: `Paris, France`,
      es: `París, Francia`,
      cat: `París, França`,
      ger: `Paris, Frankreich`,
    },
    link: `https://www.acousticheritagecollective.org/journeyvratmonom/index.html`
  },
  {
    id: 11,
    title: {
      en: `Journey to the center of the sound`,
      es: `Journey to the center of the sound`,
      cat: `Journey to the center of the sound`,
      ger: `Journey to the center of the sound`,
    },
    description: {
      en: `Immersive AV poetic narrative inspired by the Jules Verne novel "Voyage au centre de la Terre."`,
      es: `Narrativa poética AV inmersiva inspirada en la novela de Julio Verne "Voyage au centre de la Terre.""`,
      cat: `Narrativa poètica AV immersiva inspirada en la novel·la de Jules Verne "Voyage au centre de la Terre."`,
      ger: `Immersive AV poetische Erzählung, inspiriert von Jules Vernes Roman "Voyage au centre de la Terre.""`,
    },
    longDescription1: {
      en: `Inspired by the Jules Verne novel “Voyage au centre de la Terre,” four friends set out to explore Snaefellsjökul Volcano in Iceland. However, as they journeyed through the country recording sounds, they found themselves having a series of profound and transformative experiences that exceeded their original expectations.`,
      es: `Inspirados en la novela de Julio Verne “'Journey to the Center of the Sound'”, cuatro amigos deciden explorar el volcán Snaefellsjökul en Islandia. A medida que viajaban por el país grabando sonidos, se encontraron con una serie de experiencias profundas y transformadoras que superaron sus expectativas originales.`,
      cat: `Inspirats en la novel·la de Jules Verne “'Journey tono the Center of the Sound'”, quatre amics decideixen explorar el volcà Snaefellsjökul a Islàndia. A mesura que viatjaven pel país enregistrant sons, es van trobar amb una sèrie d'experiències profundes i transformadores que van superar les seves expectatives originals.`,
      ger: `Inspiriert von dem Roman von Jules Verne “Reise zum Mittelpunkt der Erde” machen sich vier Freunde auf, den Snaefellsjökul Vulkan in Island zu erkunden. Doch während sie durch das Land reisten und Klänge aufzeichneten, erlebten sie eine Reihe von tiefgreifenden und transformierenden Erfahrungen, die ihre ursprünglichen Erwartungen übertrafen.`
    },
    longDescription2: {
      en: `The result of their trip is a 48-minute immersive audio-visual narrative titled “Journey to the Center of the Sound,” which not only guides the audience through diverse Icelandic landscapes but also invites them to embark on their own sensory journey.`,
      es: `El resultado de su viaje es una narrativa audiovisual inmersiva de 48 minutos titulada “Journey to the Center of the Sound”, que no solo guía la audiencia a través de varios paisajes islandeses, sino que también los invita a emprender su propio viaje sensorial.`,
      cat: `El resultat del seu viatge és una narrativa audiovisual immersiva de 48 minuts titulada “Journey tono the Center of the Sound”, que no només guia l'audiència a través de diversos paisatges islandesos, sinó que també els convida a emprendre el seu propi viatge sensorial.`,
      ger: `Das Ergebnis ihrer Reise ist eine 48-minütige immersive audiovisuelle Erzählung mit dem Titel “Reise zum Zentrum des Klangs”, die nicht nur das Publikum durch verschiedene isländische Landschaften führt, sondern sie auch dazu einlädt, ihre eigene sinnliche Reise anzutreten.`
    },
    image: `/img/projects/11/11.webp`,
    image1: `img/projects/11/portada.webp`,
    image2: `LlCK-rMHbOs`,
    image3: `img/projects/11/img2.webp`,
    place: {
      en: `Iceland`,
      es: `Islandia`,
      cat: `Islàndia`,
      ger: `Island`,
    },
    link: `https://patrimoniacustic.cat/journey/home.html`
  },
  {
    id: 12,
    title: {
      en: `Monom residency 
      Journey to the center of the sound`,
      es: `Residencia en Monom 
      Journey to the center of the sound`,
      cat: `Residència a Monom 
      Journey to the center of the sound`,
      ger: `Monom-Residenz 
      Journey to the center of the sound`,
    },
    description: {
      en: `Exploring the expressive possibilities of spatial sound as an artistic medium. (Original 4DSOUND system)`,
      es: `Explorando las posibilidades expresivas del sonido espacial como medio artístico. (Sistema 4DSOUND original)`,
      cat: `Explorant les possibilitats expressives del so espacial com a mitjà artístic. (Sistema 4DSOUND original)`,
      ger: `Erkundung der expressiven Möglichkeiten von räumlichem Klang als künstlerisches Medium. (Originell 4DSOUND system)`,
    },
    longDescription1: {
      en: `Over the past two years, our collective has been working on an immersive AV poetic narrative called 'Journey to the Center of the Sound.' One of our main goals for this project was to create a piece that could adapt to different formats, from physical spaces to digital spaces. 
      
      The result has been a creative process that has involved various fields of spatial research, strategies, and outcomes.`,
      es: `Durante los últimos dos años, nuestro colectivo ha estado trabajando en una narrativa poética AV inmersiva llamada 'Journey to the Center of the Sound'. Uno de nuestros principales objetivos para este proyecto fue crear una pieza que se pudiera adaptar a diferentes formatos, desde espacios físicos hasta espacios digitales. 
      
      El resultado ha sido un proceso creativo que ha implicado varios campos de investigación espacial, estrategias y resultados.`,
      cat: `Durant els últims dos anys, el nostre col·lectiu ha estat treballant en una narrativa poètica AV immersiva anomenada 'Journey to the Center of the Sound'. Un dels nostres principals objectius per a aquest projecte va ser crear una peça que es pogués adaptar a diferents formats, des d'espais físics fins a espais digitals. El resultat ha estat un procés creatiu que ha implicat diversos camps de recerca espacial, estratègies i resultats.`,
      ger: `Während der letzten zwei Jahre hat unser Kollektiv an einer immersiven AV-Poesienarrative namens 'Journey to the Center of the Sound' gearbeitet. Eines unserer Hauptziele für dieses Projekt war es, ein Werk zu schaffen, das sich an verschiedene Formate anpassen lässt, von physischen Räumen bis zu digitalen Räumen. 
      
      Das Ergebnis war ein kreativer Prozess, der verschiedene Bereiche der Raumforschung, Strategien und Ergebnisse umfasste.`
    },
    longDescription2: {
      en: `We conducted a one-week residency to create a first approach to the spatialization of the piece at the MONOM cultural center and sound laboratory, located in Funkhaus Berlin on September 12, 2022. The heart of MONOM is the 4DSOUND sound spatialization system, composed of 48 omnidirectional speakers suspended throughout the venue, extending to 9 powerful subwoofers submerged under an acoustically transparent floor. 
    
    Following this, the work 'Journey to the Center of the Sound' was premiered, inviting listeners on a sonic journey through the landscapes of this European island, combining field recordings, music, and poetry.`,
      es: `Hicimos una residencia de una semana de duración para crear una primera aproximación a la espacialització de la pieza en el centro cultural y laboratorio de sonido MONOM, situado a Funkhaus Berlin el 12 de septiembre de 2022. El corazón de MONOM es el sistema de espacialització de sonido 4DSOUND, formado por 48 altavoces omnidireccionales suspendidos por todo el recinto, que se extienden a 9 potentes subbúfers sumergidos bajo un suelo acústicamente transparente. 
    
    A continuación, se hizo un preestreno de la obra 'Journey tono the Center of the Sound', que invitaba los oyentes a un viaje sonoro por los paisajes de esta isla europea, combinando grabaciones de campo, música y poesía.`,
      cat: `Vam fer una residència d'una setmana de durada per crear una primera aproximació a l'espacialització de la peça al centre cultural i laboratori de so MONOM, situat a Funkhaus Berlin el 12 de setembre de 2022. El cor de MONOM és el sistema d'espacialització de so 4DSOUND, format per 48 altaveus omnidireccionals suspesos per tot el recinte, que s'estenen a 9 potents subbúfers submergits sota un sòl acústicament transparent. 
    
    A continuació, es va fer una preestrena de l'obra 'Journey to the Center of the Sound', que convidava els oients a un viatge sonor pels paisatges d'aquesta illa europea, combinant enregistraments de camp, música i poesia.`,
      ger: `Wir führten eine einwöchige Residenz durch, um einen ersten Ansatz zur räumlichen Darstellung des Stücks im Kulturzentrum und Klanglabor MONOM zu schaffen, das sich im Funkhaus Berlin am 12. September 2022 befindet. Das Herzstück von MONOM ist das 4DSOUND-Klangraum-System, bestehend aus 48 omnidirektionalen Lautsprechern, die im gesamten Veranstaltungsort aufgehängt sind und sich auf 9 leistungsstarke Subwoofer erstrecken, die unter einem akustisch transparenten Boden versenkt sind. 
    
    Im Anschluss wurde das Werk 'Reise zum Zentrum des Klangs' uraufgeführt, das die Zuhörer zu einer klanglichen Reise durch die Landschaften dieser europäischen Insel einlud und dabei Feldaufnahmen, Musik und Poesie kombinierte.`,
    },
    image: "/img/projects/12/12.webp",
    image1: "img/projects/12/portada.webp",
    image2: "Jlqh2eVeMpY",
    image3: "img/projects/12/img2.webp",
    place: {
      en: "Berlin, Germany",
      es: "Berlín, Alemania",
      cat: "Berlín, Alemanya",
      ger: "Berlin, Deutschland"
    },
    link: "https://patrimoniacustic.cat/journey/home.html"

  },

  {
    id: 13,
    title: {
      en: `Artist in Residence at 
    @A+E Wave Field Synthesis Lab`,
      es: `Artista en Residencia en 
      @A+E Wave Field Synthesis Lab`,
      cat: `Artista en Residència a 
        @A+E Wave Field Synthesis Lab`,
      ger: `Künstler in Residence in 
          @A+E Wave Field Synthesis Lab`,
    },
    description: {
      en: `Exploring the expressive possibilities of spatial sound as an artistic medium. (192 speakers and 24 subwoofers).`,
      es: `Explorando las posibilidades expresivas del sonido espacial como medio artístico. (192 altavoces y 24 subwoofers).`,
      cat: `Explorant les possibilitats expressives del so espacial com a mitjà artístic. (192 altaveus i 24 subwoofers).`,
      ger: `Erkundung der expressiven Möglichkeiten von räumlichem Klang als künstlerisches Medium. (192 Lautsprecher und 24 subwoofers).`,
    },
    longDescription1: {
      en: `Exploring the expressive possibilities of spatial sound as an artistic medium. The WFS system from The Game of Life consists of 192 speakers and 24 subwoofers, which are arranged around the audience in a square formation of 10x10 meters. Conference SC2022, University of Kent.`,
      es: `Explorando las posibilidades expresivas del sonido espacial como medio artístico. El sistema WFS de The Game of Life consta de 192 altavoces y 24 subwoofers, que se disponen alrededor de la audiencia en una formación cuadrada de 10x10 metros. Conferencia SC2022, Universidad de Kent.`,
      cat: `Explorant les possibilitats expressives del so espacial com a mitjà artístic. El sistema WFS de The Game of Life consta de 192 altaveus i 24 subwoofers, que estan disposats al voltant de l'audiència en una formació quadrada de 10x10 metres.Conferència SC2022, Universitat de Kent.`,
      ger: `Erkunden der expressiven Möglichkeiten von räumlichem Klang als künstlerischem Medium.Das WFS - System von The Game of Life besteht aus 192 Lautsprechern und 24 Subwoofern, die um das Publikum in einer quadratischen Formation von 10x10 Metern angeordnet sind.Konferenz SC2022, Universität von Kent.`
    },
    longDescription2: {
      en: "",
      es: "",
      cat: "",
      ger: ""
    },
    image: `/img/projects/13/13.webp`,
    image1: `img/projects/13/portada.webp`,
    image2: `img/projects/13/img1.webp`,
    image3: `img/projects/13/img2.webp`,
    place: {
      en: `Chatham, UK`,
      es: `Chatham, Reino Unido`,
      cat: `Chatham, Regne Unit`,
      ger: `Chatham, Vereinigtes Königreich`,
    },
    link: `https://patrimoniacustic.cat/journey/home.html`
  },
  {
    id: 14,
    title: {
      en: `Soroll`,
      es: `Soroll`,
      cat: `Soroll`,
      ger: `Soroll`,
    },
    description: {
      en: `An investigation of documentary sources and artistic practice building a bridge between the past of the textile city and the current Catalan city.`,
      es: `Una investigación de fuentes documentales y práctica artística que construye un puente entre el pasado de la ciudad textil y la actual ciudad catalana.`,
      cat: `Una investigació de fonts documentals i pràctica artística que construeix un pont entre el passat de la ciutat tèxtil i l'actual ciutat catalana.`,
      ger: `Eine Untersuchung von Dokumentarquellen und künstlerischer Praxis, die eine Brücke zwischen der Vergangenheit der Textilstadt und der aktuellen katalanischen Stadt schlägt.`,
    },
    longDescription1: {
      en: `Through documentary source research and artistic practice, the aim is to build a bridge between the textile city's past and the current Catalan city. Using a critical artistic narrative based on digital tools such as convolution, spatialization, sound archives, and three-dimensional environments, we want to ask ourselves:
        
      Can we draw parallels between different social structures through a comparative study of their noises?
      `,
      es: `A través de la investigación de fuentes documentales y la práctica artística, el objetivo es construir un puente entre el pasado de la ciudad textil y la ciudad catalana actual. Utilizando una narrativa artística crítica basada en herramientas digitales como la convolución, la espacialización, archivos de sonido y entornos tridimensionales, queremos preguntarnos:

      ¿Podemos establecer paralelismos entre diferentes estructuras sociales mediante un estudio comparativo de sus sonidos?      
        `,
      cat: `A través de la recerca de fonts documentals i la pràctica artística, l'objectiu és construir un pont entre el passat de la ciutat tèxtil i la ciutat catalana actual. Utilitzant una narrativa artística crítica basada en eines digitals com la convolució, la espacialización, arxius de so i entorns tridimensionals, volem preguntar-nos:

      Podem establir paral·lelismes entre diferents estructures socials mitjançant un estudi comparatiu dels seus sons?
      `,
      ger: `Durch die Recherche von Dokumentationsquellen und künstlerische Praxis besteht das Ziel darin, eine Brücke zwischen der Vergangenheit der Textilstadt und der aktuellen katalanischen Stadt zu bauen. Unter Verwendung einer kritischen künstlerischen Erzählung, die auf digitalen Werkzeugen wie Konvolution, räumlicher Darstellung, Klangarchiven und dreidimensionalen Umgebungen basiert, möchten wir uns folgende Fragen stellen:
  
            Können wir Parallelen zwischen verschiedenen sozialen Strukturen ziehen, indem wir ihre Geräusche vergleichend untersuchen?
      `
    },
    longDescription2: {
      en: `Aren't modern cities a chaotic abstraction of the textile colonies ?

          Are the present - day production and consumption chains anything more than a shattered mirror of the past that prevents us from clearly seeing current precarity ?
          `,
      es: `¿No son las ciudades modernas una abstracción caótica de las colonias textiles?

      ¿Las cadenas de producción y consumo actuales son algo más que un espejo roto del pasado que nos impide ver claramente la precariedad actual?
      `,

      cat: `No són les ciutats modernes una abstracció caòtica de les colònies tèxtils?
        
      Les cadenes de producció i consum actuals no són una mirall trencat del passat que ens impedeix veure clarament la precarietat actual?
          `,
      ger: `Sind moderne Städte nicht eine chaotische Abstraktion der Textilkolonien ?

        Sind die heutigen Produktions - und Konsumketten mehr als ein zersplitterter Spiegel der Vergangenheit, der uns daran hindert, die aktuelle prekäre Situation klar zu sehen ?
          `
    },
    image: `/img/projects/14/14.webp`,
    image1: `img/projects/14/portada.webp`,
    image2: `R8SRyC3O8-Q`,
    image3: `F2zTAY8bpT0`,
    place: {
      en: `Sabadell, Spain`,
      es: `Sabadell, España`,
      cat: `Sabadell, Espanya`,
      ger: `Sabadell, Spanien`,
    },
    link: `https://patrimoniacustic.cat/soroll/home.html`
  },
  {
    id: 15,
    title: {
      en: `PROCÉS`,
      es: `PROCÉS`,
      cat: `PROCÉS`,
      ger: `PROCÉS`,
    },
    description: {
      en: `Multichannel sound composition created using only the audio archive of the original machinery from the Catalan industrialization process.`,
      es: `Composición de sonido multicanal creada utilizando solo el archivo de audio de la maquinaria original del proceso de industrialización catalán.`,
      cat: `Composició de so multicanal creada utilitzant només l\'arxiu d'àudio de la maquinària original del procés d\`industrialització català.`,
      ger: `Mehrklang-Soundkomposition, komponiert ausschließlich mit dem Tonarchiv der originalen Maschinen aus dem katalanischen Industrialisierungsprozess.`,
    },
    longDescription1: {
      en: `PROCÉS is a multichannel sound composition by the Barcelona based artist EMARX created during his residency at the Fundación Phonos (CAT). 
        
        All the sounds in this piece correspond to original machinery from the Catalan industrialization process that has been recorded over the last two years in a project to safeguard heritage. In this three-movement piece ((De)Construcció, Sistematització, Externalització), the sounds have been distributed in space by layers, just as in an orchestra the instruments are grouped according to their nature.`,
      es: `PROCÉS es una composición de sonido multicanal del artista con sede en Barcelona, EMARX, creada durante su residencia en la Fundación Phonos (CAT). 
        
        Todos los sonidos en esta pieza corresponden a maquinaria original del proceso de industrialización catalán que se ha registrado durante los últimos dos años en un proyecto para salvaguardar el patrimonio. En esta pieza de tres movimientos ((De)Construcció, Sistematització, Externalització), los sonidos se han distribuido en el espacio por capas, de la misma manera que en una orquesta los instrumentos se agrupan según su naturaleza.`,
      cat: `PROCÉS és una composició de so multicanal de l'artista EMARX, amb seu a Barcelona, creada durant la seva residència en la Fundació Phonos. 
        
        Tots els sons en aquesta peça corresponen a maquinària original del procés d'industrialització català que s'ha registrat durant els últims dos anys en un projecte per a salvaguardar el patrimoni. En aquesta peça de tres moviments ((De)Construcció, Sistematització, Externalització), els sons s'han distribuït en l'espai per capes, de la mateixa manera que en una orquestra els instruments s'agrupen segons la seva naturalesa.`,
      ger: `PROCÉS ist eine Mehrkanal-Tonkomposition des in Barcelona ansässigen Künstlers EMARX, die während seiner Residenz in der Fundación Phonos (CAT) entstanden ist. 
        
        Alle Klänge in diesem Stück entsprechen originalen Maschinen des katalanischen Industrialisierungsprozesses, die im Laufe der letzten zwei Jahre in einem Projekt zur Bewahrung des Erbes aufgenommen wurden. In diesem dreiteiligen Stück ((De)Construcció, Sistematització, Externalització) wurden die Klänge schichtweise im Raum verteilt, genauso wie in einem Orchester die Instrumente nach ihrer Natur gruppiert sind.`
    },
    longDescription2: {
      en: `No sound has been processed. No sound has been recreated. This is PROCÉS, a journey through the beauty and brutality of industrialization. This 20-minute piece has been supported by generative images by artist Joan Lavandeira.`,
      es: `Ningún sonido ha sido procesado. Ningún sonido ha sido recreado. Esto es PROCÉS, un viaje a través de la belleza y brutalidad de la industrialización. Esta pieza de 20 minutos ha sido complementada con imágenes generativas del artista Joan Lavandeira.`,
      cat: `Cap so ha estat processat. Cap so ha estat recreat. Això és PROCÉS, un viatge a través de la bellesa i brutalitat de la industrialització. Aquesta peça de 20 minutos s’ha complementat amb imatges generatives de l'artista Joan Lavandeira.`,
      ger: `Es wurde kein Ton verarbeitet. Es wurde kein Ton nachgebildet. Dies ist PROCÉS, eine Reise durch die Schönheit und Brutalität der Industrialisierung. Dieses 20-minütige Stück wurde von generativen Bildern des Künstlers Joan Lavandeira unterstützt.`
    },
    image: `/img/projects/15/15.webp`,
    image1: `img/projects/15/portada.webp`,
    image2: `HMKUs6F3Qg4`,
    image3: `img/projects/15/img1.webp`,
    place: {
      en: `Barcelona, Spain`,
      es: `Barcelona, España`,
      cat: `Barcelona, Espanya`,
      ger: `Barcelona, Spanien`,
    },
    link: `https://phonosbcn.bandcamp.com/album/proc-s-live-at-phonos`
  }
];

const projectsText = [
  {
    id: 1,
    heading: {
      en: `PROJECTS`,
      es: `PROYECTOS`,
      cat: `PROJECTES`,
      ger: `PROJEKTE`
    },
    text: {
      en: `We fuse space and sound perception to craft unique narratives.`,
      es: `Fusionamos espacio y percepción sonora para crear narrativas únicas.`,
      cat: `Fusionem espai i percepció sonora per crear narratives úniques.`,
      ger: `Wir fügen Raum - und Klangwahrnehmung zu eigenen Geschichten.`
    }
  },

]


const buttonTexts = [
  {
    id: 1,
    text: {
      en: `SEE FULL PROJECT`,
      es: `VER EL PROYECTO COMPLETO`,
      cat: `VEURE EL PROJECTE COMPLET `,
      ger: `VOLLSTÄNDIG PROJEKT ANSEHEN`,
    },
  },
  {
    id: 2,
    text: {
      en: `PREVIOUS`,
      es: `ANTERIOR`,
      cat: `ANTERIOR`,
      ger: `VORHERIGE`,
    },
  },
  {
    id: 3,
    text: {
      en: `BACK TO TOP`,
      es: `VOLVER ARRIBA`,
      cat: `TORNAR A DALT`,
      ger: `ZURÜCK NACH OBEN`,
    },
  },
  {
    id: 4,
    text: {
      en: `NEXT`,
      es: `SIGUIENTE`,
      cat: `SEGÜENT`,
      ger: `NÄCHSTER`,
    },
  },
  {
    id: 5,
    text: {
      en: `BINAURAL`,
      es: `BINAURAL`,
      cat: `BINAURAL`,
      ger: `BINAURAL`,
    },
  },
  {
    id: 6,
    text: {
      en: `360º AV`,
      es: `360º AV`,
      cat: `360º AV`,
      ger: `360º AV`,
    },
  },
];



export { artProjectsList, projectsText, buttonTexts };


