import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Heading from '../common/Heading';
import { buttonTexts, headings, introText } from '../../data/homeData';
import Button from '../common/Button.jsx';
import { useLanguage } from '../../context/LanguageContext.js';
import Services from '../services/Services.jsx';
import { services } from '../../data/homeData';

const Wrapper = styled.div`
  width: 88.28vw;
  margin: auto;
  border-bottom: 1px solid lightgray;
  @media (max-width: 768px) {
    height:auto;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  margin: auto;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  @media (max-width: 768px) {
    align-items:center;
  }
`;

const ListItem = styled.div`
  position: relative;
  width: 32%;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  overflow: hidden;
  @media (max-width: 768px) {
    width:80%;
  }
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer; 
`;

const ServiceVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(80%);
`;

const ServiceText = styled.p`
  position:absolute;
  top:0;
  left:0;
  margin:0.5rem;
  font-size: 1.5625rem;
  line-height: 1.5625rem; 
  letter-spacing: 0.0625rem;
  z-index:1; 
  white-space:pre-line;
  margin-top:0.5rem;
  margin-left:0.5rem;
  text-transform:uppercase;
`;

const ContentContainer = styled.div`
  flex: 2;
  text-align: start;
  color: black;
  max-width: 100%;
  margin-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;

const TextContent = styled.p`
  font-size: 3.4375rem;
  line-height: 3.4375rem;
  letter-spacing:-0.01em;
  text-align: start;
  color: black;
  white-space:pre-line;
  @media (max-width: 801px){
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 275;
    line-height: 2.5rem; 
    letter-spacing: -0.02188rem;
    width: 100%;
    white-space:normal;
  }
`;

const ServicePicture = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
 filter: grayscale(80%);
  opacity: ${(props) => (props.showVideo ? '0' : '1')};
  transition: opacity 6s ease-in-out;
  @media (max-width: 768px) {
    opacity:0;
  }
`;

const MobileTextContainer = styled.div`
  height:auto;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5625rem; 
  letter-spacing: 0.05rem;
  width:100%;
  border-bottom:1px solid #D9D9D9;
  text-transform:uppercase;
`;

const TitleText = styled.p`
color:#000;
margin-bottom:0px;
padding-bottom:0px;
`
const SubtitleText = styled.p`
color:#818181;
margin-top:0px;
`
const ServiceList = () => {
  const { language } = useLanguage();
  const heading = headings.find((heading) => heading.id === 2);
  const caption = introText.find((text) => text.id === 4);
  const buttonText = buttonTexts.find((item) => item.id === 3);
  const [resize, setResize] = useState(false)
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [videoLoaded, setVideoLoaded] = useState({});
  const linkStyle = {
    color: 'black',
    textDecoration: 'none',
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [resize]);

  const handleResize = (id) => {
    setResize(window.innerWidth <= 801);

  }


  const handleHover = (id) => {
    if (!videoLoaded[id]) return;
    setHoveredItemId(id);
    const video = document.getElementById(`video${id}`);
    video.style.filter = "none"

    try {
      if (videoLoaded[id] && video.paused) {
        video.play().catch((error) => {
          console.error('Error playing video:', error)
        });
      }
    } catch (error) {
      console.error('Error playing video:', error);
    }
  };

  const handleHoverOut = (id) => {
    if (!videoLoaded[id]) return;
    setHoveredItemId(null);
    const video = document.getElementById(`video${id}`);
    video.style.filter = "grayscale(90%)";
    try {
      if (videoLoaded[id] && !video.paused) {
        video.pause();
        video.currentTime = 0;
      }
    } catch (error) {
      console.error('Error pausing video:', error);
    }
  };

  const handleVideoLoaded = (id) => {
    setVideoLoaded((prev) => ({ ...prev, [id]: true }));

    if (window.innerWidth <= 768) {
      if (!videoLoaded[id]) return;
      const video = document.getElementById(`video${id}`);

      try {
        if (videoLoaded[id] && video.paused) {
          video.play().catch((error) => {
            console.error('Error playing video:', error);
          });
        }
      } catch (error) {
        console.error('Error playing video:', error);
      }
    };
  };


  return (
    <Wrapper>
      <Heading props={heading.text[language]} />
      <ListContainer>
        {services.map((service) => (
          resize ? (
            <MobileTextContainer key={service.id}>
              <Link style={linkStyle} to={`/services/${service.id}`} component={Services}>
                <TitleText>{service.nameMobile.title[language]}</TitleText>
                <SubtitleText>{service.nameMobile.subtitle[language]}</SubtitleText>
              </Link>
            </MobileTextContainer>

          ) : (
            
            <ListItem
              key={service.id}
              onMouseEnter={() => handleHover(service.id)}
              onMouseLeave={() => handleHoverOut(service.id)}
            >
              <VideoContainer>
                <ServiceVideo
                  className="videos"
                  id={`video${service.id}`}
                  loading="eager"
                  muted
                  loop
                  playsInline
                  onLoadedData={() => handleVideoLoaded(service.id)}
                >
                  <source src={service.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </ServiceVideo>
              </VideoContainer>
              <Link to={`/services/${service.id}`} component={Services}>
                <ServicePicture
                  showVideo={videoLoaded}
                  loading="eager"
                  src={service.image}
                  alt={service.name[language]}
                />
              </Link>
              <ServiceText>{service.name[language]}</ServiceText>
            </ListItem>
          )
        ))}
        <ContentContainer>
          <TextContent>{caption.text[language]}</TextContent>
          <Link to={`/services`} component={Services}>
            <Button text={buttonText.text[language]}></Button>
          </Link>
        </ContentContainer>
      </ListContainer>
    </Wrapper>
  );
};

export default ServiceList;
