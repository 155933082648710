import React, { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { HeadingAbout } from '../about/HeadingAbout';
import { headingText } from '../../data/servicesData';
import { useLanguage } from '../../context/LanguageContext';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';


const Wrapper = styled.div`
  width: 88.28vw;
  margin: auto;
  flex-direction: column;
  display: flex;
  position:relative;
  margin-top:5.625rem;
  @media (max-width: 801px) {
    margin-top:2.5rem;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const FlexLeft = styled.div`
  flex: 50%;
  display: flex;
  align-self: stretch;
  align-items: start;
  margin-top: 0px;
  @media (max-width: 801px) {
    flex: 100%;
    margin-bottom:1rem;
  }
`;

const FlexRight = styled.div`
  flex: 50%;
  padding-left: 7rem;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  font-size: 2.188rem;
  line-height:2.5rem;
  width: 62vw;
  height: 20vh;
  overflow: hidden;
  @media (max-width: 801px) {
    padding-left:0px;
    width:auto;
    height:auto;
  }
`;

const TextContent = styled.div`
  margin-bottom:5rem;
  align-self:start;
  @media (max-width: 801px) {
   margin-bottom:2.5rem;
  }
`;

const Text = styled.div`
  width:50%;
  margin-top:5.625rem;
  font-size:2.188rem;
  line-height:2.5rem;
  @media (max-width: 801px) {
    white-space:normal;
    margin-top:0rem;
    width:80%;
  }
`;
const ImageWrapper = styled.div`
  width:100%;
  position:relative;
`;

const TextContainer = styled.div`
  width:88.28%;
  margin:auto;
  margin-bottom:5rem;
  align-self:start;
`;

const PictureSection = () => {

  const { language } = useLanguage()
  const [resize, setResize] = useState(false)
  const headingInfo = headingText.find((text) => text.id === 2)
  const bottomInfo = headingText.find((text) => text.id === 3)


  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [resize]);

  const handleResize = (id) => {
    setResize(window.innerWidth <= 801);

  }

  return (
    <div style={{ marginBottom: "5.6rem" }}>
      <Wrapper>
        <Container>
          <FlexLeft>
            <HeadingAbout props={headingInfo.title[language]} />
          </FlexLeft>
          <FlexRight>
          </FlexRight>
        </Container>
        <TextContent>
          <Text>
            <p style={{ marginTop: "0px" }}>
              {headingInfo.text[language]}
            </p>
          </Text>
        </TextContent>
      </Wrapper>
      <ImageWrapper>

        {resize ?
          <ParallaxBanner style={{ height: '200vh', position: 'relative' }}>
            <ParallaxBannerLayer
              image="/img/services/servicios3.webp"
              style={{ width: '80vw', height: '52vh', objectFit: 'cover', objectPosition: 'center top', position: 'absolute', left: 0, top: '5%', zIndex: 3 }}
              speed={-30}
            />
            <ParallaxBannerLayer
              image="/img/services/servicios4.webp"
              style={{ width: '66vw', height: '52vh', objectFit: 'cover', objectPosition: 'center top', position: 'absolute', left: '35%', top: '27%', zIndex: 2 }}
              speed={-40}
            />
            <ParallaxBannerLayer
              image="/img/services/servicios5.webp"
              style={{ width: '100%', height:"60vh", aspectRatio:"16/9", position: 'absolute', left: '0%', top: '48%', zIndex: 1 }}
              speed={-50}
            />
          </ParallaxBanner>
          :
          <ParallaxBanner style={{ height: '330vh', position: 'relative' }}>
            <ParallaxBannerLayer
              image="/img/services/servicios3.webp"
              style={{ width: '60.875rem', height: '40.5625rem', objectFit: 'cover', objectPosition: 'center top', position: 'absolute', left: 0, top: '5%', zIndex: 3 }}
              speed={-30}
            />
            <ParallaxBannerLayer
              image="/img/services/servicios4.webp"
              style={{ width: '51.6875rem', height: '40.625rem', objectFit: 'cover', objectPosition: 'center top', position: 'absolute', left: '50%', top: '30%', zIndex: 2 }}
              speed={-40}
            />
            <ParallaxBannerLayer
              image="/img/services/servicios5.webp"
              style={{ width: '100%', objectFit: 'scale-down', position: 'absolute', objectPosition: 'center top', left: '0%', top: '57%', zIndex: 1 }}
              speed={-50}
            />
          </ParallaxBanner>

        }

      </ImageWrapper>
      <TextContainer>
        <TextContent>
          <Text>
            <p>
              {bottomInfo.text1[language]}
            </p>
            <p>
            {bottomInfo.text2[language]}
          </p>
          </Text>
        </TextContent>
      </TextContainer>
    </div>

  );
};

export default PictureSection;
