import React from 'react'
import { styled } from 'styled-components'
import { HeadingAbout } from '../about/HeadingAbout';
import { useLanguage } from '../../context/LanguageContext';
import { headingsContact, contactSmallTexts } from '../../data/contactData';

const Wrapper = styled.div`
  width: 88.28vw;
  margin:auto;
  align-items: center;
  flex-direction:column;
  display:flex;
  padding-bottom:5.625rem;
  @media (max-width: 801px){
    padding-bottom:0rem;
    height:auto;
  }

`;

const Container = styled.div`
 display:flex;
 align-items: center;
 flex-direction:row;
 position:relative;
 margin-top:120px;
 @media (max-width: 801px){
    flex-direction:column;
  }
`;

const FlexLeft = styled.div`
  flex:50%;
  display:flex;
  align-self: stretch;
  align-items: start; 
  margin-top:5.625rem;
  @media (max-width: 801px){
    flex:100%;
    margin-top:2.5rem;
  }
`;


const FlexRight = styled.div`
  flex:50%;
  display:flex;
  align-self: stretch;
  align-items: center; 
  font-size:3.438rem;
  line-height:3.438rem;
  margin-top:5.625rem;
  @media (max-width: 801px){
    flex:100%;
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 275;
    line-height: 2.1875rem; 
    padding-top:0rem;
    margin-top:2.5rem;
  }
`;

const SubHeader = styled.p`
color:"#000";
padding:0px;
margin-top:0px;
@media (max-width: 801px){
  margin-top:0rem;
  padding-top:0rem;
  }
`;


const Header = () => {
  const headingText = headingsContact.find((item)=> item.id === 1);
  const headingTextSmall = contactSmallTexts.find((item)=> item.id === 1);
  const { language } = useLanguage()

    return (
        <Wrapper>
            <Container>
                <FlexLeft>
                    <HeadingAbout props={headingText.text[language]}/>
                </FlexLeft>
                <FlexRight>
                   <SubHeader>{headingTextSmall.text[language]}</SubHeader>
                </FlexRight>
            </Container>
        </Wrapper>
    )
}

export default Header
