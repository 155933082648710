import React, { useEffect, useState } from 'react';
import styled from 'styled-components';


const Container = styled.div`
  width: 88.78%;
`;

const Header = styled.h2`
margin-bottom:0px;
margin-top:0px;
padding:0px;
color: #000;
font-size: 7.5rem;
font-style: normal;
font-weight: 300;
line-height: 6.875rem; 
letter-spacing: -0.075rem;
text-transform: uppercase;
white-space:pre-line;
@media (max-width: 801px) {
    font-size: 3.4375rem;
    font-style: normal;
    line-height: 3.4375rem; 
    letter-spacing: -0.10313rem;
    white-space:normal;
  }
`;

const ProjectListHeading = ({ props, style={}}) => {

  return (
    <Container>
      <Header>{props}</Header>
    </Container>
  );
};

export default ProjectListHeading;
