import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  
  font-size:5rem;
  text-transform:uppercase;
  margin-top:5.7rem;
  line-height:7rem ;
  @media (max-width: 801px) {
    font-size:2rem;
  }
`;

const Header = styled.h2`
width:90%;
margin-bottom:0px;
padding:0px;
color: #000;
font-size: 7.5rem;
font-style: normal;
font-weight: 300;
line-height: 6.875rem; /* 91.667% */
letter-spacing: -0.075rem;
text-transform: uppercase;
@media (max-width: 801px) {
  font-size: 3.4375rem;
  line-height: 3.4375rem; /* 100% */
  letter-spacing: -0.03438rem;
  width:90%;
  }
`;
const SubHeader = styled.h2`
margin-top:0px;
padding:0px;
color: #818181;
font-size: 7.5rem;
font-style: normal;
font-weight: 300;
line-height: 6.875rem;
letter-spacing: -0.075rem;
@media (max-width: 801px) {
  font-size: 3.4375rem;
  line-height: 3.4375rem; /* 100% */
  letter-spacing: -0.03438rem;
  }
`;


export const ProjectHeading = (props) => {
  const { title, subtitle } = props

  return (
    <Container>
      <Header>{title}</Header>
      <SubHeader>{subtitle}</SubHeader>
    </Container>

  )
}

export default ProjectHeading