import React from 'react'
import { styled } from 'styled-components'
import { logosAbout, findElement} from '../../data/data'
import { headings } from '../../data/homeData';
import { HeadingAbout } from './HeadingAbout';
import { useLanguage } from '../../context/LanguageContext';
import { headingsAbout, aboutSmallTexts } from '../../data/aboutData';

const Wrapper = styled.div`
  width: 88.28vw;
  border-top:1px solid lightgray;
  margin:auto;
  /*height:350vh;*/
  flex-direction:column;
  border-bottom:1px solid lightgray;
  padding-bottom:9.375rem;
`;

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  row-gap: 8rem;
  column-gap:2.5rem;
    justify-content: space-between;
    padding-left:0px;
    padding-right:0px;
  @media (max-width: 1200px) {
    column-gap:0rem;
  }

`;

const ListImage = styled.img`
width:20vw;
object-fit:scale-down;
@media (max-width:1200px) {
    width:42vw;
    padding:0px;
  }

`

const TextContainer = styled.div`
font-size:2.188rem;
line-height:2.188rem;
text-align: start;
color: black;
width:70vw;
@media (max-width: 801px) {
    width:100%;
    white-space:normal;
  }
`
const Container = styled.div`
margin-top:4.688rem;
margin-bottom:4.688rem;
`


const Collaborators = () => {
    const heading = findElement(headingsAbout, 4)
    const smallText = findElement(aboutSmallTexts, 6)
    const { language } = useLanguage()

    return (
        <Wrapper>
            <Container>
                <HeadingAbout props={heading.text[language]} />
            </Container>
            <ListContainer>
                {logosAbout.map((logo) => {
                    return <ListImage key={logo.id} src={logo.src} alt={logo.text} />
                })}
            </ListContainer>
            <TextContainer>
                <p>{smallText.text[language]}</p>
            </TextContainer>

        </Wrapper>
    )
}

export default Collaborators