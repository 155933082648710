import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import { styled } from 'styled-components';
import Button from '../common/Button.jsx';
import { useLanguage } from '../../context/LanguageContext.js';
import ProjectHeading from './ProjectHeading.jsx';
import { buttonTexts } from '../../data/projectsData.js';
import { artProjectsList } from '../../data/projectsData.js';

const Wrapper = styled.div`
  display: flex;
  width: 88.28vw;
  margin: auto;
  flex-direction: column;
  border-bottom: 3rem;
`;

const Picture = styled.img`
  width: 100%;
  object-fit: cover;
`;

const VideoWrapper = styled.div`
  width:100%!important;
  height:100%;
  position: relative;
  aspect-ratio:16/10;
`;

const Video = styled(YouTube)`
 width: 100%!important;
height: 100%!important;
`;

const TextContent = styled.div`
  width: 70;
`;

const Text = styled.p`
  font-size: 5.625rem;
  line-height:5rem;
  text-transform: uppercase;
  @media (max-width: 801px) {
    font-size: 2.1875rem;
    font-weight: 275;
    line-height: 2.5rem
  }
`;

const TextSmall = styled.p`
  font-size: 1.563rem;
  line-height:1.875rem;
  margin-left: 2rem;
  margin-top: 0px;
  white-space:pre-line;
  @media (max-width: 768px){
    margin-top:1rem;
    margin-left: 0rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5rem;
   @media (max-width: 768px){
    flex-direction: column;
  }

`;

const FlexLeft = styled.div`
  display: flex;
  flex: 50%;
  flex-direction: column;
  row-gap:2rem;
  
`;

const FlexRight = styled.div`
  display: flex;
  flex: 50%;
  flex-direction: column;
  position: relative;
  overflow-y: ${(props) => (props.isOverflowing ? 'scroll' : 'auto')};
  overflow-x:hidden;
  /* For WebKit browsers */
  &::-webkit-scrollbar {
    width: 7px; 
  }

  &::-webkit-scrollbar-thumb {
    background-color: gray; 
    border-radius: 6px; 
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1; 
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

const ButtonContainer = styled.div`
display:flex;
flex-direction:row;
`;

const ButtonWrapper = styled.div`
  position:relative;
  left: 2rem;
  margin-bottom:0px;
  height:30%;
`;

const NextButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30vh;
  align-items: center;
`;

const ButtonNext = styled.div`
  display: flex;
  flex: 1;
  font-size: 2rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  cursor: pointer;
  position: relative;
  @media (max-width: 801px) {
    font-size:1.2rem;
  }

`;

const RightArrow = styled.img`
  max-width: 1.6rem;
  opacity: 0.9;
  margin-left: 1rem;
  @media (max-width: 801px) {
    max-width: 1.2rem;
  }
`;

const TopArrow = styled.img`
  max-width: 1.9rem;
  opacity: 0.8;
  position: absolute;
  bottom: 3rem;
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
  @media (max-width: 801px) {
    max-width: 1.4rem;
  }

  ${({ animate }) =>
    animate &&
    `
    animation: bounceUpDown 1s ease-in-out 2;
  `}

  @keyframes bounceUpDown {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
`;

const LeftArrow = styled.img`
  max-width: 1.6rem;
  opacity: 0.9;
  margin-right: 1rem;
  @media (max-width: 801px) {
    max-width: 1.2rem;
  }
`;



const Project = () => {

  const { id } = useParams();
  const { language } = useLanguage();
  const [topScroll, setTopScroll] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [player, setPlayer] = useState(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  let nextProjectsId = parseInt(id);
  const containerRef = useRef(null);


  const findElement = (item, id) =>{
    return item.find((element) => element.id === id);
  }

  const project = artProjectsList.find((p) => p.id === parseInt(id, 10));
  const seeAllProjectsButton = findElement(buttonTexts, 1) 
  const previousButton = findElement(buttonTexts, 2) 
  const topButton = findElement(buttonTexts, 3) 
  const nextButton = findElement(buttonTexts, 4) 
  const binauralButton = findElement(buttonTexts, 5) 
  const aVButton = findElement(buttonTexts, 6) 
  const nextProject = nextProjectsId + 1;
  const prevProject = nextProjectsId - 1;

  const linkStyle = {
    color: 'black',
    textDecoration: 'none',
  };


  const handlePlayerReady = (event) => {
    setPlayer(event.target);
  };

  useEffect(() => {
    if (player) {
      const iframe = player.getIframe();
      if (iframe) {
        iframe.style.width = '100%';
        iframe.style.height = '100%';
      }
    }
  }, [player]);


  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      setIsOverflowing(container.scrollHeight > container.clientHeight);
    }

  }, [containerRef.current, language]);


  const handleHover = () => {
    setAnimate(true);
  };

  const handleMouseLeave = () => {
    setAnimate(false);
  };

  useEffect(() => {
    const element = document.getElementById(`wrapper`);
    if (element) {
      element.scrollIntoView({
        behavior: 'auto',
        block: 'start',
      });
    }

    setTopScroll(false);
  }, [nextProject, prevProject, topScroll]);

  const handleClick = () => {
    setTopScroll(true);
  };

  if (!project) {
    return <div>Project not found.</div>;
  }

  return (
    <Wrapper id="wrapper">
      <ProjectHeading title={project.title[language]} subtitle={project.place[language]} />
      <Picture src={`../${project.image1}`} />
      <TextContent>
        <Text>{project.description[language]}</Text>
      </TextContent>
      <FlexContainer>
        <FlexLeft>
          {[2, 10, 11, 12, 14, 15].includes(project.id) ? (
            <VideoWrapper>
              <Video
                videoId={project.image2}
                opts={{
                  playerVars: {
                    autoplay: 0,
                    controls: 0,
                    modestbranding: 1,
                    showinfo: 0,
                    loop: 0,
                    fs: 0,
                    rel: 0,
                  },

                }}
                onReady={handlePlayerReady}
              />
            </VideoWrapper>
          ) : (
            <Picture src={`../${project.image2}`} />
          )}

          {project.id === 14 ? (
            <VideoWrapper>
              <Video
                videoId={project.image3}
                opts={{
                  playerVars: {
                    autoplay: 0,
                    controls: 0,
                    modestbranding: 1,
                    showinfo: 0,
                    loop: 0,
                    fs: 0,
                    rel: 0,
                  },
                }}
                onReady={handlePlayerReady}
              />
            </VideoWrapper>

          ) : (
            <Picture src={`../${project.image3}`} />
          )}

        </FlexLeft>
        <FlexRight ref={containerRef} isOverflowing={isOverflowing}>
          <TextSmall className='read-font'>{project.longDescription1[language]}</TextSmall>
          <TextSmall className='read-font'>{project.longDescription2[language]}</TextSmall>

          {project.id === 4 ? (

            <ButtonContainer>
              <ButtonWrapper>
                <a href={project.link} target="_blank">
                  <Button text={`${binauralButton.text[language]} +`} />
                </a>
              </ButtonWrapper>
              <ButtonWrapper style={{marginLeft:'2rem'}} >
                <a  href="https://acousticheritagecollective.org/soundwalks01vr/index.html" target="_blank">
                  <Button text={`${aVButton.text[language]} +`} />
                </a>
              </ButtonWrapper>
            </ButtonContainer>

          ) : (
            <ButtonWrapper>
              <a href={project.link} target="_blank">
                <Button text={`${seeAllProjectsButton.text[language]} +`} />
              </a>
            </ButtonWrapper>

          )}

        </FlexRight>
      </FlexContainer>
      <NextButtonWrapper>
        {prevProject > 0 && (
          <div>
            <Link style={linkStyle} to={`/project/${prevProject}`} component={Project}>
              <ButtonNext className="thin-font">
                <LeftArrow src="/img/arrow-left.jpg" />
                {previousButton.text[language]}
              </ButtonNext>
            </Link>
          </div>
        )}

        <ButtonNext
          onMouseEnter={handleHover}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleClick()}
          className="thin-font"
          aria-label="Back to Top"
        >
          {topButton.text[language]}
          <TopArrow animate={animate} onMouseEnter={() => handleHover} src="/img/top-img.jpg" />
        </ButtonNext>

        {nextProject < artProjectsList.length + 1 && (
          <div>
            <Link style={linkStyle} to={`/project/${nextProject}`} component={Project}>
              <ButtonNext className="thin-font">
                {nextButton.text[language]}
                <RightArrow src="/img/arrow-right.jpg" />
              </ButtonNext>
            </Link>
          </div>
        )}
      </NextButtonWrapper>
    </Wrapper>
  );
};

export default Project;
