import React from 'react'
import { styled } from 'styled-components'
import { useLanguage } from '../../context/LanguageContext';
import { contactInfo } from '../../data/contactData';
import Heading from '../common/Heading';
import { contactSmallTexts } from '../../data/contactData';

const Wrapper = styled.div`
  width: 88.28vw;
  margin:auto;
 
`;

const Container = styled.div`
 display:flex;
 align-items: center;
 flex-direction:row;
 padding-bottom:5rem;
 border-bottom:1px solid lightgray;
 @media (max-width: 801px){
    flex-direction:column;
  }
`;

const FlexItem = styled.div`
  flex:50%;
  display:flex;
  flex-direction:column;
  align-self: stretch;
  align-items: start; 
  margin-top:0px;
  text-transform:uppercase;
  @media (max-width: 801px){
    flex:100%;
    margin-bottom:2.5rem;
  }
`;

const Image = styled.img`
  width:23.313vw;
  height:34.313vw;
  @media (max-width: 768px){
    flex:100%;
    width:80%;
    margin:auto;
  }
`;

const Text = styled.p`
font-size:3.438rem;
line-height:3.438rem;
padding-top:10px;
margin-bottom:0;
margin-left:0;
 @media (max-width: 768px){
    font-size:2.188rem;
    line-height:2.5rem;
    margin-left:2.5rem;
  }
`
const TextGrey  = styled.p`
font-size:3.438rem;
line-height:3.438rem;
color:gray;
padding:0;
margin:0;
@media (max-width: 768px){
    font-size:2.188rem;
    line-height:2.5rem;
    margin-left:2.5rem;
  }
`
const EmailText = styled.p`
font-size:3.438rem;
line-height:3.438rem;
padding-bottom:5.625rem;
border-bottom:1px solid lightgray;
@media (max-width: 768px){
    font-size:1.8rem;
    line-height:2.5rem;
    padding-bottom:2.5rem;
  }
`
const InfoSection = () => {
    const { language } = useLanguage();
    const headingText = contactSmallTexts.find((element) => element.id === 2)
  
    return (
      <Wrapper>
          <Container className='thin-font'>
          {contactInfo.map((item) => (
            <FlexItem key={item.id}>
              <Image src={item.image} alt={item.country}/>
              <Text>{item.phone}<br></br>
             {item.city}</Text>
              <TextGrey>{item.country[language]}</TextGrey>
            </FlexItem>
            ))}
          </Container>
        <EmailText>info@acousticheritagecollective.org<br></br>
        acousticheritagecollective@gmail.com<br></br>
        patrimoniacustic@gmail.com</EmailText>
        <Heading props={headingText.text[language]}/>
      </Wrapper>
    );
  };
  
  export default InfoSection;
  