import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import styled from 'styled-components';
import Heading from '../common/Heading';
import { headings, contactText } from '../../data/homeData';
import { useLanguage } from '../../context/LanguageContext';

const Wrapper = styled.div`
  display: flex;
  width: 88.28vw;
  margin-left: auto;
  margin-right:auto;
  flex-direction: column;
  align-items: start;
  margin-bottom:9.375rem;
  @media (max-width: 801px){
   margin-top:0px;
   padding-top:0px;
  }
  
`;

const SendButton = styled.input.attrs({
  type: 'submit',
})`
  background-color: white;
  color: black;
  border: 1px solid #818181;
  border-radius: 17px!important;
  font-size: 1.6rem;
  cursor: pointer;
  margin-top: 10px;
  max-width: 100px!important;
  padding: 10px 20px!important;
  height:2.5rem!important;
  line-height:1rem;
`;

const FormContainer = styled.form`
  flex-direction: column;
  padding-top: 1rem;
  font-size:1.6rem;

  @media (max-width: 801px){
   padding-top:0rem;
   
  }

  div {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    padding-bottom:2rem;
  }

  label {
    margin-bottom: 0.6rem;
    margin-right:9.3125rem;
    min-width: 9.3125rem;
   
  }

  input{
    margin-bottom: 1rem;
    height:3.5rem;
    width:37.25rem;
    border:1px solid #818181;
    border-radius:10px;
    font-size:1.25rem;
  }

  input[type=submit]{
    margin-bottom: 1rem;
    height:3.5rem;
    width:37.25rem;
    border:1px solid #818181;
    border-radius:10px;
    font-size:1.6rem;
  }

  textarea{
    margin-bottom: 1rem;
    width:37.25rem;
    height:13.8125rem;
    border-radius:10px;
    font-size:1.25rem;
  }

  @media (max-width: 801px) {

    div {
    
    flex-direction:column;
    margin-bottom: 0.5rem;
    padding-bottom:1rem;
    
  }


    input {
      max-width: 15rem; 
      height: 1.875rem;
    }

    label {
    margin-bottom: 0.5rem;
    margin-right:1.3125rem;
    min-width: 3.3125rem;
    font-size: 1.25rem;
    line-height: 1.375rem; 
    letter-spacing: 0.05rem;
    
  }

  textarea{
    max-width: 15rem;
    height:10rem;
   
  }

  }

`;

const TextContainer = styled.div`
  width: 100%;
  white-space:pre-wrap;
  align-items:start;
  @media (max-width: 801px){
   margin-bottom:3.75rem;
   width:80%;
  }
`;

export const Contact = () => {
  const form = useRef();
  const { language } = useLanguage()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_78vbk6t', 'template_yz8d7or', form.current, 'KJEv4eqES7eE2wrOO')
      .then(
        (result) => {
          console.log(result.text);
          alert("Email sent")
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const heading = headings.find((heading) => {
    return heading.id === 4
  })

  const contactInfo = contactText.find((item) => item.id === 1)

  return (

    <Wrapper>
      <TextContainer className="light-font">
        <Heading props={heading.text[language]} spacing={true} style={{ margin: '0'}} />
      </TextContainer>
      <FormContainer ref={form} onSubmit={sendEmail}>
        <div>
          <label className="light-font" htmlFor="user_name">{contactInfo.name[language]}</label>
          <input type="text" name="user_name" />
        </div>
        <div>
          <label className="light-font" htmlFor="user_email">{contactInfo.email[language]}</label>
          <input type="email" name="user_email" />
        </div>
        <div>
          <label className="light-font" htmlFor="user_subjectline">{contactInfo.subject[language]}</label>
          <input type="phone" name="user_subjectline" />
        </div>
        <div>
          <label className="light-font" htmlFor="message">{contactInfo.message[language]}</label>
          <textarea name="message" />
        </div>
        <SendButton className="light-font" type="submit" value={contactInfo.send[language]} />
      </FormContainer>
    </Wrapper>
  );
};

export default Contact;
