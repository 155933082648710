import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { findElement } from '../../data/data';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { useLanguage } from '../../context/LanguageContext';
import { aboutSmallTexts } from '../../data/aboutData';

const Wrapper = styled.div`
  width: 88.78vw;
  margin:auto;
  display:flex;
  flex-direction:column;
  padding-bottom:9.375rem;
  border-bottom:1px solid lightgray;
`;

const ContentContainer = styled.div`
  text-align: start;
  color: black;
  width:88.28vw;
  margin:auto;
`;

const TextContent = styled.div`
  font-size:2.188rem;
  line-height:2.5rem;
  text-align: start;
  color: black;
  white-space:pre-line;
  @media (max-width: 801px) {
    white-space: normal; 
}
`;

const StyledParallaxBanner = styled(ParallaxBanner)`
height:100vh;
width:100vw;
z-index:4;
@media (max-width: 801px) {
  width: 32.375rem;
  height: 29.75rem;
  }
`;


const Parallax = () => {
  const { language } = useLanguage();
  const smallText = findElement(aboutSmallTexts,2)
  const smallText2 = findElement(aboutSmallTexts,2.1)

  return (
    <div>
      <StyledParallaxBanner style={{ aspectRatio: '2 / 1' }}>
        <ParallaxBannerLayer image="/img/about/img2.webp" speed={-30} />
      </StyledParallaxBanner>
      <Wrapper>
        <ContentContainer>
          <TextContent>
            <p>{smallText.text[language]}</p>
            <p>{smallText2.text[language]}</p>
          </TextContent>
        </ContentContainer>
      </Wrapper>

    </div>

  );
};

export default Parallax;
